import React, { useState, useEffect, useRef } from 'react'
import axiosClient from "../api/ApiInstance.js";
import './otpverification.css'
import { Link, useNavigate } from "react-router-dom";
const Otpverification = () => {
  const inputRef = useRef(null);
  let navigate = useNavigate();
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const otp = sessionStorage.getItem('gotp');
  const newOtp = sessionStorage.getItem("newOtp");
  const mobile = sessionStorage.getItem("mobileOTP");
  const mobileOtpValues = { otp1: "", otp2: "", otp3: "", otp4: "", };

  const [mobileOtp, setMobileOtp] = useState(mobileOtpValues);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);
  const [otpValue, setOtpValue] = useState();
  const [show, setShow] = useState(false);


  const handleOtpChange = (e) => {
    const { name, value } = e.target;
    setMobileOtp({
      ...mobileOtp,
      [name]: value,
    });
  };
  const otpMove = (e, p, c, n) => {
    var length = document.getElementById(c).value.length;
    var maxLength = document.getElementById(c).getAttribute("maxLength");
    if (length == maxLength) {
      if (n !== "") {
        document.getElementById(n).focus();
      }
    }
    if (e.key === "Backspace") {
      if (p !== "") {
        document.getElementById(p).focus();
      }
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    setOtpValue((mobileOtp.otp1 + mobileOtp.otp2 + mobileOtp.otp3 + mobileOtp.otp4).toString());
    if (otpValue) {
      // setShow(true);
    }
  });

 
  const resendOtp = () => {
    setMinutes(0);
    setSeconds(60);
    sessionStorage.clear('gotp')
    const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
    console.log(randOtp, 'random');
    console.log(mobile, 'resendMobile');
    if (randOtp) {
      //set value of random otp in session storage
      sessionStorage.setItem("newOtp", randOtp);
    }
    axiosClient
      .post("/forget-password", {
        mobile_number: mobile,
        otp: newOtp,
      })
      .then(res => {
        console.log(res.data);
      })
      .catch(error => {
        console.log(error.response);
        // setErrMsg({
        //   errors: error.response.data,
        //   isError:true,
        // });
        // if(error){
        //   setShow(true);
        // }
      })
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp != otpValue) {
      navigate("");
    }   else {
      navigate("/reset-password");
    }
  };

  const verify=()=>{
    setShow(true)
  }
  return (
    <>
      <section className='otp-verification'>
        <div className='container'>
          <div className='row background-color'>
            <div className='col-md-12 mb-4 mt-5'>
              <div className='heading-holder text-center'>
                <h2>fizo</h2>
              </div>
            </div>
            <div className='col-md-6 col-sm-8 col-12 mx-auto'>
              <div className='text-holder mb-4  text-center'>
                <h3>Verification</h3>
                <p >We have just sent your email an OTP, please enter <br /> below to verify</p>
              </div>
              <form className="row" onSubmit={handleSubmit}>
                  <div className='col-md-3 col-sm-3 col-3'>
                  <div className="input-group mb-3">
                    <input type="text"
                      className="form-control form-control-sm mx-2 number"
                      name="otp1"
                      value={mobileOtp.otp1}
                      id='txt1'
                      maxLength={1}
                      onChange={handleOtpChange}
                      onKeyUp={(e) => otpMove(e, '', 'txt1', 'txt2')}
                      ref={inputRef}
                    />
                  </div>
                </div>  
               <div className='col-md-3 col-sm-3 col-3'>
                  <div className="input-group mb-3">
                    <input type="text"
                      className="form-control form-control-sm mx-2 number"
                      name="otp2"
                      value={mobileOtp.otp2}
                      id='txt2'
                      maxLength={1}
                      onChange={handleOtpChange}
                      onKeyUp={(e) => otpMove(e, 'txt1', 'txt2', 'txt3')}
                    />
                  </div>
                </div>
               <div className='col-md-3 col-sm-3 col-3'>
                  <div className="input-group mb-3">
                    <input type="text"
                      className="form-control form-control-sm mx-2 number"
                      name="otp3"
                      value={mobileOtp.otp3}
                      id='txt3'
                      maxLength={1}
                      onChange={handleOtpChange}
                      onKeyUp={(e) => otpMove(e, 'txt2', 'txt3', 'txt4')}
                    />
                  </div>
                </div> 
                <div className='col-md-3 col-sm-3 col-3'>
                  <div className="input-group mb-3">
                    <input type="text"
                      className="form-control form-control-sm mx-2 number"
                      name="otp4"
                      value={mobileOtp.otp4}
                      id='txt4'
                      maxLength={1}
                      onChange={handleOtpChange}
                      onKeyUp={(e) => otpMove(e, 'txt3', 'txt4', '')}
                    />
                  </div>
                </div>
                {show ? <div>{otp != otpValue && newOtp != otpValue ? <p className='errMsg'>Please Enter Valid Otp</p> : ""}</div> : ""}
                <div className="countdown-text text-center">
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Resend on: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p className='errMsg'>Didn't recieve code?</p>
                  )}
                  <button className='btn'
                    disabled={seconds > 0 || minutes > 0}
                    onClick={resendOtp}
                  >
                    Resend OTP
                  </button>
                </div>
                <div className="col-12 mt-5">
                  <button type="submit" className='btn btn-white' onClick={verify}>Verify</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Otpverification;