import React from 'react'
import { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "../circular.css"


const PlanSuccess = () => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
      }
    }, 50);
  }, [percentage]);

  let tID = setTimeout(function () {
    window.location.href = 
    "/nutrition"; //replace with your url
    window.clearTimeout(tID);// clear time out.
}, 4000);
  return (
    <>
    <div className="centered-container">
         <div className='fizo-circular mx-auto'>
         <img
         style={{
          marginBottom:'148px'
                 }}
                    className="head-logo"
                    src={process.env.PUBLIC_URL + "assets/images/icons/green-success.png"}
                    alt="logo"
                  />
      <div className='subprogress-div mx-auto'>
      <span>Your plan has been <br/>activated.</span>
      </div>
         </div>

    </div>
    </>
  )
}

export default PlanSuccess