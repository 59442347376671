import React, { useEffect } from "react";
import axiosClient from "../api/ApiInstance.js";
import { Rings } from 'react-loader-spinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from "swiper";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./Nutrition.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link, useNavigate } from "react-router-dom";
import { getBannerApi } from "../api/Apis";
import { useState } from "react";
import { getDietApi, getMealApi, getDietWeekApi, getUserSubscription, getInfoApi } from "../api/Apis";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export const Nutrition = () => {
  let navigate = useNavigate();
  const [noSubs, setSubs] = useState(true);
  const [loading, setLoading] = useState(true);
  const [assignedDiet, setAssignedDiet] = useState(false);
  const [submitModal, setSubmitModal] = useState(true);
  const [image, setImage] = useState([]);
  const [weeks, setWeek] = useState();
  const [days, setDay] = useState();
  const [caloriesLeft, setCaloriesLeft] = useState();
  const [currentWeek, setCurrentWeek] = useState(1);
  const [currentDay, setCurrentDay] = useState();
  const [totalCal, setTotalCal] = useState(0);
  const [meal, setMeal] = useState();
  const [userdietweight, setDietWeight] = useState();
  const [userweight, setUserWeight] = useState();
  const [userFoodSelect, setFoodSelect] = useState();
  const [today, setToday] = useState();
  const [Nodata, setNoData] = useState(false);
  const [executeCount, setExecuteCount] = useState(false);
  const [executeDietCount, setExecuteDietCount] = useState(false);
  const [dayPercent, setDayPercent] = useState();

  const [errors, setErrors] = useState(false);
  const [errMsg, setErrMsg] = useState({
    errors: [],
    isError: false,
  });
  const [show, setShow] = useState(false);

  const imageUrl = "https://api.fizo.in/";

  const submitWeight = async () => {
    let weight1 = document.getElementById('weight-note');
    let note1 = document.getElementById('note');

    // console.log(weight1.value ,"dietweight");
    axiosClient
      .post("/diet-weight", {
        weight: weight1.value,
        note: note1.value,
      })
      .then((res) => {

        // console.log(res.data ,"dietweight");
        setSubmitModal(false);


      })
      .catch((error) => {
        console.log(error.response);
        setErrMsg({
          errors: error.response.data,
          isError: true,
        });
        if (error) {
          setShow(true);
        }
      });
  }

  const getUserinfoSubscription = async () => {
    await getUserSubscription()
      .then((res) => {


        if (res.data.data.subscription == null) {
          setSubs(false);
          // console.log(res.data.data.subscription.id, "usersubs");
        } else {

          // console.log(res.data.data, "usersubs");
        }
      })
      .catch((error) => {
        console.log(error, "hhhhhhhh");
      });
  };

  const getDietWeek = async () => {
    await getDietWeekApi()
      .then((res) => {

        setCurrentDay(res.data.data.currentDay);
        setCurrentWeek(res.data.data.ongoingWeek);
        setExecuteCount(true);
        // console.log(res.data, "execute");
      })
      .catch((error) => {
        console.log(error, "hhhhhhhh");
      });
  };


  const getDietWeekDays = async () => {
    await getDietApi(currentWeek, currentDay)
      .then((res) => {
        console.log(res.data, 'ssssssssssssssssss');
        if (res.data != null && res.data.data.day_one_percent) {
          if (!res.data.data.diets) {
            setNoData(true);
          }

          if (typeof res.data.data.currentDay == "number") {
            // console.log(res.data.data.currentDay, "eeeeeeeeeee");
            setToday(res.data.data.currentDay);
          }
          let totalWeek = res.data.data.totalWeeks;
          let weeks = [];
          for (let i = 0; i < totalWeek; i++) {
            weeks[i] = i + 1;
          }
          setWeek(weeks);
          setCaloriesLeft(res.data.data);
          setCurrentDay(res.data.data.currentDay);
          setDayPercent(res.data.data.day_one_percent);
          if (res.data.data.selectedWeek) {
            setCurrentWeek(res.data.data.selectedWeek);
          } else {
            setCurrentWeek(res.data.data.ongoingWeek);
          }


          setDay(res.data.data.dayslist);
          setFoodSelect(res.data.data.user_food_selected);
          setExecuteDietCount(true);
          setLoading(false);
          setAssignedDiet(true)
        } else {
          setLoading(false);
          setAssignedDiet(false)
        }

      })
      .catch((error) => {
        console.log(error);
      });


  };

  const getMeal = async () => {
    await getMealApi()
      .then((res) => {
        setMeal(res.data.data.foods);
        console.log(res.data.data, "meaal");
      })
      .catch((error) => {
        console.log(error);
        getMealApi();
      });
  };

  const getInfo = async () => {
    const token = sessionStorage.getItem("token");
    // console.log(token,'tokencheck');
    if (token == null) {
      navigate(`/login`);
    }
    await getInfoApi()
      .then((res) => {
        console.log(res.data.data,'ggfhjasfaf');
          if (res.data.data.dietweight.weight) {
            setDietWeight(res.data.data.dietweight);
            setUserWeight(res.data.data.dietweight.weight)
          } else {
            setDietWeight(res.data.data.user.user_input.weight);
            setUserWeight(res.data.data.user.user_input.weight.name)
          }


      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addMeal = () => {
    let meal = document.getElementById("add_meal").value;
    //  console.log(meal,'uuuuuuuuuuu');

    if (meal == "") {
      setErrors(true);
      console.log(errors);
    }
    axiosClient
      .post("/mealpost", {
        food_data_id: meal,
        food_type_id: 5,
        day_id: currentDay,
      })
      .then((res) => {
        // console.log(res.data ,"added meal");

        setLoading(true);
        getDietWeekDays();

      })
      .catch((error) => {
        console.log(error.response);
        setErrMsg({
          errors: error.response.data,
          isError: true,
        });
        if (error) {
          setShow(true);
        }
      });
  };

  const selectMeal = (foodid, foodtype, fooditem) => {
    //  console.log(fooditem,'uuuuuuuuuuu');

    axiosClient
      .post("/user-food", {
        food_data_id: foodid,
        food_type_id: foodtype,
        day_id: currentDay,
        food_taken: 1,
        food_item_package_id: fooditem,
      })
      .then((res) => {
        // console.log(res.data ,"added meal");

        setLoading(true);
        getDietWeekDays();
      })
      .catch((error) => {
        console.log(error.response);
        setErrMsg({
          errors: error.response.data,
          isError: true,
        });
        if (error) {
          setShow(true);
        }
      });
  };

  const handleDay = (week, day) => {
    // console.log(week + 'uuuuuuuuuuu'+day);
    setCurrentWeek(week);
    setCurrentDay(day);
    setExecuteDietCount(false);
  };

  const modalClick = () => {
    setSubmitModal(true);
  };

  useEffect(() => {
    getMeal();
    getInfo();
    getUserinfoSubscription();
    if (executeDietCount == false) {
      if (executeCount == false) {
        getDietWeek();
      }
      setLoading(true);
      getDietWeekDays();
    }

  }, [currentWeek, currentDay, userweight]);

  const calculateTotal = (price) => {
    setTotalCal(totalCal + price);
  };
  return (
    <>
      <section className="main" >
        <TopNav />
        <div className="container-fluid p-0">
          {loading ? (
            <Rings color="#00BFFF" height={80} width={80} />
          ) : (
            <section
              className={noSubs ? "nutrition-section" : "nutrition-section nutrition-section-blur"}
                   >
                <div className="container">
                  {/* {`${imageUrl}${val.path}`} */}
                  {/* <img className='nutribanner' src={process.env.PUBLIC_URL + '/assets/images/nutrition/nutri-banner.png'} /> */}
                  {/* <img className='nutribanner' src={image} /> */}
                  <Swiper
                    // pagination={{
                    //   clickable: true,
                    //   type: "fraction",
                    //   dynamicBullets: true,
                    // }}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      1024: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                    }}
                    autoplay={{
                      delay: 800,
                      disableOnInteraction: true,
                    }}
                    modules={[Navigation, Pagination]}
                    className="brand-associtates-slider"
                    navigation={true}
                  >
                    {/* {image.map((val) => {
                                                                          return (
                                                                            <SwiperSlide>
                                                                              <img className="nutribanner" src={imageUrl+val.image} />
                                                                            </SwiperSlide>
                                                                          );
                                                                        })} */}
                  </Swiper>


                  <div className="nutri-tab-section pt-4">
                    <div className="tabs-div">

                      <div className="row">
                        <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-8 col-xl-5 col-8 mx-auto">
                          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" >

                            <Swiper
                              spaceBetween={10}
                              breakpoints={{
                                0: {
                                  slidesPerView: 2,
                                },
                                640: {
                                  slidesPerView: 3,
                                },
                                768: {
                                  slidesPerView: 3,
                                },
                                1024: {
                                  slidesPerView: 4,
                                },
                                1200: {
                                  slidesPerView: 4,
                                },
                                1400: {
                                  slidesPerView: 4,
                                },
                              }}


                              pagination={{
                                clickable: true,
                              }}
                              loop={true}
                              // navigation={true}
                              modules={[Autoplay]}
                              className="mySwiper"
                            >
                              {weeks?.map((val) => (
                                <SwiperSlide>
                                  <li
                                    className="perWeekSlider nav-item "
                                    role="presentation"
                                    onClick={() => {
                                      handleDay(val, 1);
                                    }}
                                  >
                                    <button
                                      class={
                                        currentWeek == val
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id="pills-Week1-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-Week1"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-Week1"
                                      aria-selected="true"
                                    >
                                      Week {val}
                                    </button>
                                  </li>
                                </SwiperSlide>
                              ))}
                            </Swiper>



                          </ul>
                        </div>
                      </div>

                      {assignedDiet ? (
                      <div>
                        {/* Days Section start  */}
                        <div className="days">
                          <div className="row">
                            <div className="col-xxl-5 col-lg-6 col-md-6 col-sm-8 col-xl-6 col-10 mx-auto">

                              <ul
                                className="daysSlider  nav nav-pills justify-content-center mb-3"
                                id="pills-tab"
                                role="tablist"
                              >

                                <Swiper
                                  spaceBetween={10}

                                  breakpoints={{
                                    0: {
                                      slidesPerView: 3,
                                    },
                                    320: {
                                      slidesPerView: 5,
                                    },
                                    640: {
                                      slidesPerView: 6,
                                    },
                                    768: {
                                      slidesPerView: 6,
                                    },
                                    1024: {
                                      slidesPerView: 7,

                                    },
                                    1200: {
                                      slidesPerView: 7,
                                    },
                                    1400: {
                                      slidesPerView: 7,
                                    },
                                  }}
                                  centeredSlides={false}

                                  pagination={{
                                    clickable: true,
                                  }}
                                  loop={false}
                                  // navigation={true}
                                  modules={[Autoplay]}
                                  className="mySwiper"
                                >

                                  {days?.map((val, index) => (
                                    <SwiperSlide>
                                      <li
                                        class="perDaySlider nav-item"
                                        role="presentation"
                                        onClick={() => {
                                          handleDay(currentWeek, val?.day_id);
                                        }}
                                      >

                                        <CircularProgressbar className="nav-link" value={dayPercent[index]?.percentage} text={`D${val?.day_id}`}
                                          styles={buildStyles({
                                            // Colors
                                            pathColor: '#01B4D8',
                                            textColor: '#01B4D8',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#01B4D8',
                                          })}
                                        />

                                        <input type="radio" className="activeDot"
                                          style={{
                                            display:
                                              caloriesLeft?.currentDay == val?.day_id
                                                ? "block"
                                                : "none"

                                          }}
                                          checked={true}
                                        />

                                        {/* <button
                                                                                        class={
                                                                                          caloriesLeft?.currentDay == val?.day_id
                                                                                            ? "nav-link active"
                                                                                            : "nav-link "
                                                                                        }
                                                                                        id="pills-home-tab"
                                                                                        type="button"
                                                                                        role="tab"
                                                                                        aria-controls="pills-home"
                                                                                        aria-selected="true"
                                                                                      >
                                                                                        D{val?.day_id}
                                                                                      </button> */}
                                      </li>
                                    </SwiperSlide>
                                  ))}
                                  {/* <li class="nav-item" role="presentation">
                                                                                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">D2</button>
                                                                                    </li> */}
                                </Swiper>
                              </ul>

                            </div>

                          </div>
                        </div>
                        {/* Days Section end */}

                        <div class="tab-content pt-3" id="pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="pills-Week1"
                            role="tabpanel"
                            aria-labelledby="pills-Week1-tab"
                            tabindex="0"
                          >
                            <div className="tab-body">
                              <div className="goal-div">
                                <div className="row">
                                  <div className="col-md-3 col-sm-3 col-6">
                                    <div className="sec-1">
                                      <h6 className="goaltitle">Today's Goal</h6>
                                      <p className="cal-p">Calories</p>
                                      <h1 className="calh1">
                                        {caloriesLeft?.calories_left} cal
                                        <span>Left</span>
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="col-md-7 col-sm-9 col-6">
                                    <div className="sec-2 ">
                                      {/* <Link to="/more-details">
                                                                                          <p className="more-details-p">More Details</p>
                                                                                        </Link> */}

                                      <div className="sec-2-goal">
                                        <div className="d-flex justify-content-center">
                                          <div className="">

                                            <CircularProgressbar className="range-pic" value={100 - caloriesLeft?.carb_percent} text={`${100 - caloriesLeft?.carb_percent}%`}
                                              styles={buildStyles({
                                                // Colors
                                                pathColor: '#01B4D8',
                                                textColor: '#01B4D8',
                                                trailColor: '#d6d6d6',
                                                backgroundColor: '#01B4D8',
                                              })}
                                            />
                                          </div>
                                          <div className=" protein-txt ms-2 mt-4">
                                            <h6 className="carbs">Carbs</h6>
                                            <p className="gram-p">
                                              {caloriesLeft?.carbs_left} g left
                                            </p>
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                          <div className="fats_circle">

                                            <CircularProgressbar className="range-pic" value={100 - caloriesLeft?.fat_percent} text={`${100 - caloriesLeft?.fat_percent}%`}
                                              styles={buildStyles({
                                                // Colors
                                                pathColor: '#F9C700',
                                                textColor: '#F9C700',
                                                trailColor: '#d6d6d6',
                                                backgroundColor: '#F9C700',
                                                marginLeft: "-7px"
                                              })}
                                            />
                                          </div>
                                          <div className="protein-txt ms-2 mt-4">
                                            <h6 className="carbs">Fats</h6>
                                            <p className="gram-p">
                                              {caloriesLeft?.fat_left} g left
                                            </p>
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                          <div className="">
                                            {/* <img
                                                                                                className="range-pic"
                                                                                                src={
                                                                                                  process.env.PUBLIC_URL +
                                                                                                  "/assets/images/nutrition/protien.png"
                                                                                                }
                                                                                              /> */}
                                            <CircularProgressbar className="range-pic" value={100 - caloriesLeft?.protein_percent} text={`${100 - caloriesLeft?.protein_percent}%`}
                                              styles={buildStyles({
                                                // Colors
                                                pathColor: '#FF960F',
                                                textColor: '#FF960F',
                                                trailColor: '#d6d6d6',
                                                backgroundColor: '#FF960F',
                                              })}
                                            />
                                          </div>
                                          <div className="protein-txt ms-2 mt-4">
                                            <h6 className="carbs">Protien</h6>
                                            <p className="gram-p">
                                              {caloriesLeft?.protein_left} g left
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="col-md-2 col-sm-2 col-12">
                                    <Link to={`/more-details/${currentWeek}/${currentDay}`}>
                                      <p className="more-details-p">More Details</p>
                                    </Link>

                                  </div>
                                </div>
                              </div>

                              {caloriesLeft?.calories_left == 0 ? (
                                <div className="meal-div text-center">
                                  <div className="">
                                    <img
                                      className="mb-2"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/nutrition/complete.gif"
                                      }
                                    />

                                    <h3>Congratulations Nutrition Completed!</h3>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {caloriesLeft?.diet?.map((val) => (
                                <div className="meal-div">
                                  <div className="row py-3 bd-bot">
                                    <div className="col-10">
                                      <div className="d-flex">
                                        <div>
                                          <h6 className="meal-title">{val?.name}</h6>
                                        </div>
                                        <div className="my-auto ms-3">
                                          {/* <p className="cal-count">
                                                                                              430 cal{" "}
                                                                                              <img
                                                                                                className="fire-icon"
                                                                                                src={
                                                                                                  process.env.PUBLIC_URL +
                                                                                                  "/assets/images/icons/fire.png"
                                                                                                }
                                                                                              />
                                                                                            </p> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2 icon-align mx-auto">
                                      <div class="">
                                        <img
                                          className={
                                            today != currentDay || caloriesLeft?.calories_left == 0
                                              ? "drop-plus-icon d-none"
                                              : "drop-plus-icon"
                                          }
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/plus1.png"
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#AddMeal"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {val.food_item_package?.map((value) => (


                                    <div className="row py-3 bd-bot">
                                      <div className="col-10">
                                        <div
                                          className="d-flex"
                                          data-bs-toggle="modal"
                                          data-bs-target={"#mealmodal" + value?.food_data?.id}
                                        >
                                          <div>
                                            <img
                                              className="meal-img"
                                              src={imageUrl + value?.food_data?.image} />
                                          </div>
                                          <div className="my-auto ms-3">
                                            <h6 className="meal-name">
                                              {value?.food_data?.food_name}
                                            </h6>
                                            <p className="meal-quantity">
                                              ({value?.weight_show} {value?.unit_show}
                                              ) | <span>{value?.a_calories} cal</span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-2 icon-align mx-auto">
                                        <div class="">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckChecked"
                                            onClick={() => {
                                              selectMeal(
                                                value?.food_data?.id,
                                                val?.id,
                                                value?.id
                                              );
                                            }}
                                            checked={userFoodSelect.some(
                                              (cred) => cred.food_data_id ==
                                                value?.food_data?.id &&
                                                cred.food_type_id == val?.id
                                            ) == true
                                              ? true
                                              : false}
                                            disabled={userFoodSelect.some(
                                              (cred) => cred.food_data_id ==
                                                value?.food_data?.id &&
                                                cred.food_type_id == val?.id
                                            ) == true || today != currentDay
                                              ? true
                                              : false} />
                                        </div>
                                      </div>
                                    </div>




                                  ))}
                                </div>
                              ))}

                              <div className="meal-div">
                                <div className="row py-3 bd-bot">
                                  <div className="col-10">
                                    <div className="d-flex">
                                      <div>
                                        <h6 className="meal-title">
                                          User Added meal
                                        </h6>
                                      </div>
                                      <div className="my-auto ms-3"></div>
                                    </div>
                                  </div>
                                  <div className="col-2 icon-align mx-auto">
                                    <div class=""></div>
                                  </div>
                                </div>
                                {caloriesLeft?.diet_add_by_user?.map((val) => (
                                  <div className="row py-3 bd-bot">
                                    <div className="col-10">
                                      <div
                                        className="d-flex"
                                        data-bs-toggle="modal"
                                        data-bs-target="#mealmodal"
                                      >
                                        <div>
                                          <img
                                            className="meal-img"
                                            src={imageUrl + val?.user_data?.image}
                                          />
                                        </div>
                                        <div className="my-auto ms-3">
                                          <h6 className="meal-name">
                                            {val?.user_data?.food_name}
                                          </h6>
                                          <p className="meal-quantity">
                                            {/* (3pcs)  */}
                                            {/* |  */}
                                            <span>{val?.user_data?.calories}cal</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2 icon-align mx-auto">
                                      <div class="">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckChecked"
                                          onClick={() => {
                                            selectMeal(
                                              val?.food_data_id,
                                              val?.food_type_id,
                                              val?.food_item_package_id
                                            );
                                          }}
                                          checked={
                                            userFoodSelect.some(
                                              (cred) =>
                                                cred.food_data_id ==
                                                val?.food_data_id &&
                                                cred.food_type_id == val?.food_type_id
                                            ) == true
                                              ? true
                                              : false
                                          }
                                          disabled={
                                            userFoodSelect.some(
                                              (cred) =>
                                                cred.food_data_id ==
                                                val?.food_data_id &&
                                                cred.food_type_id == val?.food_type_id
                                            ) == true || today != currentDay
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="d-flex mt-4">
                                <div className="">
                                  <h6 className="today-weight-p">Today's weight</h6>
                                  <input className="today-weightkg"
                                    type="text"
                                    name="weight_id"
                                    id="weight-note"
                                    defaultValue={userweight}
                                    readOnly={false}
                                  />
                                </div>
                                <div className="ms-4 w-100">
                                  <textarea
                                    class="form-control today-weight-form"
                                    placeholder="Write here about today's meal..."
                                    name=""
                                    id="note"
                                    cols="30"
                                    rows="4"
                                    required=""
                                    defaultValue={userdietweight?.note}
                                  ></textarea>
                                </div>
                              </div>

                              <div className="text-center mt-5">
                                <button className="btn meal-completed-btn"
                                  onClick={submitWeight}
                                >
                                  Submit Weight
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            class="tab-pane fade"
                            id="pills-Week2"
                            role="tabpanel"
                            aria-labelledby="pills-Week2-tab"
                            tabindex="0"
                          ></div>

                          <div
                            class="tab-pane fade"
                            id="pills-Week3"
                            role="tabpanel"
                            aria-labelledby="pills-Week3-tab"
                            tabindex="0"
                          ></div>

                          <div
                            class="tab-pane fade"
                            id="pills-Week4"
                            role="tabpanel"
                            aria-labelledby="pills-Week4-tab"
                            tabindex="0"
                          ></div>
                        </div>
                      </div>
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col-md-6 mx-auto mt-5">
                              <div className="card ">
                                <div className="card-body">
        
                                  <h3 className="noAssigned">No Diet Assigned Yet..!</h3>
                                  <p className="noAssigned">You can refer to the support for more info</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <Add />
                  </div>
                </div>
              
            </section>
          )}
          <section className="modal-section">
            <div className="addmeal-modal">
              <div
                class="modal fade"
                id="AddMeal"

                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        Add Food
                      </h1>
                      {/* <p className="cal-no">480cal</p> */}
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <h6 className="h6add">
                        Add food item to meal as per your convenience.
                      </h6>

                      <select
                        className="form-select my-5"
                        aria-label="Default select example"
                        id="add_meal"

                      >
                        <option className="add_meal_dropdown" selected value="">
                          Search food item
                        </option>
                        {meal?.map((val) => (
                          <option value={val?.id}>{val?.food_name} | {val?.calories} cal
                          </option>
                        ))}
                      </select>

                      <div className="text-center">
                        <button
                          className="add-btn btn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={addMeal}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {caloriesLeft?.diet?.map((val) => (
              <div>
                {val.food_item_package?.map((value) => (
                  <div className="meal-modal">
                    <div
                      class="modal fade"
                      id={"mealmodal" + value?.food_data?.id}
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content">
                          {/* <div class="modal-header">
                                                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                              </div> */}
                          <div class="modal-body">
                            <div className="row my-auto">
                              <div className="col-md-7 my-auto">
                                <h6 className="title">
                                  {value?.food_data?.food_name}
                                </h6>
                                <p className="quantity">
                                  Food quantity <span>{value?.weight_show} {value?.unit_show}</span>
                                </p>
                                <Link to="/recipes">
                                  <button className="recipe-btn btn"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >

                                    Recipe
                                  </button>
                                </Link>
                              </div>
                              <div className="col-md-5">
                                <div className="d-flex ">
                                  <div className="calbox">
                                    <p className="cal-p">Calories</p>
                                    <h6 className="gram">{Math.round(value?.food_data?.calories)}g</h6>
                                  </div>
                                  <div className="calbox">
                                    <p className="cal-p">Carbs</p>
                                    <h6 className="gram">{Math.round(value?.food_data?.carbs)}g</h6>
                                  </div>
                                </div>
                                <div className="d-flex ">
                                  <div className="calbox">
                                    <p className="cal-p">Fats</p>
                                    <h6 className="gram">{Math.round(value?.food_data?.fats)}g</h6>
                                  </div>
                                  <div className="calbox">
                                    <p className="cal-p">Protein</p>
                                    <h6 className="gram">{Math.round(value?.food_data?.protein)}g</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                ))}
              </div>
            ))}




            <div className="subcription-modal">
              <div
                class={noSubs ? "modal fade" : "modal fade show"}
                id="subsModal"
                style={{
                  display: noSubs ? "none" : "block"
                }}
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        Get Premium to access all the features.
                      </h1>
                      {/* <p className="cal-no">480cal</p> */}

                    </div>
                    <div class="modal-body">
                      <div className="text-center">
                        <Link to={"/choose-plan"}>
                          <button
                            className="add-btn btn btn-info"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Go Premium!
                          </button>
                        </Link>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class={submitModal?"modal fade":"modal fade show"}
                  id="subsModal"
                 style={{
                  display:submitModal?"none":"block"
                 }}
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">
                          Weight Submitted Successfully..!
                        </h1>
                        {/* <p className="cal-no">480cal</p> */}
                        <button
                          type="button"
                          class="btn-close"
                          onClick={modalClick}
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="text-center">
                          
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
                
          </section>


        </div>
      </section>
    </>
  );
};

export default Nutrition;

