import * as React from "react";
import "./Form.css";
import { useState } from "react";
import { Field } from "@progress/kendo-react-form";
import { CardSelector } from "./card-selector";
import { FormInput, FormMaskedTextBox, FormDateInput } from "./form-components";
import { requiredValidator, cardValidator, cvcValidator } from "./validators";
import { getBodyFat, getInputApi,getOneUserInputApi   } from "../api/Apis";
const BodyType = () => {
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [data4, setData4] = useState();
  const [data5, setData5] = useState();
  const [value, setValue] = useState(0);
  const [imageType, setImageType] = useState(true);
  // console.log(value);

  const fatApi = {
    b_f_percentage: "",
  };



  const ChnageGender = (e) => {
    switch (e.target.id) {
      case "one":
        setValue(10);
        setData1(true);
        setData2(false);
        setData3(false);
        setData4(false);
        setData5(false);
        sessionStorage.setItem("bodytype", 10);
        break;
      case "two":
        setValue(20);
        setData1(false);
        setData2(true);
        setData3(false);
        setData4(false);
        setData5(false);
        sessionStorage.setItem("bodytype", 20);
        break;
      case "three":
        setValue(30);
        setData1(false);
        setData2(false);
        setData3(true);
        setData4(false);
        setData5(false);
        sessionStorage.setItem("bodytype", 30);
        break;
      case "four":
        setValue(40);
        setData1(false);
        setData2(false);
        setData3(false);
        setData4(true);
        setData5(false);
        sessionStorage.setItem("bodytype", 40);
        break;
      case "five":
        setValue(50);
        setData1(false);
        setData2(false);
        setData3(false);
        setData4(false);
        setData5(true);
        sessionStorage.setItem("bodytype", 50);
        break;

      default:
        break;
    }
  };
  let gen = sessionStorage.getItem("gender");
  const getImage = () => {
    if (gen == "Male") {
      setImageType(false);
    }else if(gen=="Female"){
      setImageType(true)
    }
  };

  const getStat = async () => {
    await getOneUserInputApi()
      .then((res) => {
        
        //    console.log(res.data.data.activityLevel[0].name);
        if(res.data.data.list.b_f_percantage != null){
          let val = res.data.data.list.b_f_percantage;
          switch (val) {
            case "10":
              setData1(true);
              setData2(false);
              setData3(false);
              setData4(false);
              setData5(false);
              sessionStorage.setItem("bodytype", 10);
              break;
            case "20":
              setData1(false);
              setData2(true);
              setData3(false);
              setData4(false);
              setData5(false);
              sessionStorage.setItem("bodytype", 20);
              break;
            case "30":
              
              setData1(false);
              setData2(false);
              setData3(true);
              setData4(false);
              setData5(false);
              sessionStorage.setItem("bodytype", 30);
              break;
            case "40":
              
              setData1(false);
              setData2(false);
              setData3(false);
              setData4(true);
              setData5(false);
              sessionStorage.setItem("bodytype", 40);
              break;
            case "50":
              
              setData1(false);
              setData2(false);
              setData3(false);
              setData4(false);
              setData5(true);
              sessionStorage.setItem("bodytype", 50);
              break;
      
            default:
              break;
          }
        }else{
          sessionStorage.setItem("bodytype", "0");
        }
        console.log(res.data.data.list.b_f_percantage,"w");
        // setPlan(res.data.data.package);
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getImage();
    getStat();
  }, []);

  return (
    <>
      <section className="main Body_typeSection">
        <div className="container p-0">
          <form>
            <div className="row workout-BodyTop">
              <div className="col-lg-6 col-md-7 my-auto">
                <div className="sec-BodyType">
                  <h1 className="field-title">
                    Hope...! <br></br>you are not overweight?
                  </h1>

                  <div className="title-bd"></div>
                  <p className="substitle-p">Select your body type</p>
                </div>
              </div>

              <div className="col-lg-6 col-md-5">
                <div className="sec-2">
                  <div className="d-flex justify-content-center overweight-imges-div">
                    {
                      imageType ? data1 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/femaleb1.png"
                          }
                          id="one"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/greyimgf1.png"
                          }
                          id="one"
                          onClick={ChnageGender}
                        />
                      </div>
                    ) : data1 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/1.png"
                          }
                          id="one"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/overweight1.png"
                          }
                          id="one"
                          onClick={ChnageGender}
                        />
                      </div>
                    )
                      }

                    {
                      imageType ? data2 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/femaleb2.png"
                          }
                          id="two"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/greyimgf2.png"
                          }
                          id="two"
                          onClick={ChnageGender}
                        />
                      </div>
                    ) : data2 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/2.png"
                          }
                          id="two"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/gray 2.png"
                          }
                          id="two"
                          onClick={ChnageGender}
                        />
                      </div>
                    )
                      }

                    {
                      imageType ? data3 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/femaleb3.png"
                          }
                          id="three"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/greyimgf3.png"
                          }
                          id="three"
                          onClick={ChnageGender}
                        />
                      </div>
                    ) : data3 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/3.png"
                          }
                          id="three"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/overweight3.png"
                          }
                          id="three"
                          onClick={ChnageGender}
                        />
                      </div>
                    )
                    }

                    {
                      imageType ? data4 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/femaleb4.png"
                          }
                          id="four"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/greyimgf4.png"
                          }
                          id="four"
                          onClick={ChnageGender}
                        />
                      </div>
                    ) :
                    data4 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/4.png"
                          }
                          id="four"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/overweight4.png"
                          }
                          id="four"
                          onClick={ChnageGender}
                        />
                      </div>
                    )
                    }

                    {
                      imageType ? data5 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/femaleb5.png"
                          }
                          id="five"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/greyimgf5.png"
                          }
                          id="five"
                          onClick={ChnageGender}
                        />
                      </div>
                    ) : data5 ? (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/5.png"
                          }
                          id="five"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="overweight-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/overweight5.png"
                          }
                          id="five"
                          onClick={ChnageGender}
                        />
                      </div>
                    )
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* <button onClick={handleSubmit}>get</button> */}
          </form>
        </div>
      </section>
    </>
  );
};

export default BodyType;
