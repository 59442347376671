import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./SimplePricing.css";

import { Rings } from 'react-loader-spinner';
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link, useNavigate  } from "react-router-dom";
import { useEffect } from "react";
import { getPlan, getPlans } from "../../api/Apis";

export const SimplePricing = () => {



  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState();
  const [plans, setPlans] = useState();
  var HtmlToReactParser = require('html-to-react').Parser;
  const htmltoreactconvert = new HtmlToReactParser();



  const getStat = async () => {
    await getPlan()
      .then((res) => {
        //    console.log(res.data.data.activityLevel[0].name);
        setPlan(res.data.data.plan);
        setLoading(false);
        // setPlan(res.data.data.package);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStats = async (val) => {
    try {
      const response = await getPlans(val);
      setPlans(response?.data?.data?.package);
      
      console.log(response?.data?.data?.package);
    } catch (error) {
      console.log(error);
    }
  };
  const handleButtonClick = async (val) => {
    getStats(val);
  };

  useEffect(() => {
    getStat();
    getStats();
    // openRazorpayWindow();
  }, []);
  return (
    <>
    {loading ? (
       <Rings color="#00BFFF" height={80} width={80} />
      ) : (
      <section className="main">
        <div className="container-fluid p-0">
          <section className="simple-pricing">
            <div className="row ms-0 me-0">
              <div className="col-md-10 mx-auto">
                <div className="row ">
                  <div className="col-lg-4">
                    <div className="sec-1 mt-5">
                      <h6 className="title">Simple Pricing</h6>
                      <h3 className="plan-h3">Choose Your Perfect Plan</h3>

                      <div className="tabs-main-div">
                        <ul
                          className="nav nav-pills  mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          {plan?.map((val, index) => (
                            <li className="nav-item" role="presentation"  key={val.id}>
                            <button
                              className={index == 0?"nav-link active":"nav-link"}
                              id={`pills-${val.id}-tab`}
                              data-bs-toggle="pill"
                              data-bs-target={`#pills-${val.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`pills-${val.id}`}
                              aria-selected="true"
                              onClick={() => handleButtonClick(val.id)}
                            >
                             {val.name}
                            </button>
                          </li>
                            ))}
                          
                          {/* <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-yearly-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-yearly"
                              type="button"
                              role="tab"
                              aria-controls="pills-yearly"
                              aria-selected="false"
                            >
                              Yearly
                            </button>
                          </li> */}
                        </ul>
                      </div>

                      <p className="plan-subtext">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="sec-2">
                      <div className="tabs-conent-div">
                        <div className="tab-content" id="pills-tabContent">

                          <div
                            className="tab-pane fade show active"
                            id="pills-weekly"
                            role="tabpanel"
                            aria-labelledby="pills-weekly-tab"
                            tabindex="0"
                          >
                            <div className="simple-pricing-slider">
                              <Swiper
                                slidesPerView={3}
                                spaceBetween={30}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  0: {
                                    slidesPerView: 1,
                                    spaceBetween: 0,
                                  },
                                  575: {
                                    slidesPerView: 1,
                                    spaceBetween: 0,
                                  },
                                  600: {
                                    slidesPerView: 2,
                                    spaceBetween: 0,
                                  },
                                  768: {
                                    slidesPerView: 2,
                                    spaceBetween: 0,
                                  },
                                  1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 0,
                                  },
                                  1300: {
                                    slidesPerView: 3,
                                    spaceBetween: 0,
                                  },
                                }}
                                className="simple-pricing-slider-innerclass"
                              >

                          {plans?.map((val) => (
                                <SwiperSlide>
                                  <div className="slide-card card">
                                    <b></b>
                                    <h3 className="plan-name">{val.plan_type.name}</h3>
                                    <h3 className="price">₹{val.price}{" "}</h3>
                                  <p>{htmltoreactconvert.parse(val?.specification)}</p>
                                    {/* <p className="plan-details">
                                      <img
                                        className="check-icon"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/check.png"
                                        }
                                      />{" "}
                                      Lorem ipsum dolor sit
                                    </p>
                                    <p className="plan-details">
                                      <img
                                        className="check-icon"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/check.png"
                                        }
                                      />{" "}
                                      Lorem ipsum dolor sit
                                    </p>
                                      */}
                                    <Link to="/login">
                                    <button className="get-start-btn btn">
                                      Get Started
                                    </button>
                                    </Link>
                                  </div>
                                </SwiperSlide>
                                    ))}
                                {/* <SwiperSlide>
                                  <div className="slide-card card">
                                    <b></b>
                                    <h3 className="plan-name">Plan Name</h3>
                                    <h1 className="price">$50</h1>
                                    <p className="plan-details">
                                      <img
                                        className="check-icon"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/check.png"
                                        }
                                      />{" "}
                                      Lorem ipsum dolor sit
                                    </p>
                                    <p className="plan-details">
                                      <img
                                        className="check-icon"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/check.png"
                                        }
                                      />{" "}
                                      Lorem ipsum dolor sit
                                    </p>

                                    <p className="plan-details">
                                      <img
                                        className="check-icon"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/check.png"
                                        }
                                      />{" "}
                                      Lorem ipsum dolor sit
                                    </p>

                                    <p className="plan-details">
                                      <img
                                        className="check-icon"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/check.png"
                                        }
                                      />{" "}
                                      Lorem ipsum dolor sit
                                    </p>

                                    <p className="plan-details">
                                      <img
                                        className="check-icon"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/check.png"
                                        }
                                      />{" "}
                                      Lorem ipsum dolor sit
                                    </p>

                                    <button className="get-start-btn btn">
                                      Get Started
                                    </button>
                                  </div>
                                </SwiperSlide> */}

                              </Swiper>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      )}
    </>
  );
};

export default SimplePricing;
