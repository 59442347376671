import "./Banner.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import 'swiper/css';
import { Autoplay, Pagination } from "swiper";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Typed from 'react-typed';

function Banner() {

    const [plan, setPlan] = useState(false);
    const [user, setUser] = useState();

    const getStat = async () => {
        setPlan(sessionStorage.getItem('plan'));
        setUser(sessionStorage.getItem('userid'));
        console.log(user,'eeeeeeeeeeeeeeeeeeee');
      };

    useEffect(() => {
        getStat();
      }, []);
    return (
        <section className='main'>
            <div className="container-fluid p-0">
                <section className="home-banner">
                    <div className="row ms-0 me-0">
                        <div className="col-md-9 mx-auto">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="sec-1">
                                        <h1 className="title">Tool to <br/> manage your &nbsp;
                                            <span className="title-animation">
                                            
                                            {/* <Typed
                                              strings={['Health', 'Nutrition', 'Fitness', 'Wellness']}
                                              typeSpeed={150}
                                              backSpeed={80}
                                              loop
                                              cursorChar=""
                                          /> */}

                                                <Swiper
                                                    spaceBetween={30}
                                                    centeredSlides={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: false,
                                                    }}
                                                    pagination={{
                                                        clickable: false,
                                                    }}
                                                    loop={true}
                                                  
                                                    modules={[Autoplay, Pagination, Navigation]}
                                                    className="mySwiper"
                                                >
                                                
                                                <SwiperSlide><span className='anim-span'>Health</span></SwiperSlide>
                                                <SwiperSlide><span className='anim-span'>Nutrition</span></SwiperSlide>
                                                <SwiperSlide><span className='anim-span'>Fitness</span></SwiperSlide>
                                                <SwiperSlide><span className='anim-span'>Wellness</span></SwiperSlide>    
                                                                                        
                                                </Swiper>
                                            </span>
                                        </h1>
                                        <p className="subtitle">Seamless online programs to maintain health and fitness</p>

                                        <div className="start-btn-div">
  {user === null? (
    <Link to="/login">
      <button className='start-btn btn'>
        <span>Start <img className='next-icon' src={process.env.PUBLIC_URL + 'assets/images/icons/icon-next.svg'} alt='next' /></span>
      </button>
    </Link>
  ) : plan === true ? (
    <Link to="/choose-plan">
      <button className='start-btn btn'>
        <span>Start <img className='next-icon' src={process.env.PUBLIC_URL + 'assets/images/icons/icon-next.svg'} alt='next' /></span>
      </button>
    </Link>
  ) : (
    <Link to="/nutrition">
      <button className='start-btn btn'>
        <span>Start <img className='next-icon' src={process.env.PUBLIC_URL + 'assets/images/icons/icon-next.svg'} alt='next' /></span>
      </button>
    </Link>
  )}
</div>

                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="sec-2">
                                        <div className="img-div">
                                            <img className="mobile-img" src={process.env.PUBLIC_URL + 'assets/images/home/banner/mobile.png'} alt="mobile" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default Banner;