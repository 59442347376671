import * as React from "react";
import "./Form.css";
import { Rings } from 'react-loader-spinner';
import { Field } from "@progress/kendo-react-form";
import { FormInput, FormUpload } from "./form-components";
import Parser from "html-react-parser";
import {getOneUserInputApi} from "../api/Apis";

import // userNameValidator,
// emailValidator,
// passwordValidator
"./validators";
import { useState } from "react";
import {getGender} from "../api/Apis";
import { useEffect, useContext } from "react";
// import { MyContext } from "./StepContext";

const Gender = () => {
  const[nameofGender,setNameofGender]=useState(true)
  // const { stepval, setStepval } = useContext(MyContext);
  const genderApi = {
    gender:""
  };
  const [fizoUsers, setFizoUsers] = useState(genderApi);
  const [loading, setLoading] = useState(true);



  const [gender, setGender] = useState(true);
const [userid, setUserId] = useState(sessionStorage.getItem('userid'));

const getStat = async () => {
  await getOneUserInputApi()
    .then((res) => {
      console.log(res.data,'lllllllllll');
      if(res.data.data.list != null){
        
        setGender(true);
        
    setLoading(false);
        if(res.data.data.list.gender == 'Female'){
          
       sessionStorage.setItem('gender','Female')
    setLoading(false);
    setGender(false);
        }if(res.data.data.list.gender == 'Male'){
          setGender(true); 
          
      sessionStorage.setItem('gender','Male')
    setLoading(false);
        }
        
      }else{
        setGender(true);
        sessionStorage.setItem('gender','Male');

        setLoading(false);
      }
      console.log(res.data.data.list.gender,"w");
     
    })
    .catch((error) => {
      console.log(error);
    });
};


  const ChnageGender = async () => {
    if (gender == true) {
       setGender(false);
       sessionStorage.setItem('gender','Female')
    } else {
      setGender(true);
      sessionStorage.setItem('gender','Male')
    }
  };

  useEffect(() => {
   
    getStat();
}, []);
  return (
    <>
      <section className="main">
        <div className="container p-0">
          <form>
            <div className="row workout-gender">
              <div className="col-md-7 my-auto">
                <div className="sec-Gender">
                  <h1 className="field-title text-md-start text-center">
                    Let's get to know <br></br> you better
                  </h1>

                  <div className="title-bd text-md-start text-center  "></div>
                  <p className="substitle-p text-md-start text-center">Choose Gender</p>
                </div>
              </div>
              {loading ? (
                  <Rings color="#00BFFF" height={80} width={80} />
                  ) : (
              <div className="col-md-5">
                <div className="sec-2">
                  <div className="d-flex justify-content-center">
                    {gender ? (
                      <>
                        <div>
                          <img
                            className="male-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/step-form/male.png"
                            }
                          />
                        </div>
                        <div>
                          <img
                            className="female-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/step-form/female.png"
                            }
                            onClick={ChnageGender}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <img
                            className="male-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/step-form/Path 23.png"
                            }
                            onClick={ChnageGender}
                          />
                        </div>
                        <div>
                          <img
                            className="female-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/step-form/Path 22.png"
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
                  )}
            </div>
            {/* <button onClick={handleSubmit}>Get</button> */}
          </form>
        </div>
      </section>
    </>
  );
};

export default Gender;
