import * as React from "react";
import "./Form.css";
import { Field } from "@progress/kendo-react-form";
import { CardSelector } from "./card-selector";
import { FormInput, FormMaskedTextBox, FormDateInput } from "./form-components";
import { requiredValidator, cardValidator, cvcValidator } from "./validators";
import { getInputApi, getHabitsApi, getOneUserInputApi } from "../api/Apis";
import { useEffect } from "react";
import { useState } from "react";
const Habits = () => {
  // let signupid;
  // let habitsName;
  const habitsApi = {
    user_input_id: "",
    goal_id: "",
  };
  const [data, setData] = useState([]);
  const [habit, setHabit] = useState([]);

  const [fizoUsers, setFizoUsers] = useState(habitsApi);
  const [goalUser, setGoalUser] = useState();


  const getData = async () => {
    await getInputApi()
      .then((res) => {
        console.log(res.data.data.habits);
        setData(res.data.data.habits);
      })
      .catch((error) => {
        console.log(error);
      });

  };

  const postData = (id) => {
    sessionStorage.setItem("habit", id);
    console.log("clicked");
  };

  const getStat = async () => {
    await getOneUserInputApi()
      .then((res) => {
        
        if(res.data.data.list.goal_id != null){
          setGoalUser(res.data.data.list.goal_id);
       
          sessionStorage.setItem("habit", res.data.data.list.goal_id);
        }else{
          sessionStorage.setItem("habit", "0");
        }
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    getStat();
  }, []);
  // console.log(habit);
  return (
    <>
      <section className="main">
        <div className="container p-0">
          <form>
            <div className="row goals-top">
              <div className="col-md-5  my-auto">
                <div className="sec-1">
                  <h1 className="field-title">Goals</h1>
                  <div className="title-bd"></div>
                  <p className="substitle-p">
                  Your habits selection makes us easy to assign a diet plan & workout
                  </p>
                </div>
              </div>

              <div className="col-md-7">
              <div className="sec-2">
                  {/* <ul class="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
                    {data?. reduce((pairs, val, i)=>{
                      if(i % 2===0){
                        pairs.push([val]);

                      }
                      else{
                        pairs[pairs.length - 1].push(val);
                      }
                      return pairs;
                    }, []).map((pair, i) => {
                      // return (
                        <div className="row">
                          {pair.map((val, buttonIndex) =>{
                          <div className="col-lg-6 col-md-12" key={buttonIndex}>
                            <li className="nav-item" role="presentation" key={i}>
                              <button
                                className={goalUser == val.id? "nav-link active":"nav-link"}
                                //   id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => postData(val.id)}
                              >
                                {val.name}
                              </button>
                            </li>
                          </div>

                          })}
                        </div>
                      // );
                    })}
                  </ul> */}

<ul className="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
  {data?.reduce((pairs, val, i) => {
    if (i % 2 === 0) {
      pairs.push([val]);
    } else {
      pairs[pairs.length - 1].push(val);
    }
    return pairs;
  }, []).map((pair, i) => (
    <div className="row" key={i}>
      {pair.map((val, buttonIndex) => (
        <div className="col-lg-6 col-md-12" key={buttonIndex}>
          <li className="nav-item" role="presentation">
            <button
              className={goalUser === val.id ? "nav-link active" : "nav-link"}
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => postData(val.id)}
            >
              {val.name}
            </button>
          </li>
        </div>
      ))}
    </div>
  ))}
</ul>

                </div>
              </div>
            </div>
            {/* <button onClick={handleSubmit}>get</button> */}
          </form>
        </div>
      </section>
    </>
  );
};
export default Habits;
