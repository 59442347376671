import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBnczKqzDRQM2wVh9DhSfgFsUUoybo5RFg",
    authDomain: "fizomobile-e32bf.firebaseapp.com",
    projectId: "fizomobile-e32bf",
    storageBucket: "fizomobile-e32bf.appspot.com",
    messagingSenderId: "559125014396",
    appId: "1:559125014396:web:979e2b9da6147dc18ce6f9",
    measurementId: "G-PMWLCLKNCR"
})

const db = firebaseApp.firestore()

const auth = firebase.auth()

export  { db,auth};