import React from 'react'
import './Bmr.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link } from 'react-router-dom';
import VideoBg from '../video/omlet.mp4';

export const Bmr = () => {
  return (
    <>
        <section className='main'> 
            <div className='container-fluid p-0'>
                <TopNav />
                <section className='bmr-details'>
                    <div className='container'>                                            
                        <div className='row'>
                            <div className='col-lg-6 mx-auto'>
                                <div className='back-div'>
                                    <Link to="/body-analysis">
                                        <div className='back-btn-div'>
                                            <img className='back-btn' src={process.env.PUBLIC_URL + '/assets/images/icons/back.png'} alt="" />
                                        </div> 
                                    </Link> 
                                </div>   
                                <div className='bmr-div'>
                                    <div className='title-div'>
                                        <h6 className='title'>What is BMR ?</h6>
                                    </div>

                                    <div className='mt-4'>
                                        <p className='content-p'>Your basal metabolic rate measures the minimum amount of calories that your body needs to perform necessary functions. These functions include:</p>
                                    </div>

                                    <ul className='mt-3'>
                                        <li className='content-p'>Pumping blood throughout your body</li>
                                        <li className='content-p'>Digesting food</li>
                                        <li className='content-p'>Breathing</li>
                                        <li className='content-p'>Keeping a stable body temperature</li>
                                        <li className='content-p'>Growing hair and skin</li>
                                        <li className='content-p'>Maintaining levels of different chemicals</li>
                                    </ul>
                                </div>  
                            </div>
                        </div>                     
                    </div>
                </section>

                <Add />
            </div>
        </section>

        
    </>
  )
}
export default Bmr;
