import React from "react";
import "./ChoosePlan.css";
import Add from "../add/Add";
import axiosClient from "../api/ApiInstance.js";
import TopNav from "../top-nav/TopNav";
import { Link, useNavigate  } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getPlan, getPlans,getReviews } from "../api/Apis";
import useRazorpay from "react-razorpay";

export const ChoosePlan = () => {

  const ReadMore = ({ children }) => { 
    const text = children; 
    const [isReadMore, setIsReadMore] = useState(true); 
    const toggleReadMore = () => { 
      setIsReadMore(!isReadMore); 
    }; 
    return ( 
      <p className="text"> 
        {isReadMore ? text.slice(0, 50) : text} 
        <p className="mt-1 ReadMore_txt"
                onClick={toggleReadMore}
        >{isReadMore ? "...read more" : " show less"} </p>
      </p> 
    ); 
  }; 
  const [response, setResponse] = useState();
  const [formData1, setFormData1] = useState({
    plan: "",
  });
  const [loading, setLoading] = useState(true);
  const [couponOffer, setCouponOffer] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponDescription, setCouponDescription] = useState("");
  const [reviews, setReview] = useState();
  const [plan, setPlan] = useState();
  const [plans, setPlans] = useState();
  const [storedValue, setStoredValue] = useState();
  const [packageId, setPackage_id] = useState();
  const [plan_typeId, setPlan_type_id] = useState();
  const [checkId, setCheckId] = useState();
  const [indexvalue, setIndexValue] = useState();
  let navigate = useNavigate();
  const [Razorpay, openRazorpay] = useRazorpay();

  const imageUrl = "https://api.fizo.in/";
  const storedvalue = async (val, plan, packagedata) => {
    console.log(val,'gggggggg');
    console.log(plan,'ttttttttt');
    console.log(packagedata,'fffffff');
    setStoredValue(Math.round(val));
    setPackage_id(packagedata);
    setPlan_type_id(plan);
    setCheckId(packagedata);
    document.querySelector('#flexRadioDefault'+packagedata).click();
    setCouponOffer("");
    document.getElementById('coupon-code').value = "";
    setCouponCode("");
    setCouponDescription("");
    //    console.log(Math.round(val),'aaadxcdec')
  };

  const applyCoupon = async () => {
    var code = document.getElementById('coupon-code');
    let discounted;
    let discountedvalue;
    axiosClient
      .post("/couponvalidation", {
        coupon_code: code.value,
      })
      .then((res) => {
        if(res.data.message == 'false'){
          console.log(res.data,'afsaf');
          setCouponOffer("Invalid Coupon!");
          setCouponDescription("");
       
        }else{
          console.log(res.data,'kkkkkkkkk');
          if(res.data.data.disscount != null){
            discounted = storedValue-(storedValue * res.data.data.disscount) / 100;
          }else{
            discounted = storedValue-res.data.data.amount;
          }
          discountedvalue = storedValue-discounted;
          setStoredValue(discounted);
          setCouponOffer(discountedvalue + " Rs. discounted on plan selected.");
          setCouponCode(res.data.data.id);
          setCouponDescription(res.data.data.couponinfo);
        }
        
      })
      .catch((error) => {
        console.log(error.response);
      });
    
  };

  const handleRazorpay = async (storedValue) => {
      
    console.log(storedValue, "ghjgfjh");
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: storedValue * 100,
      currency: "INR",
      name: "Hippikart",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        handlesButtonClick();
        console.log(res, "ttttttttttttttttttttttttttttt");
        // return res;
        await setResponse(res);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();

  };

  const getStat = async () => {
    await getPlan()
      .then((res) => {
        //    console.log(res.data.data.activityLevel[0].name);
        setPlan(res.data.data.plan);
        // console.log(res.data.data);
        // setPlan(res.data.data.package);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStats = async (val) => {
    try {
      const response = await getPlans(val);
      setPlans(response?.data?.data?.package);
      setIndexValue(response?.data?.data?.package[0]);
    } catch (error) {
      console.log(error);
    }
  };


  const handleButtonClick = async (val) => {
    getStats(val);
  };

  const handlesButtonClick = async () => {
    axiosClient
      .post("/subscription", {
        amount: storedValue,
        plan_type_id: plan_typeId,
        package_id: packageId,
        coupon_code: couponCode,
      })
      .then((res) => {
        console.log(res.data , 'eeeeeeeeeeeeeee');

        if(res.data.success == true){
          navigate("/subcircular");
        }

      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getReview = async () => {
    await getReviews()
      .then((res) => {
        console.log(res.data.data.reviews,'reviews');
        setReview(res.data.data.reviews)
       
    setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


 
  useEffect(() => {
    getStat();
    getStats();
    getReview();
    // openRazorpayWindow();
  }, []);

  return (
    <>
      <section className="main">
        <TopNav />
        <div className="container-fluid p-0">
          <section className="choose-plan-section">
            <div className="choose-plan-tab-section my-auto">
              <div className="container">
                <div className="title-div">
                  <h6 className="title">
                    Choose your plan
                    <Link className="skip-text" to="/nutrition">
                      Skip
                    </Link>
                  </h6>
                </div>

                <div className="tabs-div mt-4">
                  <ul
                    class="nav nav-pills mb-3 justify-content-center"
                    id="pills-tab"
                    role="tablist"
                  >
                    {plan?.map((val) => (
                      <li class="nav-item" role="presentation" key={val.id}>
                        <button
                          className="nav-link"
                          id={`pills-${val.id}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#pills-${val.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`pills-${val.id}`}
                          aria-selected="true"
                          onClick={() => handleButtonClick(val.id)}
                        >
                          {val.name}
                        </button>
                      </li>
                    ))}
                  </ul>

                  <div class="tab-content pt-3" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-weekly"
                      role="tabpanel"
                      aria-labelledby="pills-weekly-tab"
                      tabindex="0"
                    >
                      <div className="tab-data">
                        <div className="row">
                          <div className="col-md-11 mx-auto">
                            <div className="row justify-content-center">
                              {plans?.map((val,index) => {
                                return (
                                  <>
                                    <div className="col-xl-4 col-lg-5 col-md-6">
                                      <div
                                        className="subscription-card active"
                                        for="flexRadioDefault1"
                                      >
                                        <div className="row form-check">
                                          <div className="col-lg-9 col-md-9 col-9">
                                            <div className="d-flex">
                                              <div className="input-div">
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="flexRadioDefault"
                                                  id={"flexRadioDefault"+val?.id}
                                                  onClick={() =>
                                                    storedvalue(val?.price,val?.plan_type?.id,val?.id)
                                                  }
                                                  checked = {checkId == val?.id?true:false}
                                                />
                                              </div>

                                              <div className="plan-div">
                                                <h6 className="rupees">
                                                  ₹{val?.price}{" "}
                                                  <span className="week"></span>
                                                </h6>
                                                <p className="plan-p">
                                                  {val?.plan_type?.name} plan
                                                </p>
                                                {index == 0 ? (
                                                  <p className="validity-p"></p>
                                                  
                                                ) : (
                                                  <p className="validity-p">
                                                    ₹
                                                    {val?.plan_type?.id == 2?(
                                                          (indexvalue?.price * 3) - val?.price 
                                                    ):(
                                                     (indexvalue?.price * 6) - val?.price 
                                                    )}
                                                      &nbsp;save on this plan
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          {index == 0? (
                                            <p className="validity-p"></p>
                                          ) : (
                                            <div className="col-lg-3 col-md-3 col-3">
                                              <div className="save-div">
                                                {/* <img
                                                  className="down-arrow"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/icons/down-arrow.png"
                                                  }
                                                /> */}
                                                <div className="save-p-pos">
                                                  <p className="save-p">
                                                    Save &nbsp;
                                                    {val?.plan_type?.id == 2?(
                                                          Math.round(((indexvalue?.price * 3) - val?.price)/(indexvalue?.price * 3)*100)
                                                    ):(
                                                      Math.round(((indexvalue?.price * 6) - val?.price)/(indexvalue?.price * 6)*100)
                                                    )}
                                                    %
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}

                              {/* <div className='col-xl-4 col-lg-5 col-md-6'>
                                                                <div className='subscription-card' for="flexRadioDefault2">
                                                                    <div className='row form-check'>
                                                                        <div className='col-lg-9 col-md-9 col-9'>
                                                                            <div className='d-flex'>
                                                                                <div className='input-div'>
                                                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                                                </div>
                                                                                <div className='plan-div'>
                                                                                    <h6 className='rupees'>₹4.00 <span className='week'>/week</span></h6>
                                                                                    <p className='plan-p'>3 month plan</p>
                                                                                    <p className='validity-p'><span className='off-p'>₹134</span>  ₹67 for the first 3 months</p>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-3 col-md-3 col-3'>
                                                                            <div className='save-div'>
                                                                                <img className='down-arrow' src={process.env.PUBLIC_URL + '/assets/images/icons/down-arrow.png'} />
                                                                                <div className='save-p-pos'>
                                                                                    <p className='save-p2'>Save 25%</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                              {/* <div className='col-xl-4 col-lg-5 col-md-6'>
                                                                <div className='subscription-card' for="flexRadioDefault3">
                                                                    <div className='row form-check'>
                                                                        <div className='col-lg-9 col-md-9 col-9'>
                                                                            <div className='d-flex'>
                                                                                <div className='input-div'>
                                                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                                                                </div>
                                                                                <div className='plan-div'>
                                                                                    <h6 className='rupees'>₹9.75 <span className='week'>/week</span></h6>
                                                                                    <p className='plan-p'>1 month plan</p>
                                                                                    <p className='validity-p'><span className='off-p'></span>  ₹39 will be charged every 1 month</p>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-3 col-md-3 col-3'>
                                                                            <div className='save-div'>
                                                                                <img className='down-arrow' src={process.env.PUBLIC_URL + '/assets/images/icons/down-arrow.png'} />
                                                                                <div className='save-p-pos'>
                                                                                    <p className='save-p2'>Save 50%</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                            </div>

                            <div className="row">
                              <div className="col-xl-4 col-lg-6 col-md-10 mx-auto">
                                <div>
                                  <div class="search"
                                  style={{
                                    display:couponCode == ""?"block":"none"
                                  }}
                                  >
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter coupon code"
                                      id="coupon-code"
                                    />
                                    <button
                                    type="button"
                                      class="btn btn-primary"
                                      onClick={() => applyCoupon()}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>

                                <div className="coupon-modal"
                                style={{
                                  display:couponOffer != ""?"block":"none"
                                }}
                                >

                                  <p className="text">
                                    {/* <img
                                      className="yes-icon"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/yes-include.png"
                                      }
                                    /> */}
                                    <ul>
                                      <li className="text">{couponOffer}</li>
                                      {couponDescription?(<li>{couponDescription}</li>):""}
                                    </ul>
                                    
                                    
                                  </p>
                                </div>

                                <div className="all-plans-include">
                                  <h6 className="title">All plans include</h6>

                                  <p className="text">
                                    <img
                                      className="yes-icon"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/yes-include.png"
                                      }
                                    />
                                    A personalized meal plan approved by
                                    nutritionists
                                  </p>

                                  <p className="text">
                                    <img
                                      className="yes-icon"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/yes-include.png"
                                      }
                                    />
                                    Over 1,000 easy-to-follow,delicious recipes.
                                  </p>

                                  <p className="text">
                                    <img
                                      className="yes-icon"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/yes-include.png"
                                      }
                                    />
                                    Customizable meal and ingredient
                                    substitutions
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6 col-md-12">
                                <div className="client-review">
                                  <h6 className="title">
                                    Client Reviews <span>Top 3 Reviews</span>
                                  </h6>
                                  {reviews?.slice(0,3).map((val)=>(
                                  <div className="review-card">
                                    <div className="row">
                                      <div className="col-sm-8 col-12  my-auto">
                                        <div className="d-flex my-auto">
                                          <div className="img-div">
                                            <img
                                              className=""
                                              src={
                                                imageUrl +
                                                "public/" +
                                                val?.pic
                                              }
                                            />
                                          </div>
                                          <div className="content my-auto">
                                            <h6 className="name">{val?.user_name}</h6>
                                            <p className="review">
                                            
                                            <ReadMore> 
                                            {val?.description}
                                            </ReadMore> 
                                            </p>
                                            
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-12">
                                        <div className="star-div">
                                          <div className="d-flex justify-content-end">
                                          {[...Array(val?.star_id)].map((x) =>
                                            <div>
                                            <img
                                              className="star-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/icons/review-star.png"
                                              }
                                            />
                                          </div>
                                          )}
                                          {[...Array(5 - val?.star_id)].map((x) =>
                                            <div>
                                            <img
                                              className="star-img"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/icons/star.png"
                                              }
                                            />
                                          </div>
                                          )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  ))}
                                  {/* <div className="review-card">
                                    <div className="row">
                                      <div className="col-sm-8 col-12  my-auto">
                                        <div className="d-flex my-auto">
                                          <div className="img-div">
                                            <img
                                              className=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/clients/client3.png"
                                              }
                                            />
                                          </div>
                                          <div className="content my-auto">
                                            <h6 className="name">Name</h6>
                                            <p className="review">
                                              Lorem Ipsum dolor sit amet
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-4 col-12">
                                        <div className="star-div">
                                          <div className="d-flex justify-content-end">
                                            <div>
                                              <img
                                                className="star-img"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/review-star.png"
                                                }
                                              />
                                            </div>
                                            <div>
                                              <img
                                                className="star-img"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/review-star.png"
                                                }
                                              />
                                            </div>
                                            <div>
                                              <img
                                                className="star-img"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/review-star.png"
                                                }
                                              />
                                            </div>
                                            <div>
                                              <img
                                                className="star-img"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/review-star.png"
                                                }
                                              />
                                            </div>
                                            <div>
                                              <img
                                                className="star-img"
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/review-star.png"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12">
                                <div className="faq">
                                  <h6 className="title">FAQ's</h6>

                                  <div class="accordion mt-4" id="accordionExample">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header">
                                        <button
                                          class="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                           What is Fizo?
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse show"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <p className="faq-content">
                                          Fizo is an online fitness platform which provide online nutrition and fitness consultation.Nutrihealthplus  app serves you with customised nutrition and workout programs to help you loose fat,gain muscle, tone body maintain health or manage medical condition.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header">
                                        <button
                                          class="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseTwo"
                                          aria-expanded="false"
                                          aria-controls="collapseTwo"
                                        >
                                          How does online fitness program work?
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseTwo"
                                        class="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <p className="faq-content">
                                          To start your transformation journey, you can select a package from the app.After evaluating your stats our experts design a personalise diet and workout plan which suits your lifestyle and preferences.Your program is allocated in the app where our team closely monitors your progress and help you achieve your goals.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header">
                                        <button
                                          class="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                        >
                                          How much the program costs ?
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseThree"
                                        class="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <p className="faq-content">
                                          We are here to provide you with the most affordable packages . Packages comes under various categories. For more info please visit...
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header">
                                        <button
                                          class="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseFour"
                                          aria-expanded="false"
                                          aria-controls="collapseFour"
                                        >
                                          How much weight loss / muscle gain can I expect during the program?
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseFour"
                                        class="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <p className="faq-content">
                                          Each one of us has a different body type, lifestyle, eating habits, stress levels current condition etc.Amount of weight loss/gain differ from person to person.We are here to provide you with the best service to get maximum out of the program.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header">
                                        <button
                                          class="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseFive"
                                          aria-expanded="false"
                                          aria-controls="collapseFive"
                                        >
                                          How often can I call and ask questions to my coach ?
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseFive"
                                        class="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <p className="faq-content">
                                          Your coach is available on call and message at any time of the day. You can also schedule a weekly call to discuss queries and progress.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="text-center">
                              <button
                                className="update-btn btn"
                                onClick={() => handleRazorpay(storedValue)}
                              >
                                Activate plan
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Add />
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default ChoosePlan;
