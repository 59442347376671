import React from "react";
import "./ManageSubscription.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link, useNavigate } from "react-router-dom";
import { getManageSpecification, getSubCheck } from "../api/Apis";
import { useEffect } from "react";
import { useState } from "react";
import { Rings } from 'react-loader-spinner';

export const ManageSubscription = () => {
  
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const [loading, setLoading] = useState(true);
  const [subcheck, setSubCheck] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const getData = async () => {
    await getManageSpecification()
      .then((res) => {
        // console.log(res, 'manage')
        setData(res.data.data);
     
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getUserSubscription = async () => {
    await getSubCheck()
      .then((res) => {
        console.log(res.data.data.subs.subscription, 'manage')
        if(res.data.data.subs.subscription == 'unpaid'){
          setSubCheck(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    getUserSubscription();
  }, []);
  return (
    <>
      <section className="   main ">
        <TopNav />
        <div className="container-fluid p-0">
          <section className="subscription-section">
            <div className="subscription-tab-section pt-5 my-auto">
              <div className="container">
                <div className="title-div">
                  <h6 className="title">Manage Subscription</h6>
                </div>
                {loading ? (
                    <div className="loader-ring"
                    
                    >
       <Rings color="#00BFFF" height={80} width={80} 
       
       />
       </div>
      ) : (
                <div className="tabs-div mt-4">
                 

                  <div class="tab-content pt-3" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-weekly"
                      role="tabpanel"
                      aria-labelledby="pills-weekly-tab"
                      tabindex="0"
                    >
                      <div className="tab-data">
                        <div className="row">
                          <div className="col-md-11 mx-auto">
                            <div className="row ">
                              {data?.map((d, index) => {
                                return (
                                  <div
                                    className="col-xl-4 col-lg-5 col-md-6"
                                    key={index}
                                  >
                                    <div
                                      className="subscription-card active"
                                      for="flexRadioDefault1"
                                    >
                                      <div className="row form-check">
                                        <div className="col-lg-8 col-md-8 col-8">
                                          <div className="d-flex">
                                            <div className="plan-div">
                                              <h6 className="rupees">
                                                ₹{d?.module?.price}{" "}
                                                <span className="week">
                                                  /{d?.module?.plan?.name}
                                                </span>
                                              </h6>
                                              <p className="plan-p">
                                                {d?.module?.plan_type?.name}
                                              </p>
                                              <p className="validity-p">
                                                <b> Start Date </b>
                                                {d?.s_date}
                                              </p>
                                              <p className="validity-p">
                                                <b> End Date </b> {d?.e_date}
                                              </p>
                                              <p className="validity-p">
                                                <b> Specification </b>{" "}
                                                {htmlToReactParser.parse(d?.module?.specification)}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-3 col-3">
                                              <div className="save-div">
                                                {/* <img
                                                  className="down-arrow"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/icons/down-arrow.png"
                                                  }
                                                /> */}
                                                {d?.deleted_at == null?(
                                                  <div className="save-p-pos">
                                                  <p className="save-p"
                                                  style={{
                                                    background:'#10ff00'
                                                  }}
                                                  >
                                                    Active
                                                  </p>
                                                </div>
                                                ):(
                                              <div className="save-p-pos">
                                                  <p className="save-p"
                                                  style={{
                                                    background:'#d9d2d2'
                                                  }}
                                                  >
                                                    InActive
                                                  </p>
                                                </div>
                                                )}
                                                

                                              </div>
                                            </div>
                                    </div>
                                  </div>
                                );
                              })}

                              
                            </div>

                            {subcheck?(
                                  <div className="text-center">
                                  <button
                                    className="update-btn btn"
                                    onClick={() => {
                                      navigate(`/choose-plan`);
                                      // /choose-plan
                                    }}
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#exampleModal"
                                  >
                                    Update Plan
                                  </button>
                                  </div>
                            ):(
                              <div className="text-center">
                              <div
                                className="update-btn btn"
                              >
                                Already Plan Active
                              </div>
                            </div>
                            )}
                           

                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="pills-monthly"
                      role="tabpanel"
                      aria-labelledby="pills-monthly-tab"
                      tabindex="0"
                    >
                      <div className="tab-data"></div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="pills-yearly"
                      role="tabpanel"
                      aria-labelledby="pills-yearly-tab"
                      tabindex="0"
                    >
                      <div className="tab-data"></div>
                    </div>
                  </div>
                </div>
      )}
                <Add />
              </div>
            </div>

            {/* start modal section  */}
            <div className="modal-section">
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog  modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <p className="success-msg">Plan update success </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end modal section  */}
          </section>
        </div>
      </section>
    </>
  );
};

export default ManageSubscription;
