import React from "react";
import axiosClient from "../api/ApiInstance.js";
import "./MyReports.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Rings } from 'react-loader-spinner';
import { getOneUserInputApi,getInfoApi } from "../api/Apis";

export const MyReports = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const hiddenFileInput = useRef(null);
  const [myReport, setReport] = useState();
  const [bmr, setBmr] = useState(0);
  const [tee, setTee] = useState(0);
  const [bmi, setBmi] = useState(0);
  const [noSubs, setNoSubs] = useState(true);
  const [fileName, setFileName] = useState("null");
  const [file, setFile] = useState("null");
  const [fileexist, setfileexist] = useState("null");
  const [errors, setErrors] = useState(false);
  const [errMsg, setErrMsg] = useState({
    errors: [],
    isError: false,
  });
  const [show, setShow] = useState(false);

  const getStat = async () => {
    await getOneUserInputApi()
      .then((res) => {
        setReport(res.data.data.list);
        
        for(let i = 0;i <= res.data.data.list.bmr;i++){
          setTimeout(() => {
            setBmr(Math.round(i));
        }, 100);
        }
        for(let i = 0;i <= res.data.data.list.tee;i++){
          setTimeout(() => {
            setTee(Math.round(i));
        }, 100);
        }
        for(let i = 0;i <= res.data.data.list.bmi;i++){
          setTimeout(() => {
            setBmi(Math.round(i));
        }, 1000);
        }
        setTee(res.data.data.list.tee);
        setBmi(res.data.data.list.bmi);
        console.log(res.data.data, "wwwwwwwwwwwwwwwww");

        // setPlan(res.data.data.package);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getData = async () => {
    await getInfoApi()
      .then((res) => {
        console.log(res.data.data,'bloodreport');
        let split = res.data.data.user.blood_pdf.split("/");
        setFileName(split[3]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (event.target.files.length != 0) {
      console.log(event.target.files[0]);
      setFileName(event.target.files[0].name);
      setFile(event.target.files[0]);
      setfileexist(event.target.files[0].name);
      setErrors(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(fileName,'files');
    if (fileexist == "null") {
      setErrors(true);
      console.log(fileName,'files');
      setLoading(false);
    }else{
      const blood_pdf = new FormData();
      blood_pdf.append("blood_pdf", file);
  
      console.log(blood_pdf);
      axiosClient
        .post("/pdfs", blood_pdf)
        .then((res) => {
          console.log(res.data,'filesssssssssss');
          setNoSubs(false);
          getData();
          setLoading(false);
          setfileexist("null");
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);
          setErrMsg({
            errors: error.response.data,
            isError: true,
          });
          if (error) {
            setShow(true);
          }
        });
    }
   
  };

  const modalClick = () => {
    setNoSubs(true);
  };

  useEffect(() => {
    getData();
    getStat();
  }, [noSubs]);
  return (
    <>
      <section className="main">
        <TopNav />
        <div className="container-fluid p-0">
          <section className="myreport-section">
            <div className="container">
              <div className="title-div pt-5">
                <h6 className="title">Reports</h6>
              </div>
              <div className='back-btn-div' 
                            onClick={() => navigate(-1)}
                            >
                                <img className='back-btn' src={process.env.PUBLIC_URL + '../assets/images/icons/back.png'} alt="" />
                            </div> 
              <div className="row mt-4">
                <div className="col-md-10 mx-auto">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                      <div className="bmr-card">
                        <img
                          className="bg-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/report/bmr.png"
                          }
                        />
                        <div className="overlay">
                          <p className="title">BMR</p>
                          <h1 className="h1">{
                         bmr
                          }</h1>
                          <p className="subtext">Calories/day</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3  col-md-6  col-sm-6 col-6">
                      <div className="bmr-card">
                        <img
                          className="bg-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/report/tee.png"
                          }
                        />
                        <div className="overlay">
                          <p className="title">TEE</p>
                          <h1 className="h1">{tee}</h1>
                          <p className="subtext">kcal/d</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12  col-sm-12">
                      <div className="bmr-card">
                        <img
                          className="bg-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/report/bmi.png"
                          }
                        />
                        <div className="overlay">
                          <div className="d-flex p-xl-4 p-lg-2 p-md-2">
                            <div className="pe-4">
                              <p className="title">BMI</p>
                              <h1 className="h1">
                                {bmi}
                              </h1>
                              <p className="subtext">kg/m2</p>
                            </div>
                            <div className="normal-div">
                              <h5 className="normal-title">(Normal)</h5>
                              <p className="text">
                                Healthy BMI range: 18.5 kg/m2 - 25 kg/m2
                              </p>
                              <p className="text">
                                Healthy weight for the height: 59.9 kgs - 81.0
                                kgs
                              </p>
                              <p className="text">Ponderal Index: 11.1 kg/m3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="blood-report-div">
                    <h6 className="title">Upload your blood report</h6>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <input
                            type="file"
                            id="bloodpdf"
                            ref={hiddenFileInput}
                            accept="application/pdf"
                            value=""
                            onChange={handleChange}
                            hidden
                          ></input>
                          <button
                            className="attach-btn btn"
                            type="button"
                            onClick={handleClick}
                          >
                            Attach
                            <img
                              className="attach-icon"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/attachment.png"
                              }
                            />
                          </button>
                          <span className="text text-danger">
                                {errors ? "Select the file first." : ""}
                              </span>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="d-flex justify-content-end">
                            <div>
                              {fileName == "null" ? (
                                ""
                              ) : (
                                <img
                                  className="pdf-icon"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/pdf.png"
                                  }
                                />
                              )}
                            </div>
                            <div className="my-auto">
                              {fileName == "null" ? (
                                ""
                              ) : (
                                <p className="pdf-text">{fileName.substring(0,10)}</p>
                              )}
                              
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt-5 mb-3">
                      {loading ? (
                  <Rings color="#00BFFF" height={80} width={80} />
                  ) : (
                        <button className="upload-btn btn">
                          Upload
                          <img
                            className="attach-icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/upload.png"
                            }
                          />
                        </button>
                  )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
              <div className="subcription-modal">
                <div
                  class={noSubs?"modal fade":"modal fade show"}
                  id="subsModal"
                 style={{
                  display:noSubs?"none":"block"
                 }}
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">
                          PDF Updated Successfully..!
                        </h1>
                        {/* <p className="cal-no">480cal</p> */}
                        <button
                          type="button"
                          class="btn-close"
                          onClick={modalClick}
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="text-center">
                          
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <Add />
          </section>
        </div>
      </section>
    </>
  );
};

export default MyReports;
