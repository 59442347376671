import React from "react";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./bodyanalysis.css";

const BodyAnalysis = () => {
  const percentage = 100;
  let tID = setTimeout(function () {
    window.location.href = 
    "/body-analysis"; 
    window.clearTimeout(tID);
}, 4000);
  return (
    <>
      {/* <CircularProgressbar value={percentage} text={`${percentage}%`} /> */}
      {/* </Example>
    <Example label="Stroke width"> */}
    <div className="centered-container">
      <div className="analysis-div mx-auto">
      <span className='progress-span'><b>Your body analysis <br/> is complete</b> </span>
        <CircularProgressbar
          value={percentage}
          text=""
          strokeWidth={5}
        />
      </div>

    </div>
    </>
  );
};

export default BodyAnalysis;
