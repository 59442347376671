import React, { useEffect, useState } from "react";
import "./WaterIntake.css";
import axiosClient from '../api/ApiInstance.js'
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link } from "react-router-dom";
import WaterIntakeAnim from "../../src/animation/water-intake/WaterIntakeAnim";
import { getWater } from "../api/Apis";
import { getsWater } from "../api/Apis";
import { getYest } from "../api/Apis";
import axios from 'axios';
import Charts from "../sleep-score/Charts";
import CanvasJSReact from "@canvasjs/react-charts";
import { Chart } from "@canvasjs/react-charts";
import "../sleep-score/chart.css";
import "../sleep-score/SleepScore.css";
import { Rings } from 'react-loader-spinner';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const WaterIntake = () => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Weekly");
  const [target, setTarget] = useState();
  const [data, setData] = useState();
  const[count,setCount]=useState(0)
  const[datas,setDatas]=useState()
  const[yester,setYester]=useState()

  const [height1, setHeight1] = useState();
  const [height2, setHeight2] = useState();
  const [height3, setHeight3] = useState();
  const [height4, setHeight4] = useState();
  const [height5, setHeight5] = useState();
  const [height6, setHeight6] = useState();

  const [monthlyHeight1, setMonthlyHeight1] = useState();
  const [monthlyHeight2, setMonthlyHeight2] = useState();
  const [monthlyHeight3, setMonthlyHeight3] = useState();
  const [monthlyHeight4, setMonthlyHeight4] = useState();
  const [monthlyHeight5, setMonthlyHeight5] = useState();
  const [monthlyHeight6, setMonthlyHeight6] = useState();

  const monthly = selectedOption === "Monthly" ? "monthly" : "";
  const weekly = selectedOption === "Weekly" ? "weekly" : "";

  const getData = async (monthly, weekly) => {
    await getWater(monthly, weekly)
      .then((res) => {
        if(res.data != null){
          console.log(res.data ,'waterrrrrrrrrr');
          // console.log(res.data.data.sleepScoremonth[0]);
          // setTime(res.data.data.current_time)
          setTarget(res.data.data.target);
          setData(Math.round(res.data.data.waterPercentageDay[0]));
          setHeight1(Math.round(res.data.data.waterPercentageDay[0]));
          setHeight2(Math.round(res.data.data.waterPercentageDay[1]));
          setHeight3(Math.round(res.data.data.waterPercentageDay[2]));
          setHeight4(Math.round(res.data.data.waterPercentageDay[3]));
          setHeight5(Math.round(res.data.data.waterPercentageDay[4]));
          setHeight6(Math.round(res.data.data.waterPercentageDay[5]));
          if(res.data.data.water != null){
            setDatas(Math.round(res.data.data.water.glass));
          }else{
            setDatas(0);
          }
          if(res.data.data.yesterdaywaterglass != null){
            setYester(Math.round(res.data.data.yesterdaywaterglass.glass));
          }else{
            setYester(0);
          }
          
          // console.log(Math.round(res.data.data.yesterdaywaterglass.glass));
        }else{
          setDatas(0);
          setYester(0);
        }
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMonthlyData = async (monthly, weekly) => {
    await getWater(monthly, weekly)
      .then((res) => {
        setMonthlyHeight1(Math.round(res.data.data.waterPercantageWeek[0]));
        setMonthlyHeight2(Math.round(res.data.data.waterPercantageWeek[1]));
        setMonthlyHeight3(Math.round(res.data.data.waterPercantageWeek[2]));
        setMonthlyHeight4(Math.round(res.data.data.waterPercantageWeek[3]));
        setMonthlyHeight5(Math.round(res.data.data.waterPercantageWeek[4]));
        setMonthlyHeight6(Math.round(res.data.data.waterPercantageWeek[5]));

      })
      .catch((error) => {
        console.log(error);
      });
  };
  let val;
  const handleOptions = (event) => {
    setSelectedOption(event.target.value);
    val = event.target.value;
    console.log(val);
  };

  

  let h1 = height1;
  let h2 = height2;
  let h3 = height3;
  let h4 = height4;
  let h5 = height5;
  let h6 = height6;

  let Mh1 = monthlyHeight1;
  let Mh2 = monthlyHeight2;
  let Mh3 = monthlyHeight3;
  let Mh4 = monthlyHeight4;
  let Mh5 = monthlyHeight5;
  let Mh6 = monthlyHeight6;

  

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    backgroundColor: "transparent",
    axisX: {
        labelFormatter: () => "", // Remove x-axis labels
        gridThickness: 0, // Remove y-axis grid lines
        tickThickness: 0, // Remove tick lines from x-axis
    },
    axisY: {
        minimum: 0, // Set the minimum value for the y-axis
        maximum: 100, // Set the maximum value for the y-axis
        interval: 10, // Set the interval between custom labels
        includeZero: true, // Ensure that zero is included
        labelFormatter: (e) => {
            return e.value.toFixed(0); // Format the labels as integers
        },
        gridThickness: 0, // Remove y-axis grid lines
    },
    data: [
        {
            type: "column",
            indexLabelPlacement: "outside",
            indexLabelFontSize: 12,
            fillOpacity: 1,
            borderColor: "transparent",
            color: "#274bbf",
            cornerRadius: 100,
            indexLabelFontSize: 16,
            indexLabelFontColor: "white",
            dataPoints: [
                {
                    y: selectedOption === "Weekly" ? h1 : Mh1,
                    indexLabel: selectedOption === "Weekly" ? "D1" : "W1",
                },
                {
                    y: selectedOption === "Weekly" ? h2 : Mh2,
                    indexLabel: selectedOption === "Weekly" ? "D2" : "W2",
                },
                {
                    y: selectedOption === "Weekly" ? h3 : Mh3,
                    indexLabel: selectedOption === "Weekly" ? "D3" : "W3",
                },
                {
                    y: selectedOption === "Weekly" ? h4 : Mh4,
                    indexLabel: selectedOption === "Weekly" ? "D4" : "W4",
                },
                {
                    y: selectedOption === "Weekly" ? h5 : Mh5,
                    indexLabel: selectedOption === "Weekly" ? "D5" : "W5",
                },
                {
                    y: selectedOption === "Weekly" ? h6 : Mh6,
                    indexLabel: selectedOption === "Weekly" ? "D6" : "W6",
                },
            ],
        },
    ],
};


  const sub = () => {
    
    setLoading(true);
    const updatedCount = datas - 1;
    setCount(updatedCount);
    sendPostRequest(updatedCount);
    axiosClient
    .post("/water", {
      glass: updatedCount
   
    })
    .then(res => {
      console.log(res.data,'sub');
      getData(monthly, weekly);
      
    setLoading(false);
    })
    .catch(error => {
      console.log(error.response);
      
    setLoading(false);
    })
  };

  const add = () => {
    setLoading(true);
    const updatedCount = datas + 1;
    setCount(updatedCount);
    sendPostRequest(updatedCount);
    axiosClient
    .post("/water", {
      glass: updatedCount
   
    })
    .then(res => {
      console.log(res.data,'add');
      getData(monthly, weekly);
      setLoading(false);
    })
    .catch(error => {
      console.log(error.response);
      
    setLoading(false);
    })
  };

  const sendPostRequest = async (updatedCount) => {
   

    axiosClient
    .post("/water", {
      glass: updatedCount
   
    })
    .then(res => {
      console.log(res.data);
      
    })
    .catch(error => {
      console.log(error.response);
      
    })
  };
  
  useEffect(() => {
   
    getData(monthly, weekly);
    getMonthlyData(monthly, weekly)
  }, [selectedOption]);
  return (
    <>
      <section className="main">
        <TopNav />
        <div className="container-fluid p-0">
          <section className="water-intake-section">
            <div className="container">
              <div className="title-div pt-5">
                <h6 className="title">Water</h6>
                <h5 className="title-sleep">Water Intake</h5>
              </div>
              <Link to="/progress">
                            <div className='back-btn-div'>
                                <img className='back-btn' src={process.env.PUBLIC_URL + '../assets/images/icons/back.png'} alt="" />
                            </div> 
                            </Link>
              <div className="drop-div">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleOptions}
                  value={selectedOption}
                >
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>

              <br></br>

              <div className="score-div mt-5">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12 mb-5">
                    <div className="sec-1" style={{ position: "relative" }}>
                      <img
                        className="chart-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/sleep-score/sleep-chart.png"
                        }
                      />
                      <div className="bars">
                        <div className="sleep-charts">
                          <CanvasJSChart options={options} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12  mb-5">
                    <div className="sec-2">
                      <img
                        className="banner2"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/sleep-score/banner2.png"
                        }
                      />

                      <div className="overflow">
                        <div className="container">
                          <p className="good-night-p">
                            {datas >= target?"Your Daily Target Is Completed":"It's time to drink water"}
                          </p>

                          <WaterIntakeAnim />
                          {loading ? (
                            <div className="d-flex justify-content-center">
                               <div className="number-div">
                                  <Rings color="#00BFFF" height={80} width={80} />
                               </div>
                            </div>
                                   ) : (
                          <div className="d-flex justify-content-center mt-4">
                            <div className="button-div">
                              <button className="btn add-btn"
                               onClick={sub}
                               disabled={datas == 0?true:false}
                               >-</button>
                            </div>

                            <div className="number-div">
                            
                                  <p className="numbbbbb">{datas}</p>

                            </div>

                            <div className="button-div">
                              <button className="btn add-btn" onClick={add}>+</button>
                            </div>
                          </div>
                  )}
                          <hr className="border-bot"></hr>
                          <p className="good-night-p">History</p>

                          <div className="d-flex justify-content-center">
                           
                            <div className="bed-time-div">
                              <p className="bed-p">Yesterday</p>
                              <h6 className="time">{yester} Glass</h6>
                            </div>

                            <div className="bed-time-div">
                              <p className="bed-p">Today</p>
                              <h6 className="time">{datas} Glass</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Add />
      </section>
    </>
  );
};

export default WaterIntake;
