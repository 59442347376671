import React from 'react';
import  {db,auth}  from '../firebasefile';
import './Chatbot.css';
import firebase from 'firebase/compat/app';
import TopNav from '../top-nav/TopNav';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react'

export const Chatbot = () => {
    const scroll = useRef()
    const [messages, setMessages] = useState([])

    const [msg, setMsg] = useState('')

    function signInWithGoogle() {
        const provider = new firebase.auth.GoogleAuthProvider()
        auth.signInWithPopup(provider)
    }

    async function sendMessage(e) {
        e.preventDefault()
console.log(auth.currentUser,'llllll');
        const { uid, photoURL } = auth.currentUser
        await db.collection('messages').add({
            text: msg,
            uid,
            photoURL,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
        })
        setMsg('')
        scroll.current.scrollIntoView({ behavior: 'smooth' })
    }
    useEffect(() => {
        db.collection('messages').limit(50).onSnapshot(snapshot => {
            setMessages(snapshot.docs.map(doc => doc.data()))
        })
    }, [])
    return (
        <>
            <section className='main'>
                <div className='container-fluid p-0'>
                    <TopNav />
                    <div className="google-chatBot">
                            <button className="btn btn-success"  onClick={signInWithGoogle}>Sign In With Google</button>
                        </div>
                    <section className='chatbot-main'>
                        <div className='container'>
                            <div className='chat-header'>
                                <div className='d-flex'>
                                    <div className='back-div'>
                                        <Link to="/nutrition">
                                            <div className='back-btn-div'>
                                                <img className='back-btn' src={process.env.PUBLIC_URL + '/assets/images/icons/back.png'} alt="" />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='client-img-div'>
                                        <img className='client-img' src={process.env.PUBLIC_URL + '/assets/images/clients/client1.png'} alt="client" />
                                    </div>
                                    <div className='name-div my-auto'>
                                        <h6 className='name'>Rose Merry</h6>
                                        <p className='active-status'>Online</p>
                                    </div>
                                </div>
                            </div>

                            <div className='chat-content-box'>
                                <div className='text-center'>
                                    <p className='chat-day'>Today</p>
                                </div>


                                {/* <div className='row mb-1'>
                                    <div className='col-10'>
                                        <div className='recieved-message-div'>
                                            <div className='d-flex'>
                                                <p className='recieved-message'>Hii Aditya</p>
                                                <p className='time'>2:30</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {messages.map(({ id, text, photoURL, uid }) => (
                                <div className='row  mb-1 justify-content-end'>
                                    <div className='col-10'>
                                        <div className={`msg ${uid === auth.currentUser.uid ? 'sent-message-div' : 'recieved-message-div'}`}>
                                            <div className='d-flex'>
                                                <p className='sent-message'>{text}</p>
                                                <p className='time'>2:30 <img className='seen-icon' src={process.env.PUBLIC_URL + '/assets/images/icons/seen.png'} alt="seen" /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        ))}
                                


                                <div className='message-type-box'>
                                <form onSubmit={sendMessage}>
                                    <div className='d-flex all-message-strip'>
                                        <div className='attachment-div'>
                                            <input className='fileInput' type="file" name="" />
                                            <img className='attachment-icon' src={process.env.PUBLIC_URL + '/assets/images/icons/attachment.png'} />
                                        </div>
                                        <div className='message-input-div'>
                                            <textarea className='form-control' type='text' placeholder='Enter your text here...' onChange={e => setMsg(e.target.value)} >{msg}</textarea>
                                        </div>


                                        <div className='send-message-btn-div'>
                                            <div className='send-btn-div'>
                                                <button className='send-btn btn'><img className='next-icon' src={process.env.PUBLIC_URL + '/assets/images/icons/icon-next1.png'} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    </form>
                                </div>



                            </div>
                        </div>
                    </section>
                </div>
            </section>


        </>
    )
}
export default Chatbot;
