import * as React from "react";
import "./Form.css";
import { Field } from "@progress/kendo-react-form";
import { CardSelector } from "./card-selector";
import { FormInput, FormMaskedTextBox, FormDateInput } from "./form-components";
import { requiredValidator, cardValidator, cvcValidator } from "./validators";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HorizontalWeight from "../Horizontal Slider/HorizontalWeight";
import { getWeightApi, getInputApi,getOneUserInputApi } from "../api/Apis";
// import {} from "@thomasloven/round-slider";
import Roundy from "roundy";
import WeightGuage from "./WeightGuage";

const Weight = () => {
  // const [value, setValue] = useState(60);
  const [value, setValue] = useState("0");
  const [type, setType] = useState("Pound");
  var rangeSlider;
  var rangeBullet;

  const getWeight = {
    weight_id: "",
  };
  let Weightval = value;
  const [fizoUsers, setFizoUsers] = useState(getWeight);

  // const onChangedata = (e)=>{
  //   setValue(e.target.value)
  //   sessionStorage.setItem('weight',e.target.value)
  // }
  const [userData, setUserData] = useState();

  

  const [min1, setMin] = useState();
  const [max1, setMax] = useState();

  const getStat = async () => {
    await getOneUserInputApi()
      .then((res) => {
        console.log(res.data,'jfksdfhgj');
        if(res.data.data.list.weight_id != null){
          setValue(res.data.data.list.weight.name);
          sessionStorage.setItem("weight", res.data.data.list.weight.name);
          setType(res.data.data.list.weight.weighttype.name);

          if(res.data.data.list.weight.weighttype.name == "Kg"){
            sessionStorage.setItem("weightype", "kg");
          }else{
      sessionStorage.setItem("weightype", "pound");
          }
        }else{
          sessionStorage.setItem("weight", "0");
        }
        console.log(res.data.data.list,"w");
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDataW = async () => {
    let data = await getInputApi();
    if(type == "Pound"){
      setUserData(data.data.data.weightpo);
      sessionStorage.setItem("weightype", "pound");
    }else{
      setUserData(data.data.data.weightkg);
      sessionStorage.setItem("weightype", "kg");
    }
    setMin(userData[0]);
    setMax(userData[userData.length - 1].name);
  };
  const onChangeType = async (e) => {
    switch (e.target.value) {
      case "kg":
        {
          let data = await getInputApi();
          setUserData(data.data.data.weightkg);
          setMin(userData[0].name);
          setMax(userData[userData.length - 1].name);
          setValue(0);
          sessionStorage.setItem("weightype", "kg");
        }

        break;
      case "pound":
        {
          let data = await getInputApi();
          setUserData(data.data.data.weightpo);
          setMin(userData[0].name);
          setMax(userData[userData.length - 1].name);
          setValue(0);
          sessionStorage.setItem("weightype", "pound");
        }

        break;
      case "gram":
        {
          let data = await getInputApi();
          setUserData(data.data.data.weightgr);
          setMin(userData[0].name);
          setMax(userData[userData.length - 1].name);
          setValue(0);
          sessionStorage.setItem("weightype", "gram");
        }

        break;

      default:
        break;
    }
  };
  // console.log(userData);

  function showSliderValue() {
    rangeBullet.innerHTML = 
    
    
    rangeSlider.value;
    setValue(rangeSlider.value);
    // console.log(rangeSlider.value, "val");
    var bulletPosition = rangeSlider.value / rangeSlider.max;
    rangeBullet.style.left = bulletPosition * 578 + "px";
  }

  const onChangedata = (e) => {
    setMin(userData[0].name);
    setMax(userData[userData.length - 1].name);
    setValue(e.target.value);
    
  };
  sessionStorage.setItem("weight", value);
    console.log(sessionStorage.setItem("weight", value), "eee");

    useEffect(() => {
      rangeSlider = document.getElementById("rs-range-line");
      rangeBullet = document.getElementById("rs-bullet");
      getStat();
      getDataW();
      // rangeSlider.addEventListener("input", showSliderValue, false);
    }, [type]);
  return (
    <>
      <section className="main WeightSection ">
        <div className="container p-0">
          <form>
            <div className="row workout-weight">
              <div className="col-md-7 mb-5">
                <div className="sec-weight">
                  <h1 className="field-title">
                    Let's get to know <br></br> you better
                  </h1>

                  <div className="title-bd"></div>
                  <div className="d-flex">
                    <div>
                      <p className="substitle-p mt-2">Choose Weight</p>
                    </div>
                    <div className="tabbtn-grp">
                      <ul
                        class="nav nav-pills mb-3 justify-content-center"
                        id="pills-tab"
                        role="tablist">
                        <li class="nav-item ms-3" role="presentation">
                          <button
                            className={type == 'Kg'?" kgPound_btn btn nav-link active ":"  btn nav-link"}
                            id="pills-week1-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-week1"
                            type="button"
                            role="tab"
                            aria-controls="pills-week1"
                            aria-selected="true"
                            value="kg"
                            onClick={onChangeType}
                          >
                            Kg
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            className={type == 'Pound'?"kgPound_btn btn nav-link active ms-2":" btn nav-link"}
                            id="pills-week2-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-week2"
                            type="button"
                            role="tab"
                            aria-controls="pills-week2"
                            aria-selected="false"
                            value="pound"
                            onClick={onChangeType}
                          >
                            Pound
                          </button>
                        </li>

                       
                      </ul>
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="sec-2">
                  <div className="weight-male-img">
                   
                  
                  </div>
                  <div className="range-div">
                    <div className="container">
                      <div className="range-slider">
                       
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/step-form/weight-machine.png"
                          }
                          alt=".."
                          className="img-fluid mb-5"
                        
                        />
                        <div className="img-overlay">
                          <h1 className="text-center">{value}</h1>
                        </div>

                        <div className="col-md-10 mx-auto">
                          <input
                            className="weight-range progress"
                            step="1"
                            type="range"
                            value={value}
                            min={min1}
                            max={max1}
                            id="customRange2"
                            onChange={(e) => {
                              onChangedata(e);
                            }}
                          />
                        
                        </div>
                      </div>

                     
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
           
          </form>
        </div>
      </section>
    </>
  );
};
export default Weight;
