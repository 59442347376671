import React from 'react';
// import { Link } from 'react-router-dom';
import "./Footer.css";
import { useState, Component, useEffect } from "react";
import { getInfoApi, getReminders,setReminder } from "../api/Apis";
import { Link } from 'react-router-dom';
import { Scrollchor } from 'react-scrollchor';
import sound from './fizoalarm.mp3'
const Footer = () => {

    const [alarmExecute, setAlarmExecute] = useState(false);
    const [alarmText, setAlarmText] = useState("");
    const [date, setDate] = useState(new Date());
    const [infoData, setInfoData] = useState(false);
    const getData = async () => {
       
          const token = sessionStorage.getItem("token");
          // console.log(token,'tokencheck');
          if(token != null){
            setInfoData(true);
          }
      };


      const showReminder = async (date) => {
        const response = await getReminders();
        const res = await setReminder();
        // console.log(response?.data?.data?.active_post_time.includes(date+":00"));
          if(response?.data?.data?.active_post_time.includes(date+":00") && res?.data?.data?.main_status == 1){
            if(alarmExecute == false){
              setAlarmText("Time to take a meal...!");
              new Audio(sound).play();
             await setAlarmExecute(true);
            }
          }else if(response?.data?.data?.water_time.includes(date+":00") && response?.data?.data?.water_remind?.water_status == 1 && res?.data?.data?.main_status == 1){
            if(alarmExecute == false){
              setAlarmText("Time to drink water...!");
              new Audio(sound).play();
              setAlarmExecute(true);
            }
          }else{
            setAlarmText("");
            new Audio(sound).pause();
             setAlarmExecute(false);
          }
      };
      useEffect(() => {
       
    
        getData();
        const intervalId = setInterval(() => {
      
            setDate(new Date());
            
            showReminder(new Date().toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            }));
      
          }, 26000)
      
          return () => clearInterval(intervalId); 
      }, []);
    return (
        <>
            <section className='main'>
                <div className='container-fluid p-0'>
                    {/* start footer-section */}
                    <section className='footer'>
                        <div className='text-center'>
                            <div className='logo-div'>
                                {/* <img className='footer-logo' src={process.env.PUBLIC_URL + 'assets/images/logo/Fizo-footer.png'} alt='footer-logo' /> */}
                                <h2>fizo</h2>
                            </div>

                         <ul className="nav nav-pills   justify-content-center" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">

    <a className='foot-menu bd-right' href='/'>Explore</a>

                                    
                                </li>
                                <li className="nav-item" role="presentation">
                                <Scrollchor to="#sample-code1">
                                    <a className='foot-menu bd-right'>About Us</a>
                                    </Scrollchor>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link className='foot-menu bd-right' to={"/contact"}>Contact Us</Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link className='foot-menu bd-right' to={"/privacy"}>Privacy Policy</Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link className='foot-menu bd-right' to={"/terms"}>Terms & Conditions</Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className='foot-menu' href=''>Cancelation & Refund</a>
                                </li>
                            </ul>

                          {/* <div className='container'>
                                <div className='col-md-9'>
                                    <div className='row'>
                                        <div className='col'>
                                            <a className='foot-menu bd-right' href=''>Explore</a>
                                        </div>
                                        <div className='col'>
                                            <a className='foot-menu bd-right' href=''>Explore</a>
                                        </div>
                                        <div className='col'>
                                            <a className='foot-menu bd-right' href=''>Explore</a>
                                        </div>
                                        <div className='col'>
                                            <a className='foot-menu bd-right' href=''>Explore</a>
                                        </div>
                                        <div className='col'>
                                            <a className='foot-menu bd-right' href=''>Explore</a>
                                        </div>
                                        <div className='col'>
                                            <a className='foot-menu bd-right' href=''>Explore</a>
                                        </div>
                                    </div>
                                </div>
                            </div>  */}


                            <div className='copyright-div'>
                            <p className='copy-p'>© 2023 All Rights Reserved by Nutrihealthplus Pvt Ltd | Developed by <a className='fizo-span' href='https://profcymaglobal.com/'>Profcyma Solutions PVT LTD.</a></p>
                            </div>
                        </div>
                    </section>
                    {/* start footer-section */}
                </div>
            </section>
        </>

    );
}

export default Footer;