import * as React from "react";
import "./Form.css";
import { Field } from "@progress/kendo-react-form";
import { CardSelector } from "./card-selector";
import { FormInput, FormMaskedTextBox, FormDateInput } from "./form-components";
import { requiredValidator, cardValidator, cvcValidator } from "./validators";
import { getInputApi, getWorkoutPrefExp,getOneUserInputApi } from "../api/Apis";
import { useEffect } from "react";
import { useState } from "react";
const Workout = () => {
  const [workoutpref, setWorkoutPref] = useState();
  const [workoutexp, setWorkoutExp] = useState();

  const [workoutprefdata, setWorkoutprefdata] = useState();
  const [workoutexpdata, setWorkoutexpdata] = useState();
  const [workOutUser, setWorkoutUser] = useState();
  const [preferenceUser, setPreferenceUser] = useState();

  const getWorkoutprefData = {
    workout_prefrence_id: "",
    workout_experience_id: "",
  };
  const [fizoUsers, setFizoUsers] = useState(getWorkoutprefData);

 


  const handleChange = (e) => {
    if (e.target.id === "exp") {
      console.log("exp :- ", e.target.value);
      setWorkoutprefdata(e.target.value);
      
  sessionStorage.setItem("workoutexp", e.target.value);
    } else {
      console.log("pre :- ", e.target.value);
      setWorkoutexpdata(e.target.value);
      
  sessionStorage.setItem("workoutpref", e.target.value);
    }
    

  };

  const getData = async () => {
    await getInputApi()
      .then((res) => {
        // console.log(res.data.data.workoutpref[0].name);
        setWorkoutPref(res.data.data.workoutpref);
        setWorkoutExp(res.data.data.workoutexp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log(workoutexp);
  const getStat = async () => {
    await getOneUserInputApi()
      .then((res) => {
        console.log(res.data)
        if(res.data.data.list.workout_experience_id != null){
          
        setWorkoutUser(res.data.data.list.workout_experience_id);
        setPreferenceUser(res.data.data.list.workout_prefrence_id);
  sessionStorage.setItem("workoutexp", res.data.data.list.workout_experience_id);
  sessionStorage.setItem("workoutpref", res.data.data.list.workout_prefrence_id);
        }else{
          console.log(res.data.data.list,'hhjkjfgfjkl')
          sessionStorage.setItem("workoutexp", "0");
          sessionStorage.setItem("workoutpref", "0");
        }
       
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    getData();
    getStat();
  }, []);
  return (
    <>
      <section className="main">
        <div className="container p-0">
          <form>
            <div className="row workout-Preference">
              <div className="col-md-6 my-auto">
                <div className="sec-WorkOut">
                  <h1 className="field-title mt-md-5">
                    Workout Prefrence <br></br> & Experience
                  </h1>

                  <div className="title-bd"></div>
                  <p className="substitle-p">
                    Select your Workout Preference & Experience
                  </p>
                </div>
              </div>

              <div className="col-md-5">
                <div className="sec-2 mt-md-5 ">
                  <div className="workout-drop-div">
                    <select
                      class="form-select"
                      id="exp"
                      aria-label="Default select example"
                      onChange={handleChange}
                    >
                      <option value="0" selected>Select workout Experience</option>
                      {workoutexp?.map((val, i) => {
                        return (
                          <>
                            <option key={i} value={val.id} selected={workOutUser == val.id?true : false}>
                              {val.name}
                            </option>
                          </>
                        );
                      })}
                    </select>

                    <select
                      class="form-select"
                      aria-label="Default select example"
                      id="pre"
                      onChange={handleChange}
                    >
                      <option value="0" selected>Select workout preference </option>
                      {workoutpref?.map((val, i) => {
                        return (
                          <>
                            <option key={i} value={val.id} selected={preferenceUser == val.id?true : false}>
                              {val.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* <button onClick={handleSubmit}>get</button> */}
          </form>
        </div>
      </section>
    </>
  );
};
export default Workout;
