import React from 'react'
// import '../tee/Tee.css';

import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link } from 'react-router-dom';
import VideoBg from '../video/omlet.mp4';

export const Bmi = () => {
  return (
    <>
        <section className='main'> 
            <div className='container-fluid p-0'>
                <TopNav />
                <section className='bmr-details'>
                    <div className='container'>                                            
                        <div className='row'>
                            <div className='col-lg-6 mx-auto'>
                                <div className='back-div'>
                                    <Link to="/body-analysis">
                                        <div className='back-btn-div'>
                                            <img className='back-btn' src={process.env.PUBLIC_URL + '/assets/images/icons/back.png'} alt="" />
                                        </div> 
                                    </Link> 
                                </div>   
                                <div className='bmr-div'>
                                    <div className='title-div'>
                                        <h6 className='title'>What is BMI ?</h6>
                                    </div>

                                    <div className='mt-4'>
                                        <p className='content-p'>BMI is used by healthcare professionals to screen for overweight and obese individuals. The BMI is used to assess a person’s health risks associated with obesity and overweight.</p>
                                        <p className='content-p'>For example those with a high BMI are at risk of:-</p>
                                    </div>

                                    <ul className='mt-3'>
                                        <li className='content-p'>type 2 diabetes</li>
                                        <li className='content-p'>Stroke</li>
                                        <li className='content-p'>High blood pressure</li>
                                        <li className='content-p'>Certain cancers</li>
                                        <li className='content-p'>Gallbladder disease</li>
                                        <li className='content-p'>Premature death</li>
                                    </ul>


                                    <div className='all-weight-box-div'>                                    
                                    <img className='weight-img' src={process.env.PUBLIC_URL + '/assets/images/exercise/weight.png'} />
                                </div>
                                </div>  

                                
                            </div>
                        </div>                     
                    </div>
                </section>

                <Add />
            </div>
        </section>

        
    </>
  )
}
export default Bmi;
