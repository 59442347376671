import React, { useEffect, useState } from "react";
import './MoreDetails.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link, useParams } from 'react-router-dom';
import {getDietApi} from "../api/Apis";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';

export const MoreDetails = () => {

    const {  week, day } = useParams();
    const [data, setData] = useState(0);
    const getDetail = async () => {
        await getDietApi(week,day)
          .then((res) => {
             
         
            //   console.log(res.data.data, "moredetails");
              setData(res.data.data);
         
          })
          .catch((error) => {
            console.log(error,"hhhhhhhh");
          });
      };
    useEffect(() => {
        getDetail();
       
      }, []);
  return (
    <>
        <section className='main'> 
            <div className='container-fluid p-0'>
                <TopNav />

                <section className='more-details'>
                    <div className='container'>
                        <Link to="/nutrition">
                            <div className='back-btn-div'>
                                <img className='back-btn' src={process.env.PUBLIC_URL + '/assets/images/icons/back.png'} alt="" />
                            </div> 
                        </Link> 

                        <div className='more-title-div text-center'>
                            <p className='caloryp'>Calories</p>
                            <h1 className='calh1'>{data?.calories_left} cal <span >Left</span></h1>
                        </div>    

                        <div className='row mt-5'>
                            <div className='col-xl-4 col-lg-6 mb-4'>
                                <div className='sec-1'>
                                    <div className='row mb-4'>
                                        <div className='col-sm-4 mb-3'>
                                            <div className='d-flex'>
                                                <div>
                                                    {/* <img className='carbs-img' src={process.env.PUBLIC_URL + '/assets/images/nutrition/carrbs.png'} /> */}
                                                    <CircularProgressbar className="carbs-img" value={100-data?.carb_percent} text={`${100-data?.carb_percent}%`} 
                                      styles={buildStyles({
                                        // Colors
                                        pathColor: '#01B4D8',
                                        textColor: '#01B4D8',
                                        trailColor: '#d6d6d6',
                                        backgroundColor: '#01B4D8',
                                      })}
                                      />
                                                </div>
                                                <div className='ms-2'>
                                                    <h6 className='carbsh6'>Carbs</h6>
                                                    <p className='carbsgram'>{data?.carbs_left}g left</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-8 my-auto'>
                                            <p className='carbs-text'>You consume {100-data?.carb_percent}% carb</p>
                                        </div>
                                    </div>

                                    <div className='row mb-4'>
                                        <div className='col-sm-4 mb-3'>
                                            <div className='d-flex'>
                                                <div>
                                                    {/* <img className='carbs-img' src={process.env.PUBLIC_URL + '/assets/images/nutrition/fats.png'} /> */}
                                                    <CircularProgressbar className="carbs-img" value={100-data?.fat_percent} text={`${100-data?.fat_percent}%`} 
                                      styles={buildStyles({
                                        // Colors
                                        pathColor: '#F9C700',
                                        textColor: '#F9C700',
                                        trailColor: '#d6d6d6',
                                        backgroundColor: '#F9C700',
                                      })}
                                      />
                                                </div>
                                                <div className='ms-2'>
                                                    <h6 className='carbsh6'>Fats</h6>
                                                    <p className='carbsgram'>{data?.fat_left}g left</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-8 my-auto'>
                                            <p className='fats-text'>You consume {100-data?.fat_percent}% fat</p>
                                        </div>
                                    </div>

                                    <div className='row mb-4'>
                                        <div className='col-sm-4 mb-3'>
                                            <div className='d-flex'>
                                                <div>
                                                    {/* <img className='carbs-img' src={process.env.PUBLIC_URL + '/assets/images/nutrition/protien.png'} /> */}
                                                      <CircularProgressbar className="carbs-img" value={100-data?.protein_percent} text={`${100-data?.protein_percent}%`} 
                                      styles={buildStyles({
                                        // Colors
                                        pathColor: '#FF960F',
                                        textColor: '#FF960F',
                                        trailColor: '#d6d6d6',
                                        backgroundColor: '#FF960F',
                                      })}
                                      />
                                                </div>
                                                <div className='ms-2'>
                                                    <h6 className='carbsh6'>Protein</h6>
                                                    <p className='carbsgram'>{data?.protein_left}g left</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-8 my-auto'>
                                            <p className='protien-text'>You consume {100-data?.protein_percent}% protien</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-8 col-lg-6'>
                                <div className='sec-2'>
                                    <p className='content-p'>{data?.notes?.description}</p>
                                </div>
                            </div>
                        </div> 

                       
                    </div>
                </section>

                <Add />
            </div>
        </section>

        
    </>
  )
}
export default MoreDetails;
