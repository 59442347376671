import React from 'react'
import { Link } from 'react-router-dom';
import  './Add.css';


 const Add = () => {
  return (
    <>
        <div className='add-nut-ex-div'>
            <div className='d-flex box'>
                <Link to="/nutrition">
                    <div className='nutri-div'>
                        <p className='nutri-p'>Nutrition</p>
                    </div>
                </Link>

                <Link to="/progress">
                    <div className='add-div'>
                        <img src={'/assets/images/icons/plus.png'} />
                    </div>
                </Link>

                <Link to="/exercise-day">
                    <div className='nutri-div'>
                        <p className='nutri-p'>Exercise</p>
                    </div>
                </Link>
            </div>
        </div>
    </>
  )
}

export default Add;
