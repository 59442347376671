import React from 'react';
import { LinearGaugeComponent, AxesDirective, AxisDirective, PointersDirective, PointerDirective } from '@syncfusion/ej2-react-lineargauge';
const CentimeterGuage = (props) => {
    React.useEffect(() => {
        // updateSampleSection();
    }, []);
    let invertedPointer;
    let circlePointer;
    let diamondPointer;
    let rectanglePointer;
    let multiplePointer;
    function verticalGauge(e) {
        invertedPointer.width = circlePointer.width = diamondPointer.width = rectanglePointer.width = multiplePointer.width = '150px';
        invertedPointer.height = circlePointer.height = diamondPointer.height = rectanglePointer.height = multiplePointer.height = '350px';
        invertedPointer.orientation = circlePointer.orientation = diamondPointer.orientation = rectanglePointer.orientation = multiplePointer.orientation = "Vertical";
        if (e.currentTarget != null) {
            e.currentTarget['style']['color'] = "white";
            e.currentTarget['style']['backgroundColor'] = "#0074E3";
            document.getElementById('horizontal')['style']['color'] = "black";
            document.getElementById('horizontal')['style']['backgroundColor'] = "white";
            document.getElementById('containerInverted').className = document.getElementById('containerCircle').className =
                document.getElementById('containerDiamond').className = document.getElementById('containerRectangle').className =
                    document.getElementById('containerMultiple').className = "col-xs-4 col-sm-4 col-lg-2 col-md-2";
            document.getElementById('containerBox').style.display = "flex";
            document.getElementById('containerBox').style.padding = "6%";
        }
    }
    function load(args) {
    }
    
    function dragEndRectangle(args) {
        rectanglePointer.axes[0].pointers[0].animationDuration = 1500;
        rectanglePointer.axes[0].pointers[1].animationDuration = 1500;
    }
    function dragStartRectangle(args) {
        rectanglePointer.axes[0].pointers[0].animationDuration = 0;
        rectanglePointer.axes[0].pointers[1].animationDuration = 0;
    }
    function dragMoveRectangle(args) {
        if (args.pointerIndex == 1) {
            rectanglePointer.setPointerValue(0, 0, args.currentValue);
        }
    }
    return (<div className='control-pane'>
            <div className="control-section">
                <pre style={{ border: 'hidden', backgroundColor: 'inherit' }}></pre>
                <div id="containerBox" className="row" style={{ float: 'left', padding: '4%' }}></div>
                <div id='containerRectangle' className="col-xs-4 col-sm-4 col-lg-2 col-md-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LinearGaugeComponent dragStart={dragStartRectangle.bind(this)} dragEnd={dragEndRectangle.bind(this)} dragMove={dragMoveRectangle.bind(this)} load={load.bind(this)} title={props.value} titleStyle={{ fontFamily: 'inherit', fontWeight: '499' }} id='rectangleMarker' orientation='Vertical' width='150px' height='480px' background='transparent' ref={rectanglePointerInstance => rectanglePointer = rectanglePointerInstance}>
                        <AxesDirective>
                            <AxisDirective line={{ width: 5 }} minorTicks={{ interval: props.interval, height: 3 }} majorTicks={{ interval: props.interval, height: 10, width: 1 }} labelStyle={{ font: { fontFamily: 'inherit' } }} minimum={props.min} maximum={props.max} opposedPosition={true}  onChange={props.onChange}>
                                <PointersDirective>
                                    <PointerDirective value={props.value} enableDrag={true} height={5} width={5} placement='Near' type='Bar' animationDuration={1500} offset='12' color='#0077B6'>
                                    </PointerDirective>
                                    <PointerDirective value={props.value} enableDrag={true} height={5} width={50} placement='Near' markerType='Rectangle' color='#0077B6'>                                       
                                    </PointerDirective>
                                </PointersDirective>
                            </AxisDirective>
                        </AxesDirective>
                    </LinearGaugeComponent>
                </div>
            </div>
        </div>);
}

export default CentimeterGuage;