import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./Recipes.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { getRecipeApi } from "../api/Apis";

// import LinesEllipsis from "react-lines-ellipsis";
import { Ellipsis } from "react-simple-ellipsis";

export const Recipes = () => {
  const [id, setId] = useState(1);

  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const getData = async () => {
    await getRecipeApi()
      .then((res) => {
        console.log(res.data.data,'jjjjjjjj');
        setData1(res.data.data.popular_category);
        setData2(res.data.data.editor_choice);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);


  const imageUrl = "https://api.fizo.in/";
  console.log(data1);
  return (
    <>
      <section className="main">
        <TopNav />
        <div className="container-fluid p-0">
          <section className="recipe-section">
            <div className="recipe-tab-section pt-5">
              <div className="container">
                <div className="title-div">
                  <h6 className="title">Recipes</h6>
                </div>
                <Link to="/progress">
                            <div className='back-btn-div'>
                                <img className='back-btn' src={process.env.PUBLIC_URL + '../assets/images/icons/back.png'} alt="" />
                            </div> 
                            </Link>
                <div className="tabs-div mt-4">
                  <ul
                    class="nav nav-pills mb-3 justify-content-center"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-breakfast-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-breakfast"
                        type="button"
                        role="tab"
                        aria-controls="pills-breakfast"
                        aria-selected="true"
                        onClick={() => {
                          setId(1);
                        }}
                      >
                        Breakfast
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-lunch-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-lunch"
                        type="button"
                        role="tab"
                        aria-controls="pills-lunch"
                        aria-selected="false"
                        onClick={() => {
                          setId(2);
                        }}
                      >
                        Lunch
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-dinner-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-dinner"
                        type="button"
                        role="tab"
                        aria-controls="pills-dinner"
                        aria-selected="false"
                        onClick={() => {
                          setId(3);
                        }}
                      >
                        Dinner
                      </button>
                    </li>
                  </ul>

                  {/* Break Fast */}
                  <div class="tab-content pt-3" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-breakfast"
                      role="tabpanel"
                      aria-labelledby="pills-breakfast-tab"
                      tabindex="0"
                    >
                      <div className="tab-data">
                        <h6 className="title">Popular Recipes</h6>
                        <div className="row">
                          {data1?.map((value, index) => {
                            if (value?.recipe_type_id == 1) {
                              return (
                                <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-5 col-6"
                                  key={index}
                                >
                                  <Link to={`/recipe-details/${value?.id}`}>
                                    <div className="recipe-card">
                                      <img
                                        className="recipe-img"
                                        src={imageUrl + value?.image}
                                      />

                                      <p className="recipe-name">
                                        {value?.heading}
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              );
                            }
                          })}
                        </div>

                        <h6 className="title mt-4">Editor's Choice</h6>
                        {data2?.map((value, index) => {
                          if (value?.recipe_type_id === 1) {
                            return (
                              <Link
                                to={`/recipe-details/${value?.id}`}
                                key={index}
                              >
                                <div className="d-flex choice-card">
                                  <div className="img-div">
                                    <img
                                      className="recipe"
                                      src={imageUrl + value?.image}
                                    />
                                  </div>
                                  <div className="recipe-content-div">
                                    <h6 className="name">{value?.heading}</h6>

                                    <p className="content">
                                      {value?.short_desc}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            );
                          }
                        })}
                      </div>
                    </div>

                    {/* Lunch */}
                    <div
                      class="tab-pane fade"
                      id="pills-lunch"
                      role="tabpanel"
                      aria-labelledby="pills-lunch-tab"
                      tabindex="0"
                    >
                      <div className="tab-data">
                        <h6 className="title">Popular Recipes</h6>
                        <div className="row">
                          {data1?.map((value, index) => {
                            if (value?.recipe_type_id == 2) {
                              return (
                                <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-5 col-6"
                                  key={index}
                                >
                                  <Link to={`/recipe-details/${value?.id}`}>
                                    <div className="recipe-card">
                                      <img
                                        className="recipe-img"
                                        src={imageUrl + value?.image}
                                      />

                                      <p className="recipe-name">
                                        {value?.heading}
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              );
                            }
                          })}
                        </div>

                        <h6 className="title mt-4">Editor's Choice</h6>
                        {data2?.map((value, index) => {
                          if (value?.recipe_type_id === 2) {
                            return (
                              <Link
                                to={`/recipe-details/${value?.id}`}
                                key={index}
                              >
                                <div className="d-flex choice-card">
                                  <div className="img-div">
                                    <img
                                      className="recipe"
                                      src={imageUrl + value?.image}
                                    />
                                  </div>
                                  <div className="recipe-content-div">
                                    <h6 className="name">{value?.heading}</h6>

                                    <p className="content">
                                      {value?.short_desc}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            );
                          }
                        })}
                      </div>
                    </div>

                    {/* Dinner */}
                    <div
                      class="tab-pane fade"
                      id="pills-dinner"
                      role="tabpanel"
                      aria-labelledby="pills-dinner-tab"
                      tabindex="0"
                    >
                      <div className="tab-data">
                        <h6 className="title">Popular Recipes</h6>
                        <div className="row">
                          {data1?.map((value, index) => {
                            if (value.recipe_type_id == 3) {
                              return (
                                <div
                                  className="col-lg-2 col-md-3 col-sm-4 col-6"
                                  key={index}
                                >
                                  <Link to={`/recipe-details/${value.id}`}>
                                    <div className="recipe-card">
                                      <img
                                        className="recipe-img"
                                        src={imageUrl + value.image}
                                      />

                                      <p className="recipe-name">
                                        {value.heading}
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              );
                            }
                          })}
                        </div>

                        <h6 className="title mt-4">Editor's Choice</h6>
                        {data2?.map((value, index) => {
                          if (value.recipe_type_id === 3) {
                            return (
                              <Link
                                to={`/recipe-details/${value.id}`}
                                key={index}
                              >
                                <div className="d-flex choice-card">
                                  <div className="img-div">
                                    <img
                                      className="recipe"
                                      src={imageUrl + value.image}
                                    />
                                  </div>
                                  <div className="recipe-content-div">
                                    <h6 className="name">{value.heading}</h6>
                                    <p className="content">
                                      {value.short_desc}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                <Add />
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Recipes;
