import React from "react";
import "./Progress.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link, useNavigate } from "react-router-dom";

import CanvasJSReact from "./canvasjs.react";
import { getProgress, getSubCheck } from "../api/Apis";
import { useState } from "react";
import { useEffect } from "react";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Progress = () => {
  let navigate = useNavigate();
  const [type, setType] = useState("weekly");
  const [firstWeight, setFirst] = useState();
  const [subcheck, setSubCheck] = useState(true);
  const [messageErr, setmessageErr] = useState(false);
  const [gainLose, setGainLose] = useState();

  const [datapoints, setDataPoints] = useState([]);

  const getUserSubscription = async () => {
    await getSubCheck()
      .then((res) => {
        console.log(res.data.data.subs.subscription, 'manage')
        if (res.data.data.subs.subscription == 'unpaid') {
          setSubCheck(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getData = async () => {
    await getProgress(type)
      .then((res) => {
        console.log(res.data.data, 'afgfgafaf');
        if ((res.data.data.data_points).length > 1) {
          setDataPoints(res.data.data.data_points);
          setFirst(res.data.data.first_weight);
          setGainLose(res.data.data.gain_or_lose);
          setmessageErr(true)
        } else {
          setmessageErr(false)
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setDataPoints([]);
    getData();
    getUserSubscription();
  }, [type]);



  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
    title: {
      // text: "Bounce Rate by Week of Year"
    },
    axisY: {
      title: "Weight",
      // suffix: "%"
    },
    axisX: {
      title: type === 'weekly' ? "Days" : "Weeks",
      // prefix: "W",
      interval: type === 'weekly' ? 1 : 5
    },
    data: [{
      type: "line",
      // toolTipContent: "Week {x}: {y}%",
      dataPoints: datapoints,
    }]
  };

  return (
    <>
      <section className="main">
        <TopNav />
        <div className="container-fluid p-0">
          <section
            className={subcheck ? "progress-section pt-5" : "progress-section pt-5 progress-section-blur"}
          >
            <div className="container">
              <div className="title-div">
                <h6 className="title">Progress</h6>
                {/* <p className="success">5 kg weight loss</p> */}
              </div>

              <div className="row justify-content-end">
                <div className="col-md-3">
                  <div className="drop-div mb-5">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      <option value="weekly" selected>
                        Weekly
                      </option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </div>
                </div>
              </div>
              {messageErr ? (
                <div>
                  <div>
                    <CanvasJSChart options={options} />
                  </div>

                  <div className="row">
                    <div className="col-md-8 mx-auto">
                      <div className="d-flex mt-4">
                        <div className="col-md-1"><h6>{Math.round(firstWeight)} Kgs Start</h6></div>
                        <div className="col-md-10 ">
                          <div className="progress-bar-section">
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100">
                              <div
                                className="progress-bar"
                                style={{
                                  width: "100%"
                                }}></div>
                            </div>
                          </div>

                        </div>
                        <div className="col-md-1 ms-2"><h6>{gainLose}</h6></div>

                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-6 mx-auto mt-5">
                      <div className="card ">
                        <div className="card-body">

                          <h3 className="noAssigned">Less Data to show graph..!</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}


              <div className="tabs-menu-section">
                <div className="tab-div">
                  <ul class="nav justify-content-center">
                    <li class="nav-item">
                      <Link to="/hamburger-progress">
                        <div className="icon-div">
                          <img
                            className="icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/progress/progress.png"
                            }
                          />
                        </div>
                        <p className="name">Progress</p>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/my-reports">
                        <div className="icon-div">
                          <img
                            className="icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/progress/chart.png"
                            }
                          />
                        </div>
                        <p className="name">Reports</p>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/calculator">
                        <div className="icon-div">
                          <img
                            className="icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/progress/calculator.png"
                            }
                          />
                        </div>
                        <p className="name">Calculator</p>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="">
                        <div className="icon-div">
                          <img
                            className="icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/progress/smart-watch.png"
                            }
                          />
                        </div>
                        <p className="name">Devices</p>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/blog">
                        <div className="icon-div">
                          <img
                            className="icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/progress/content.png"
                            }
                          />
                        </div>
                        <p className="name">Blogs</p>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/recipes">
                        <div className="icon-div">
                          <img
                            className="icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/progress/cookbook.png"
                            }
                          />
                        </div>
                        <p className="name">Recipies</p>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/sleep-score">
                        <div className="icon-div">
                          <img
                            className="icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/progress/moon.png"
                            }
                          />
                        </div>
                        <p className="name">Sleep</p>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/water-intake">
                        <div className="icon-div">
                          <img
                            className="icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/progress/water.png"
                            }
                          />
                        </div>
                        <p className="name">Water</p>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/reminder">
                        <div className="icon-div">
                          <img
                            className="icon"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/progress/Bell.png"
                            }
                          />
                        </div>
                        <p className="name">Reminder</p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


          </section>
          <div className="subcription-modal">
            <div
              class={subcheck ? "modal fade" : "modal fade show"}
              id="subsModal"
              style={{
                display: subcheck ? "none" : "block"
              }}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      Get Premium to access all the features.
                    </h1>
                    {/* <p className="cal-no">480cal</p> */}

                  </div>
                  <div class="modal-body">
                    <div className="text-center">
                      <Link to={"/choose-plan"}>
                        <button
                          className="add-btn btn btn-info"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Go Premium!
                        </button>
                      </Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Add />
      </section>
    </>
  );
};

export default Progress;
