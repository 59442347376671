import * as React from 'react';
import { CircularGaugeComponent, AxesDirective, AxisDirective, Inject, AnnotationsDirective, AnnotationDirective, PointersDirective, PointerDirective, RangesDirective, RangeDirective, Annotations, } from '@syncfusion/ej2-react-circulargauge';
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
function WeightGuage(props) {
    React.useEffect(() => {
        // updateSampleSection();
    }, []);
    let gauge;
    let drag;
    let pointerDrag;
    let rangesDrag;
    let content = '<div style="font-size: 14px;color:#E5C31C;font-weight: lighter;font-style: oblique;"><span>';
    function onChartLoad(args) {
        document.getElementById('drag-container').setAttribute('title', '');
    }
    ;
    function dragMove(args) {
        if (args.type.indexOf('pointer') > -1) {
            document.getElementById('pointerValue').innerHTML = String(Math.round(args.currentValue));
            drag.value = Math.round(args.currentValue).toString();
            gauge.setAnnotationValue(0, 0, content + Math.round(args.currentValue) + ' KG</span></div > ');
        }
    }
    ;
    function dragEnd(args) {
        if (isNaN(args.rangeIndex)) {
            setPointersValue(gauge, Math.round(args.currentValue));
        }
    }
    ;
    function load(args) {
    }
    function dragChange() {
        let pointerValue = +drag.value;
        document.getElementById('pointerValue').innerHTML = String(Math.round(pointerValue));
        setPointersValue(gauge, pointerValue);
    }
    function pointerDragChange() {
        let value = pointerDrag.checked;
        gauge.enablePointerDrag = value;
    }
    function rangesDragChange() {
        let value = rangesDrag.checked;
        gauge.enableRangeDrag = value;
    }
    function setPointersValue(circulargauge, pointerValue) {
        let color;
        if (pointerValue >= 0 && pointerValue <= 40) {
            color = '#30B32D';
        }
        else if (pointerValue >= 40 && pointerValue <= 100) {
            color = '#E5C31C';
        }
        else {
            color = '#F03E3E';
        }
        circulargauge.axes[0].pointers[0].color = color;
        circulargauge.axes[0].pointers[1].color = color;
        circulargauge.axes[0].pointers[0].animation.enable = false;
        circulargauge.axes[0].pointers[1].animation.enable = false;
        circulargauge.axes[0].pointers[0].needleTail.color = color;
        circulargauge.axes[0].pointers[1].needleTail.color = color;
        circulargauge.axes[0].pointers[0].cap.border.color = color;
        circulargauge.axes[0].pointers[1].cap.border.color = color;
        circulargauge.setPointerValue(0, 1, pointerValue);
        circulargauge.setPointerValue(0, 0, pointerValue);
        content = '<div style="font-size: 14px;color:' + color + ';font-weight: lighter;font-style: oblique;"><span>';
        circulargauge.setAnnotationValue(0, 0, content + pointerValue + ' KG</span></div>');
    }
    return (<div className='control-pane'>
        <div className='control-section row'>
            <div className='col-lg-8'>
                <CircularGaugeComponent load={load.bind(this)} loaded={onChartLoad.bind(this)} background='transparent' dragMove={dragMove.bind(this)} dragEnd={dragEnd.bind(this)} id='drag-container' ref={g => gauge = g} enablePointerDrag={true} enableRangeDrag={false}>
                    <Inject services={[Annotations]} />
                    <AxesDirective>
                        <AxisDirective startAngle={220} endAngle={140} radius='80%' minimum={0} maximum={120} majorTicks={{
                            useRangeColor: true
                        }} lineStyle={{ width: 0 }} minorTicks={{
                            useRangeColor: true
                        }} labelStyle={{
                            useRangeColor: true,
                            font: {
                                fontFamily: 'inherit'
                            }
                        }}>
                            <AnnotationsDirective>
                                <AnnotationDirective content='<div style="font-size: 14px;color:#E5C31C;font-weight: lighter;font-style: oblique;margin-left:-20px;"><span>70 KG</span></div>' angle={180} radius='45%' zIndex='1'>
                                </AnnotationDirective>
                            </AnnotationsDirective>
                            <PointersDirective>
                                <PointerDirective value={props.value} radius='60%' markerWidth={5} cap={{
                                    radius: 10, border: { width: 5, color: '#E5C31C' }
                                }} needleTail={{
                                    length: '0%', color: '#E5C31C'
                                }} color='#E5C31C' />
                                <PointerDirective value={props.value} radius='110%' color='#E5C31C' markerWidth={15} markerHeight={15} type='Marker' markerShape='InvertedTriangle'></PointerDirective>
                            </PointersDirective>
                            <PointerDirective value={props.value} type="Marker" markerShape='InvertedTriangle' radius='110%' markerHeight={20} color='#E5C31C' markerWidth={20} />
                            <RangesDirective>
                                <RangeDirective start={0} end={40} radius='108%' color='#0077B6' startWidth={8} endWidth={8} />
                                <RangeDirective start={40} end={100} radius='108%' color='#0077B6' startWidth={8} endWidth={8} />
                                <RangeDirective start={100} end={120} radius='108%' color='#0077B6' startWidth={8} endWidth={8} />
                            </RangesDirective>
                        </AxisDirective>
                    </AxesDirective>
                </CircularGaugeComponent>
            </div>
            {/* Property Panel */}
            <div className='col-lg-4 property-section d-none'>
                <table id='property' title='Properties' className='property-panel-table' style={{ width: '100%', marginLeft: "-10px" }}>
                    <tbody>
                        <tr style={{ height: "50px" }}>
                            <td style={{ width: "50%" }}>
                                <div style={{ width: "110%", fontSize: "14px" }}>Pointer Value </div>
                            </td>
                            <td style={{ width: "40%" }}>
                                <div style={{ marginLeft: '5px' }}>
                                    <input type="range" id="value" ref={d => drag = d} value={props.value} min={props.min} max={props.max} style={{ width: "100%", paddingLeft: '0px' }} />
                                </div>
                            </td>
                            <td style={{ width: '10%' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <span id='pointerValue' style={{ fontSize: "14px" }}>70</span>
                                </div>
                            </td>
                        </tr>
                        <tr style={{ height: "50px" }}>
                            <td style={{ width: "20%" }}>
                                <div id='enablePointer' style={{ width: "90%", fontSize: "14px" }}>Allow Pointer Drag</div>
                            </td>
                            <td style={{ width: "49%" }}>
                                <div style={{ paddingTop: "0px", marginLeft: "-4px" }}>
                                    <CheckBoxComponent id='enable' checked={true} change={pointerDragChange.bind(this)} ref={d => pointerDrag = d} />
                                </div>
                            </td>
                        </tr>
                        <tr style={{ height: "50px" }}>
                            <td style={{ width: "20%" }}>
                                <div id='enablePointer' style={{ width: "90%", fontSize: "14px" }}>Allow Ranges Drag</div>
                            </td>
                            <td style={{ width: "40%" }}>
                                <div style={{ paddingTop: "0px", marginLeft: "-4px" }}>
                                    <CheckBoxComponent id='rangeDragEnable' checked={false} change={rangesDragChange.bind(this)} ref={d => rangesDrag = d} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>);
}
export default WeightGuage;