import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./activateplan.css"
library.add(fas,)
const ActivatePlan = () => {
  let tID = setTimeout(function () {
    window.location.href = 
    "/nutrition"; //replace with your url
    window.clearTimeout(tID);// clear time out.
}, 4000);
  return (
    <>
<div className='activate-plan text-center'>
    <div className='activate mx-auto mb-2'>
    <FontAwesomeIcon icon="fa-solid fa-check" className='fa-icon'/>
    </div>
    <h5>Your plan has been <br/> Activated</h5>
</div>
    </>
  )
}

export default ActivatePlan