import React from "react";
import "./ExerciseDetails.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link, useParams, useNavigate } from "react-router-dom";
import VideoBgWorkout from "../video/workout/workout.mp4";
import { useEffect } from "react";
import { useState } from "react";
import { getWorkDaywise } from "../api/Apis";

export const ExerciseDetails = () => {
  let navigate = useNavigate();
  const [response, setResponse] = useState();
  const [formData1, setFormData1] = useState({
    plan: "",
  });

  const { name, id, wk, we, ids } = useParams();

  // const params = useParams();
  // console.log("Id "+  params?.id);
  const [workoutday, setExeday] = useState();
  const [nextWork, setNextWork] = useState();
  const imageUrl = " https://api.fizo.in/";

  const videoEnd = async () => {
    console.log("end");
  navigate(`/exercise-details/${name}/${nextWork?.day_id}/${nextWork?.workout_pack_id}/${nextWork?.week_id}/${nextWork?.id}`);
  window.location.reload(); 
  };
  const getStatidays = async () => {
    await getWorkDaywise(id, wk, we,ids)
      .then((res) => {
        //    console.log(res.data.data.activityLevel[0].name);
        console.log(res.data,ids);
        const data = res.data.data.workout_per_day.filter((d) => d.id == ids);
        setExeday(data);
        setNextWork(res.data.data.nextWork);
        
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    getStatidays();

    // openRazorpayWindow();
  }, []);
  return (
    <>
      <section className="main">
        <div className="container-fluid p-0">
          <TopNav />
          {workoutday?.map((val) => {
            return (
              // params?.id == val?.id ? (
              <>
                <section className="exercise-details">
                  <div className="container">
                    <div className="revipe-back-div">
                      <Link 
                      to={`/exercise/${name}/${id}/${wk}/${we}`}
                      >
                        <div className="back-btn-div">
                          <img
                            className="back-btn"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/back.png"
                            }
                            alt=""
                          />
                        </div>
                      </Link>
                    </div>

                    <div className="workout-video-div">
                      <div>
                        <video
                          className="video"
                          src={
                            imageUrl +
                            process.env.PUBLIC_URL +
                            val.workout_data.video_link
                          }
                          onEnded={videoEnd}
                          autoPlay
                          controls
                        />
                      </div>

                      <div className="vdo-title-div">
                        <h3 className="title">{val.workout_data.name}</h3>
                        <p className="repetation-p">
                          Sets: {val.workout_data.sets} | Reps:{" "}
                          {val.workout_data.reps}
                        </p>
                      </div>

                      <div className="next-recipe-div">
                     
                        <div className="d-flex">
                          <div className="me-2">
                            <p className="next-p">Next</p>
                            <h6 className="reci-h6">{nextWork?.workout_data?.name}</h6>

                          </div>
                          
                          <div
                          onClick={videoEnd}
                          >
                            <img
                              className="next-img"
                              src={
                                imageUrl +
                                nextWork?.workout_data?.image
                              }
                            />
                          </div>
                          
                        </div>
                      
                      </div>
                    </div>

                    <div>
                      <p className="weight-lift-p">
                        Weight Lifted <span className="span-kg">35 kg</span>
                      </p>
                      <hr></hr>
                    </div>

                    <div className="exercise-content-div">
                      <h6 className="workout-name">{val.workout_data.name}</h6>
                      <p className="workout-content-p">
                        {val.workout_data.description}
                      </p>
                    </div>
                  </div>
                </section>
              </>
              // ) : (<></>)
            );
          })}
          <Add />
        </div>
      </section>
    </>
  );
};
export default ExerciseDetails;
