// import * as React from 'react';
import React, { useState, useEffect } from "react";
import "../vertical-slider/slider.css";
import "./Form.css";
import { Rings } from 'react-loader-spinner';
import { Field } from "@progress/kendo-react-form";
import {
  FormInput,
  FormAutoComplete,
  FormRadioGroup,
  FormTextArea,
} from "./form-components";
import { nameValidator, requiredValidator } from "./validators";
import { countries, genders } from "./data";
import { Link } from "react-router-dom";
import { getHeightApi, getInputApi,getOneUserInputApi } from "../api/Apis";
import CentimeterGuage from "./CentimeterGuage";
// import female from "../../public/assets/images/step-form/female.png"

const Height = () => {
  const [isActive, setIsActive] = useState(false);
  const getHeight = {
    height_id: "",
  };

  // let val=value;
  const [loading, setLoading] = useState(true);
  const [fizoUsers, setFizoUsers] = useState(getHeight);
  const [userData, setUserData] = useState();
  const [image, setImage] = useState();
  const[cm,setCm]=useState(false)
  const[inch,setInch]=useState(false)
  const[ft,setFt]=useState(false)
  const [value, setValue] = useState(0);
  const [userValue, setUserValue] = useState(0);
  const [min1, setMin] = useState();
  const [max1, setMax] = useState();
  const [interval, setInterval] = useState();
  const [type, setType] = useState("cm");
  const gen = sessionStorage.getItem("gender");

  const onChangeType = async (e) => {
    switch (e.target.value) {
      case "cm":
        {
          let data = await getInputApi();
          setUserData(data.data.data.heightcm);
          setMin(data.data.data.heightcm[0].name);
          setMax(data.data.data.heightcm[data.data.data.heightcm.length - 1].name);
          setValue(0);
          setInterval(10);
          await sessionStorage.setItem("heightype", "cm");
          setCm(true)
          setFt(false)
          console.log(cm,'cm');
        }

        setLoading(false);
        break;
      case "ft":
        {
          let data = await getInputApi();
          setUserData(data.data.data.heightft);
          const [minspiltvalue, minremain] = data.data.data.heightft[0].name.split("'");
          setMin(minspiltvalue);
          const [maxspiltvalue, maxremain] = data.data.data.heightft[data.data.data.heightft.length - 1].name.split("'");
          setMax(maxspiltvalue);
          setInterval(2);
          setValue(0);
          await sessionStorage.setItem("heightype", "ft");
          setFt(true)
          setCm(false)
          console.log(ft,'ft');
        }

        setLoading(false);
        break;
      case "inch":
        {
          let data = await getInputApi();
          setUserData(data.data.data.heightinch);
          setMin(data.data.data.heightinch[0].name);
          setMax(data.data.data.heightinch[data.data.data.heightinch.length - 1].name);
          await sessionStorage.setItem("heightype", "inch");
          setInch(true)
          setValue(0);
          console.log(inch,'inch');
        }

        setLoading(false);
        break;

      default:
        break;
    }
  };

  const getImage = () => {
    if (gen == "male") {
      setImage(false);
    }else if(gen=="female"){
      setImage(true)
    }
  };


  
  const getDataH = async () => {
    console.log(type,'typeCheck');
    switch (type) {
      case "cm":
        {
          let data = await getInputApi();
          setUserData(data.data.data.heightcm);
          setMin(data.data.data.heightcm[0].name);
          setMax(data.data.data.heightcm[data.data.data.heightcm.length - 1].name);
          setInterval(10);
          await sessionStorage.setItem("heightype", "cm");
          setCm(true)
          setFt(false)
          console.log(cm,'cm');
        }

        setLoading(false);
        break;
      case "ft":
        {
          let data = await getInputApi();
          setUserData(data.data.data.heightft);
          const [minspiltvalue, minremain] = data.data.data.heightft[0].name.split("'");
          setMin(minspiltvalue);
          const [maxspiltvalue, maxremain] = data.data.data.heightft[data.data.data.heightft.length - 1].name.split("'");
          setMax(maxspiltvalue);
          setInterval(2);
          await sessionStorage.setItem("heightype", "ft");
          setFt(true)
          setCm(false)
          console.log(ft,'ft');
          
    console.log(min1,'min1');
    console.log(max1,'max1');
    console.log(value,'value');
        }

        setLoading(false);
        break;
      case "inch":
        {
          let data = await getInputApi();
          setUserData(data.data.data.heightinch);
          setMin(data.data.data.heightinch[0].name);
          setMax(data.data.data.heightinch[data.data.data.heightinch.length - 1].name);
          await sessionStorage.setItem("heightype", "inch");
          setInch(true)
          console.log(inch,'inch');
        }

        setLoading(false);
        break;

      default:
        break;
    }
  };
  const getStat = async () => {
    await getOneUserInputApi()
      .then((res) => {
        
        if(res.data.data.list.height_id != null){
          setUserValue(res.data.data.list.height.name);

            if(res.data.data.list.height.heighttype.name == "ft"){
              const [minspiltvalue, minremain] = res.data.data.list.height.name.split("'");
              sessionStorage.setItem("height", res.data.data.list.height.name);
              setValue(minspiltvalue+"."+minremain);
            }else{
              setValue(res.data.data.list.height.name);
              sessionStorage.setItem("height", res.data.data.list.height.name);
            }
          

          setType(res.data.data.list.height.heighttype.name);
          sessionStorage.setItem("heightype", res.data.data.list.height.heighttype.name);
          console.log(res.data.data.list,'userData');
        }else{
          
          sessionStorage.setItem("height", "0");
        }
        // setPlan(res.data.data.package);
       
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const onChangedata = (e) => {
    if(ft == true){
      const [minspiltvalue, minremain] = userData[0].name.split("'");
    setMin(minspiltvalue);
    const [maxspiltvalue, maxremain] = userData[userData.length - 1].name.split("'");
    setMax(maxspiltvalue);
    
      setValue(e.target.value);
    }if(cm == true){
      setMin(userData[0].name);
      setMax(userData[userData.length - 1].name);
      setValue(e.target.value);
    }
    
    sessionStorage.setItem("height", e.target.value);

  };

  var genderImage = '';
  const renderImage = () => {
    const getImagePath = () =>
      process.env.PUBLIC_URL + `/assets/images/step-form/`+genderImage+`.png`;
    
   
      
  
    const getHeightStyle = () => {
    
      if(cm == true){
        return { height: `${(value - 97) * 2.41}px`, width: '' };
      }
      if(ft == true){
        return { height: `${(value / 1.8) * 5}rem`, width: '' };
      }
    };

    console.log(sessionStorage.getItem("gender"));
    if (sessionStorage.getItem("gender") === 'Male') {
      console.log('ggggggggg');
       genderImage = 'male';
    }else if(sessionStorage.getItem("gender")==='Female'){
      console.log('hhhhhhhhhhhh');
       genderImage = 'Path 22';
    }
    return (
      <img
        src={getImagePath()}
        className="female-img"
        style={getHeightStyle()}
      />
    );
  };


  useEffect(() => {
    getStat();
    getDataH();
    getImage();
  }, [type]);
  return (
    <>
      <section className="main">
        <div className="container p-0">
          <form>
            <div className="row workout-top_height">
              <div className="col-md-7 my-auto">
                <div className="sec-1">
                  <h1 className="field-title">
                    Let's get to know <br></br> you better
                  </h1>

                  <div className="title-bd"></div>
                  <div className="d-flex">
                    <div>
                      <p className="substitle-p">Choose Height</p>
                    </div>
                    <div className="tabbtn-grp">
                      <ul
                        class="nav nav-pills mb-3 justify-content-center"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item" role="presentation">
                          <button
                            class={type == 'cm'?"btn nav-link active":"btn nav-link"}
                            id="pills-week1-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-week1"
                            type="button"
                            role="tab"
                            aria-controls="pills-week1"
                            aria-selected="false"
                            value="cm"
                            onClick={onChangeType}
                          >
                            Cm
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            class={type == 'ft'?"btn nav-link active":"btn nav-link"}
                            id="pills-week2-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-week2"
                            type="button"
                            role="tab"
                            aria-controls="pills-week2"
                            aria-selected="false"
                            value="ft"
                            onClick={onChangeType}
                          >
                            FT
                          </button>
                        </li>

                        {/* <li class="nav-item" role="presentation">
                          <button
                            class="btn nav-link"
                            id="pills-week3-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-week2"
                            type="button"
                            role="tab"
                            aria-controls="pills-week2"
                            aria-selected="false"
                            value="inch"
                            onClick={onChangeType}
                          >
                            Inch
                          </button>
                          
                        </li> */}
                      </ul>
                      {/* <Link to="/form"><button className="btn active-btn">Cm</button></Link>
                      <Link to="/form"><button className="btn nonactive">FT</button></Link>
                      <Link to="/form"><button className="btn nonactive">Inch</button></Link> */}
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                          <Rings color="#00BFFF" height={80} width={80} />
                          ) : (
              <div className="col-md-5">
                <div className="sec-2">
                  <div class="d-flex slider">
                    <div className="text-center">
                    {ft?renderImage():renderImage()}
                    </div>

                                    
                    <div className="scale">
                      <CentimeterGuage
                        min={min1}
                        max={max1}
                        value={value}
                        interval={interval}
                        htmlFor="height-range"
                      />
                    </div>
                            
                    <div class="slider__input text-center">

                              {ft?(
                              <input
                              id="height-range"
                              class="slider__input_slider"
                              type="range"
                              min={min1}
                              // min={50}
                              max={max1}
                              step="0.1"
                              value={value}
                              data-range="circular"
                              onChange={(e) => onChangedata(e)}
                              />
                              ):(
                                <input
                                id="height-range"
                                class="slider__input_slider"
                                type="range"
                                min={min1}
                                // min={50}
                                max={max1}
                                value={value}
                                data-range="circular"
                                onChange={(e) => onChangedata(e)}
                              />
                              )}
                     

                      <div
                        class="slider__input_tracker"
                        style={{
                          width: `${value < 50 ? value - 0.5 : value - 4}%`,
                        }}
                      ></div>
                    </div>
                      
                  </div>
                </div>
                {/* {value} */}
              </div>
)}
            </div>
            {/* <button onClick={handleSubmit}>get</button> */}
          </form>
        </div>
      </section>
    </>
  );
};
export default Height;

