import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./TopNav.css";
import { useState } from "react";
import { getInfoApi, logout } from "../api/Apis";
import { useEffect } from "react";

const TopNav = () => {
  let navigate = useNavigate();
  const [data, setData] = useState();
  const [infoData, setInfoData] = useState(false);
  const getData = async () => {
    await getInfoApi()
      .then((res) => {
        console.log(res.data.data,'ggfhjasfaf');
        if(res.data.data.user.id && sessionStorage.getItem('userid') != ""){
          setInfoData(true);
        }
        setData(res.data.data.user);
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userLogout = async () =>{
    await logout()
    .then((res) => {
      
      
      if(res.data.success == true){
        sessionStorage.clear();
        navigate(`/`);
      }
     
    })
    .catch((error) => {
      console.log(error);
    });

  };

  useEffect(() => {
    getData();
  }, [infoData]);

  const imageUrl = "https://api.fizo.in/";

  return (
    <>
      <section className="main">
        <div className="top-nav">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-6">
                                    {infoData ? (
                <div className="d-flex client-info">
                  <div className="img-div">
                    <img
                      className="client"
                      src={
                        data?.profile_pic == null?process.env.PUBLIC_URL + "/assets/images/icons/dummy-user.png"
                        : imageUrl + data?.profile_pic}
                      alt="client"
                    />
                  </div>

                  <div className="bar-div dropdown ">
                    <img
                      className="bar dropdown-toggle"
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icons/bar.png"
                      }
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      alt="client"
                    />

                    <ul class="dropdown-menu">
                      <Link className="dropdown-item" to="/my-info">
                        <div className="d-flex ">
                          <div className="drop-img-div">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/user.png"
                              }
                            />
                          </div>
                          <div>
                            <li className="list-name"> My Info </li>
                          </div>
                        </div>
                      </Link>
                      <Link className="dropdown-item" to="/manage-subscription">
                        <div className="d-flex ">
                          <div className="drop-img-div">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/subscription.png"
                              }
                            />
                          </div>
                          <div>
                            <li className="list-name">Manage Subscription</li>
                          </div>
                        </div>
                      </Link>
                      <Link className="dropdown-item" to="/progress">
                        <div className="d-flex ">
                          <div className="drop-img-div">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/progress.png"
                              }
                            />
                          </div>
                          <div>
                            <li className="list-name">Progress</li>
                          </div>
                        </div>
                      </Link>
                      <Link className="dropdown-item" to="/my-reports">
                        <div className="d-flex ">
                          <div className="drop-img-div">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/profit-report.png"
                              }
                            />
                          </div>
                          <div>
                            <li className="list-name">My reports</li>
                          </div>
                        </div>
                      </Link>
                      <Link className="dropdown-item" to="#">
                        <div className="d-flex ">
                          <div className="drop-img-div">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/help.png"
                              }
                            />
                          </div>
                          <div>
                            <li className="list-name">Help</li>
                          </div>
                        </div>
                      </Link>
                      <Link className="dropdown-item" to="/invite-friend">
                        <div className="d-flex ">
                          <div className="drop-img-div">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/invite.png"
                              }
                            />
                          </div>
                          <div>
                            <li className="list-name">Invite friend</li>
                          </div>
                        </div>
                      </Link>
                      <Link className="dropdown-item"
                       onClick={userLogout}
                      >
                        <div className="d-flex ">
                          <div className="drop-img-div">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/user.png"
                              }
                            />
                          </div>
                          <div>
                            <li className="list-name"> Logout</li>
                          </div>
                        </div>
                      </Link>
                    </ul>
                  </div>
                  {/* <Link to="/">
                    <div className="home-back-div">
                      <img
                        className="icon"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/home.png"
                        }
                      />
                    </div>
                  </Link> */}
                </div>
                ):(
                  <div  className="d-flex client-info">
                     <Link to="/">
                    <div className="home-back-div">
                      <img
                        className="icon"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/home.png"
                        }
                      />
                    </div>
                  </Link>
                  </div>
                     )}
              </div>
              {/* <div className="col-md-6 col-6">
                <Link to="/chat">
                  <div className="meassage-box">
                    <img
                      className="msg"
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icons/msg.png"
                      }
                      alt="clienMESSAGEt"
                    />
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopNav;
