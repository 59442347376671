import "./PlanBanner.css";
import React, { useState } from "react";
import { useEffect } from "react";
import { getPlanBanners } from "../../api/Apis";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import 'swiper/css';
import { Autoplay, Pagination } from "swiper";
function PlanBanner() {


    const [planBanner, setPlanBanner] = useState();
    const [planIcon, setPlanIcon] = useState();
    var HtmlToReactParser = require('html-to-react').Parser;


    const [imageBack, setImgBack] = useState()
    var htmlToReactParser = new HtmlToReactParser();
    const imageUrl = "https://api.fizo.in/";

    const getStat = async () => {
        await getPlanBanners()
            .then((res) => {
                console.log(res.data.data, 'kkkkkkkkkkkkkk');
                setPlanBanner(res.data.data.banner);
                setPlanIcon(res.data.data.planIcon);
                setImgBack(imageUrl + res.data.data.banner?.image);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    useEffect(() => {
        getStat();
    }, []);
    return (
        <section className='main'>
            <div className="container-fluid p-0">
                <section
                    style={{
                        backgroundImage: `url(${imageBack})`
                    }}
                    className="plan-banner"

                >
                    <div className="overlay">
                        <div className="row">
                            <div className="col-xxl-7 col-xl-7 col-lg-9 col-md-10 ">
                                <div className="sec-1">
                                    <h1 className="title">{planBanner?.heading}</h1>
                                    <p className="subtitle">{htmlToReactParser.parse(planBanner?.description)}</p>
                                    <div className="row plan">

                                        <Swiper
                                            spaceBetween={30}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 1,
                                                },
                                                640: {
                                                    slidesPerView: 1,
                                                },
                                                768: {
                                                    slidesPerView: 2,
                                                },
                                                1024: {
                                                    slidesPerView: 2,
                                                },
                                                1200: {
                                                    slidesPerView: 2,
                                                },
                                                1400: {
                                                    slidesPerView: 2,
                                                },
                                            }}
                                            centeredSlides={false}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: false,
                                            }}
                                            loop={true}
                                            // navigation={true}
                                            modules={[Autoplay]}
                                            className="mySwiper"
                                        >
                                            {planIcon?.map((val) => (
                                                <SwiperSlide>
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="d-flex plan-div">
                                                            <div className="img-div1">
                                                                <img className="plan-icon" src={imageUrl + val?.image} alt="wellness" />
                                                            </div>
                                                            <div className="content-div">
                                                                <h6 className="cont-title">{val?.heading}</h6>
                                                                <p className="subtext">{val?.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                            {/* <SwiperSlide><span className='anim-span'>Health</span></SwiperSlide>
                                                <SwiperSlide><span className='anim-span'>Nutrition</span></SwiperSlide>
                                                <SwiperSlide><span className='anim-span'>Fitness</span></SwiperSlide>
                                                <SwiperSlide><span className='anim-span'>Wellness</span></SwiperSlide>     */}

                                        </Swiper>
                                        {/* <div className="col-md-6 col-sm-6">
                                            <div className="d-flex plan-div">
                                                <div className="img-div">
                                                    <img className="plan-icon" src={process.env.PUBLIC_URL + 'assets/images/icons/fitness.svg'} alt="fitness" />
                                                </div>
                                                <div className="content-div">
                                                    <h6 className="cont-title">Personalized Workout Plan</h6>
                                                    <p className="subtext">Personalized Workout as per your reports.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
}

export default PlanBanner;