import axios from "axios";

// var fizoUrl = `https://fizoadmin.profcymadev.in/public/api/`;
var fizoUrl = `https://api.fizo.in/api`;
// var fizoUrl=`http://192.168.12.5/nutrihealth/public/api/`;

const axiosClient = axios.create({
  baseURL: fizoUrl,
  mode: "no-cors",
  headers: {
    Authorization: ``,
    Accept: "application/json",
    "Content-Type": "application/json multipart/form-data",
  },
});
axiosClient.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    // const id = sessionStorage.getItem("userid");
    // console.log(id,'i');
    if (token) {
      config.headers["Authorization"] = "Bearer "+ token;
    }
    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);

export default axiosClient;
