import React, { useEffect, useState } from "react";
import "./Reminder.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link } from "react-router-dom";
import TimePicker from "react-time-picker";
import {
  getReminders,
  setReminder,
  postStatusMeals,
  postPostTime,
  postWater,
  postWeight,
} from "../api/Apis";
import { faL } from "@fortawesome/free-solid-svg-icons";
import sound from './fizoalarm.mp3'
export const Reminder = () => {
  var song = new Audio('public/assets/alarm/fizoalarm.mp3');
  const [alarmExecute, setAlarmExecute] = useState(false);
  const [alarmText, setAlarmText] = useState("");
  const [date, setDate] = useState(new Date());
  const [showSection1, setShowSection1] = useState();
  const [data, setData] = useState({});
  const [waterHrsId, setWaterHrsId] = useState();
  const [startFrom, setStartFrom] = useState();
  const [until, setUntil] = useState();
  const [waterStatus, setWaterStatus] = useState();

  const [type, setType] = useState();
  const [remindingTime, setRemindingTime] = useState();
  const [weightStatus, setWeightStatus] = useState();

  const toggleSection1 = async () => {
    // setShowSection1(!showSection1);
    const res = await setReminder();
    setShowSection1(res?.data?.data?.main_status);
    console.log(res?.data?.data?.main_status);
  };

  const getData = async () => {
    const response = await getReminders();
    console.log(response?.data,'dfgsdgsgsgd')
    setData(response?.data?.data);
    setShowSection1(response?.data?.data?.set_reminder);
    setWaterHrsId(response?.data?.data?.water[0]?.water_hrs_id);
    setStartFrom(response?.data?.data?.water[0]?.starting_from);
    setUntil(response?.data?.data?.water[0]?.until);
    setWaterStatus(response?.data?.data?.water[0]?.water_status);
    setType(response?.data?.data?.weight[0]?.type);
    setRemindingTime(response?.data?.data?.weight[0]?.reminding_time);
    setWeightStatus(response?.data?.data?.weight[0]?.weight_status);
  };

  //   console.log(data);

  

  const SwicthButton = async (e) => {
    const res = await postStatusMeals(e.target.value);
    // console.log(res?.data);
    if (res?.data?.success) {
      getData();
    }
  };

  const handlePostTime = async (id, e) => {
    // console.log(e.target.value);
    const res = await postPostTime(id, e.target.value);
    // console.log(res);
    if (res?.data?.success) {
      getData();
    }
    // setPosttime(e.target.value);
  };

  const handleStartTimeandUtil = async (e) => {
    if (e.target.name === "water_hrs_id") {
      setWaterHrsId(e.target.value);
    } else if (e.target.name === "startfrom") {
      setStartFrom(e.target.value);
    } else if (e.target.name === "until") {
      setUntil(e.target.value);
    }
  };

  const handleRemindingTime = async (e) => {
    if (e.target.name === "type") {
      setType(e.target.value);
    } else if (e.target.name === "reminding_time") {
      setRemindingTime(e.target.value);
    }
  };

  const WeightSwicthButton = async (e) => {
    const res = await postWeight(type, remindingTime, weightStatus);
    // console.log(res?.data);
    if (res?.data?.success) {
      getData();
    }
  };

  const WaterSwicthButton = async (e) => {
    const res = await postWater(waterHrsId, startFrom, until, waterStatus);
    // console.log(res?.data);
    if (res?.data?.success) {
      getData();
    }
  };
  const showReminder = async (date) => {
    const response = await getReminders();
    // console.log(response?.data?.data?.active_post_time.includes(date+":00"));
      if(response?.data?.data?.active_post_time.includes(date+":00") && showSection1 == 1){
        if(alarmExecute == false){
          setAlarmText("Time to take a meal...!");
          new Audio(sound).play();
         await setAlarmExecute(true);
        }
      }else if(response?.data?.data?.water_time.includes(date+":00") && response?.data?.data?.water_remind?.water_status == 1 && showSection1 == 1){
        if(alarmExecute == false){
          setAlarmText("Time to drink water...!");
          new Audio(sound).play();
          setAlarmExecute(true);
        }
      }else{
        setAlarmText("");
        new Audio(sound).pause();
         setAlarmExecute(false);
      }
  };

  const stopAlarm = async () => {
    
        setAlarmText("");
        new Audio(sound).pause();
         setAlarmExecute(false);
      
  };

  

  useEffect(() => {
    getData();
    const intervalId = setInterval(() => {
      
      setDate(new Date());
      
      showReminder(new Date().toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }));

    }, 26000)

    return () => clearInterval(intervalId);
  }, [showSection1]);
  return (
    <>
      <section className="main">
        <TopNav />
        <div className="container-fluid p-0">
          <section className="reminder-section">
            <div className="container">
              <div>
             
              </div>
              <div className="title-div text-center"> 
                 <div className="row clockrow">
                        <div className="col-md-5 col-sm-4">
                        <Link to="/progress">
                            <div className='back-btn-div'>
                                <img className='back-btn' src={process.env.PUBLIC_URL + '../assets/images/icons/back.png'} alt="" />
                            </div> 
                            </Link>
                        </div>
                        
                        <div className="mx-auto text-center clockbox">
                          Time
                          <p className='clock'>
                                          {date.toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: false,
                                        })}
                                        </p>
                        </div>

                        <div className="col-md-5 col-sm-4"></div>


                        <div className="col-md-5 col-sm-4">
                                                    <img className='back-btn'
                                                  style={{
                                                    display:alarmExecute?"block":"none"
                                                  }}
                                                  src={process.env.PUBLIC_URL + '../assets/images/icons/alarmclock.gif'} alt=""
                                                  onClick={stopAlarm}
                                                   />
                                                  <span>{alarmText}</span>
                        </div>

                        {/* <div className="col-md-3 col-sm-4"> */}
                          <div className="justify-content-center">
                        <audio id="notification" 
                        src={process.env.PUBLIC_URL + 'public/assets/alarm/fizoalarm.mp3'}
                        ></audio>
                        <h6 className="title">Reminder</h6>
                        <h4 className="subtitle">Set Reminder</h4>

                          </div>
                        {/* </div> */}

                        {/* <div className="col-md-2 col-sm-4">
                        </div> */}
                 </div>
                 
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    checked={showSection1}
                    onChange={toggleSection1}
                  />
                </div>
              </div>

              <div>
                {showSection1 === 1 && (
                  <div>
                    <div className="meals-div">
                      <h5 className="title">Meals</h5>

                      {data?.post_time?.map((d, index) => {
                        return (
                          <div className="meal-card" key={index}>
                            <form>
                              <div className="row">
                                <div className="col-10">
                                  <div className="d-flex">
                                    <div>
                                      <p className="meal-name">
                                        {d?.meal[0]?.name}
                                      </p>
                                    </div>
                                    <div>
                                      <input
                                        className="form-input time-class"
                                        type="time"
                                        name="post_time"
                                        onChange={(event) =>
                                          handlePostTime(d?.meal_id, event)
                                        }
                                        value={d?.post_time}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-2">
                                  <div class="form-check form-switch">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      onChange={SwicthButton}
                                      role="switch"
                                      value={d?.meal_id}
                                      checked={d?.meal_status}
                                      name={`${d?.id}`}
                                      id={`${d?.id}`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        );
                      })}
                    </div>

                    <div className="water-div">
                      {data?.water.map((d) => {
                        return (
                          <>
                            <div className="row">
                              <div className="col-6">
                                <h5 className="water-title">Water</h5>
                                <p className="remind-p">Remind me to drink</p>
                                <p className="remind-p mt-3">Every</p>
                              </div>
                              <div className="col-6 my-auto">
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    onChange={WaterSwicthButton}
                                    checked={waterStatus}
                                    id="flexSwitchCheckChecked"
                                  />
                                </div>

                                <div className="d-flex justify-content-end">
                                  <ul
                                    class="nav nav-pills mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li class="nav-item" role="presentation">
                                      <button
                                        class={
                                          waterHrsId === 1
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        value={1}
                                        name="water_hrs_id"
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        onClick={handleStartTimeandUtil}
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                      >
                                        1h
                                      </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                      <button
                                        class={
                                          waterHrsId === 2
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        value={2}
                                        id="pills-profile-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-profile"
                                        type="button"
                                        name="water_hrs_id"
                                        onClick={handleStartTimeandUtil}
                                        role="tab"
                                        aria-controls="pills-profile"
                                        aria-selected="false"
                                      >
                                        2h
                                      </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                      <button
                                        id="pills-contact-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-contact"
                                        type="button"
                                        name="water_hrs_id"
                                        class={
                                          waterHrsId === 3
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        value={3}
                                        onClick={handleStartTimeandUtil}
                                        role="tab"
                                        aria-controls="pills-contact"
                                        aria-selected="false"
                                      >
                                        3h
                                      </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                      <button
                                        class={
                                          waterHrsId === 4
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        value={4}
                                        id="pills-disabled-tab"
                                        data-bs-toggle="pill"
                                        name="water_hrs_id"
                                        onClick={handleStartTimeandUtil}
                                        data-bs-target="#pills-disabled"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-disabled"
                                        aria-selected="false"
                                      >
                                        4h
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <hr></hr>

                            <div className="row">
                              <div className="col-6">
                                <p className="remind-p">Starting from</p>
                              </div>
                              <div className="col-6 my-auto">
                                <div className="d-flex justify-content-end">
                                  <div>
                                    <input
                                      className="form-input time-class"
                                      name="startfrom"
                                      value={startFrom}
                                      onChange={handleStartTimeandUtil}
                                      type="time"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr></hr>

                            <div className="row">
                              <div className="col-6">
                                <p className="remind-p">Until</p>
                              </div>
                              <div className="col-6 my-auto">
                                <div className="d-flex justify-content-end">
                                  <div>
                                    <input
                                      className="form-input time-class"
                                      name="until"
                                      value={until}
                                      onChange={handleStartTimeandUtil}
                                      type="time"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="water-div">
                      {data?.weight.map((d) => {
                        return (
                          <div className="row">
                            <div className="col-6">
                              <h5 className="water-title">Weight</h5>
                              <p className="remind-p">
                                Remind me to weigh myself
                              </p>
                              <p className="remind-p mt-3">Select Reminder</p>
                            </div>
                            <div className="col-6 my-auto">
                              <div class="form-check form-switch">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  onChange={WeightSwicthButton}
                                  checked={weightStatus}
                                  id="flexSwitchCheckChecked"
                                />
                              </div>

                              <div className="d-flex justify-content-end">
                                <div className="col-xxl-2 col-xl-2 col-md-3 col-sm-4 mt-3">
                                  <select
                                    class="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    name="type"
                                    onChange={handleRemindingTime}
                                  >
                                    <option
                                      value="Daily"
                                      selected={d?.type === "Daily"}
                                    >
                                      Daily
                                    </option>
                                    <option
                                      value="Weekly"
                                      selected={d?.type === "Weekly"}
                                    >
                                      Weekly
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <hr></hr>
                      <div className="row">
                        <div className="col-6">
                          <p className="remind-p">Reminding time</p>
                        </div>
                        <div className="col-6 my-auto">
                          <div className="d-flex justify-content-end">
                            <div>
                              <input
                                className="form-input time-class"
                                type="time"
                                value={remindingTime}
                                name="reminding_time"
                                onChange={handleRemindingTime}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Add />
          </section>
        </div>
      </section>
    </>
  );
};

export default Reminder;
