import React from "react";
import "./Privacy.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import Privacy_img from "./Images/Privacy_img.jpg"
const Privacy = () => {
  return (
    <>
      <Header />
      <section className="Privacy">
        <div className="container-fluid">
          <div className="policy container">
            <div className="row clearfix">
              <div className="col-lg-12 col-xs-12">
                <h3 className="section-title">Privacy Policy
                <div className="horizantal_privacy"></div>
                
                </h3>

                <h5>Email & Newsletter Policy</h5>

                <p class="text">
                  A1STOPlighting’s and its network of stores value your right to
                  privacy and it’s very important to us to keep it safe. Be
                  assured that we will not in any way sell or share any of your
                  contact information to unknown or unaffiliated third parties.
                  1STOPlighting and its affiliate sites use email as one of our
                  primary modes of outside communication with our customers.
                  Emails that we send can address a variety of topics, including
                  but not limited to:
                </p>

                <ul class="text">
                  <li>
                    <em>Promotional emails you have opted-in to receive</em>
                  </li>
                  <li>
                    <em>
                      Email communication regarding but not limited to: order
                      and shipping confirmations, status updates or Customer
                      service’s direct response to an inquiry
                    </em>
                  </li>
                </ul>

                <br />

                <p class="text">
                  On occasion, 1STOPlighting and its network of stores will
                  notify you (the customer) of opt-in special promotions or
                  offers. This communication is based on a number of factors,
                  which include but are not limited to:
                </p>

                <ul class="text">
                  <li>
                    <em>Your purchase history</em>
                  </li>
                  <li>
                    <em>Saved carts</em>
                  </li>
                  <li>
                    <em>Price or promotion alerts</em>
                  </li>
                  <li>
                    <em>
                      Quotes created either by a member of our team or one that
                      is created and saved within your personal 1STOPlighting
                      preferred/trade account.
                    </em>
                  </li>
                </ul>

                <br />

                <p class="text">
                  When you unsubscribe from our network, your un-subscription
                  will not apply to any carts you may save in the future.If you
                  have opted-in to receive our newsletter or special promotional
                  emails and you wish to stop your subscriptions, you can find
                  1STOPlighting and its network of stores unsubscribe
                  information at the bottom of each email promotion or
                  newsletter we send you or contact us directly. If you have any
                  questions or concerns regarding our emails and newsletter
                  privacy policy, send us an email at
                  <a href="customerservice@1stoplighting.com?Subject=Privacy%20Issue">
                    customerservice@1stoplighting.com.
                  </a>
                  or contact customer service at 1-866-203-5392 M-F 5am-6pm PST
                </p>

                <br />

                <h5>Information Collection, Use, and Sharing</h5>

                <p class="text">
                  We (Belami Inc dba 1STOPLighting and its affiliate stores) are
                  the sole owners of the information collected on this website.
                  Our business and marketing team utilize platforms such as
                  Google Analytics and MyBuys to collect data about consumer
                  traffic trends. The information that we obtain will not be
                  sold or rented to any party outside of our company, we do NOT
                  collect sensitive information such as credit card numbers, nor
                  do we install software on users' computers or track keystrokes
                  or share your information with any third party outside of our
                  organization, other than as necessary to fulfill your order
                  requests.
                </p>

                <br />

                <h5>Privacy Policy Change Notification.</h5>

                <p class="text">
                  Please be aware this privacy policy may change from time to
                  time. Please check this page occasionally for changes.
                </p>
              </div>
            </div>
          </div>

          <nav id="social" className="text-center mt-3">
            <span className="d-block mb-3">Add me on Social Media:</span>
            <div className="d-flex justify-content-center">
              <Link
                to="https://www.linkedin.com/in/diegoarbito"
                title="LinkedIn"
                className="social-link me-3 mb-3"
              >
                <img
                  src="https://res.cloudinary.com/diegoarbito/image/upload/v1469744659/Linkedin_Icon_jfuwkc.png"
                  alt="LinkedIn"
                  className="social-icon"
                />
              </Link>
              <Link
                to="https://twitter.com/diegoarbito"
                title="Twitter"
                className="social-link me-3 mb-3"
              >
                <img
                  src="https://res.cloudinary.com/diegoarbito/image/upload/v1469744663/Twitter_enkrbf.png"
                  alt="Twitter"
                  className="social-icon"
                />
              </Link>
              <Link
                to="https://facebook.com"
                title="Facebook"
                className="social-link me-3 mb-3"
              >
                <img
                  src="https://res.cloudinary.com/diegoarbito/image/upload/v1469744665/facebook_bqjnna.png"
                  alt="Facebook"
                  className="social-icon"
                />
              </Link>
              <Link
                to="https://youtube.com"
                title="YouTube"
                className="social-link mb-3"
              >
                <img
                  src="https://res.cloudinary.com/diegoarbito/image/upload/v1469744667/Youtube_jdupgi.png"
                  alt="YouTube"
                  className="social-icon"
                />
              </Link>
            </div>
          </nav>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Privacy;
