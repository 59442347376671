import React from 'react'
import { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./circular.css"


const Circular = () => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
      }
    }, 50);
  }, [percentage]);

  let tID = setTimeout(function () {
    window.location.href = 
    "/bodyanalysiscircular"; 
    window.clearTimeout(tID);
}, 4000);
  return (
    <>
    <div className="centered-container">
         <div className='fizo-circular mx-auto'>
      
            <div className='progress-div mx-auto'>
              <CircularProgressbar value={percentage} text="Analyzing Body Statistics" strokeWidth={5}/>
            </div>
        </div>
   </div>
    </>
  )
}

export default Circular