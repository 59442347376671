import React from "react";
import axiosClient from "../api/ApiInstance";
import "./signup.css";

import callingicon from "./sign-up-images/calling-icon.png";
import lockicon from "./sign-up-images/lock-icon.png";
import eyesicon from "./sign-up-images/eyes-icon.png";
import usericon from "./sign-up-images/user-icon.png";
import emailicon from "./sign-up-images/email-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { Register } from "../api/Apis";
import { useState } from "react";
const Signup = () => {
  let navigate = useNavigate();
  // const [nameErr, setNameErr] = useState(false);
  // const [emailErr, setEmailErr] = useState(false);
  // const [mobErr, setMobErr] = useState(false);
  // const [pswrdErr, setPswrdErr] = useState(false);
  // const [confirmPswrd, setConfirmPswrd] = useState(false);
  const [agree, setAgree] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  //login api start

  const RegisterApi = {
    name: "",
    email: "",
    mobile_number: "",
    password: "",
    c_password: "",
  };

  const [fizoUsers, setFizoUsers] = useState(RegisterApi);
  const [errors, setErrors] = useState(false);
  const [errMsg, setErrMsg] = useState({
    errors: [],
    isError: false
  });
  const [mobileError, setMobileError] = useState()
  const [show, setShow] = useState(false);
  const [showM, setShowM] = useState(false);
  const [showE, setShowE] = useState(false);
  const handleChange = (e) => {
    setShow(false);
    const { name, value } = e.target;
     setFizoUsers({ ...fizoUsers, [name]: value });
if(e.target.name == "mobile_number"){
  if (e.target.value.length == 10) {
    setShowM(false);
  } else {
    setShowM(true);
  }
}
    
    let err = {}
    if (fizoUsers.email === '') {
      err.email = 'Email required!'
    } else {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (!regex.test(fizoUsers.email)) {
        setShowE(true);
      } else {
        setShowE(false);
      }
    }
    return Object.keys(err).length < 1;
  };

  const validateForm = () => {

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (fizoUsers.name.length == 0 || fizoUsers.email.length == 0 || fizoUsers.mobile_number.length == 0 || fizoUsers.password == 0 || fizoUsers.c_password == 0) {
      setErrors(true);
      console.log(errors);
    }
    axiosClient
      .post("/register", {
        name: fizoUsers.name,
        email: fizoUsers.email,
        mobile_number: fizoUsers.mobile_number,
        password: fizoUsers.password,
        c_password: fizoUsers.c_password
      })
      .then(res => {
        console.log(res.data.data);
        if (res.status === 200) {
          let success=res.data.success;
          console.log(success,'success');
          sessionStorage.setItem("success",success);
          console.log("data",res.data.data.user_input.filled);
          let filled=res.data.data.user_input.filled;
          if(res.data.data.unfilled_fields){
            let unfilled=res.data.data.unfilled_fields[0];
            sessionStorage.setItem('unfilled',unfilled);
            console.log(unfilled,'un');
          }
          console.log(filled,'fill');
          sessionStorage.setItem('filled',filled);
          let medField=res.data.data.medcon;
          sessionStorage.setItem('medField',medField);
          console.log(medField,'medfield');
          let userid = res.data.data.user_id;
          sessionStorage.setItem('userid',userid);
          console.log(userid,'userid');
         
          let token=res.data.data.token;
          sessionStorage.setItem('token',token);
          console.log(token,'token');
          let gender = res.data.data.gender;
          sessionStorage.setItem('gender',gender);
          console.log(gender,'eeeeeeeeeeeeeeeeeeeeeeeeeeee');
          navigate("/form");
        }
      })
      .catch(error => {
        console.log(error.response);
        setErrMsg({
          errors: error.response.data,
          isError: true,
        });
        if (error) {
          setShow(true);
        }
      })
   
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    console.log("clicked");
  };
  const togglePassword2=()=>{
    setPasswordShown2(!passwordShown2);
    console.log("clicked");
  }
  // sessionStorage.setItem()
  return (
    <>
      <section className="sign-up">
        <div className="container">
          <div className="row background-color">
            <div className="col-md-12 mt-4 mb-5">
            <Link to="/">
              <div className="heading-holder text-center mt-4">
                <h2>fizo</h2>
              </div>
              </Link>
            </div>
            <div className="col-lg-5 col-md-7 col-12 mx-auto">
              <div className="text-holder text-center mb-3">
                <h3>Create Account</h3>
                <p className="">
                  Already have an account?
                  <Link to="/login">
                    <span className="ms-2">Login</span>
                  </Link>
                </p>
              </div>
              <form class="row" onSubmit={handleSubmit} autoComplete="off">
                <div className="col-sm-12 mb-3">
                  <div className="input-group mb-2">
                    <span className="input-group-text" id="basic-addon1">
                      <img src={usericon} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      aria-label="Username"
                      maxlength="30"
                      aria-describedby="basic-addon1"
                      name="name"
                      onKeyDown={(event) => {
                        if (!/[A-Z-a-z]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={fizoUsers.name}
                      onChange={handleChange}
                    />
                  </div>
                  {/* {errors && fizoUsers.name <=0 && show ? <p className="errMsg">Please Enter User Name</p>: <p className="errMsg">{errMsg.errors}</p>} */}
                  {errors && fizoUsers.name <= 0 && show ? <p className="errMsg">{errMsg.errors.data.name}</p> : ''}
                </div>
                <div className="col-sm-12 mb-3">
                  <div className="input-group mb-2">
                    <span className="input-group-text" id="basic-addon1">
                      <img src={emailicon} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="email" 
                      value={fizoUsers.email}
                      onChange={handleChange}
                    />
                  </div>
                  {/* {errors && fizoUsers.email <=0 ? <p className="errMsg">Please Enter your Email Id</p> : ""} */}
                  {show ? <p className="errMsg">{errMsg.errors.data.email}</p> : ''}
                  {showE ? <p className="errMsg">Enter Valid Email Address</p> : ''}
                </div>
                <div className="col-sm-12 mb-3">
                  <div className="input-group mb-2">
                    <span className="input-group-text" id="basic-addon1">
                      <img src={callingicon} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile"
                      aria-label="Username"
                      minlength="10"
                      maxlength="10"
                      aria-describedby="basic-addon1"
                      name="mobile_number"
                      value={fizoUsers.mobile_number}
                      onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          if(event.key != 'Backspace')
                          event.preventDefault();
                        }
                      }}
                      onChange={handleChange}
                    />
                  </div>
                  {/* {errors && fizoUsers.mobile_number <=0 ? <p className="errMsg"> Please Enter your Phone Number  </p>: ""} */}
                  {show ? <p className="errMsg">{errMsg.errors.data.mobile_number}</p> : ''}
                  {showM ? <p className="errMsg">Enter only 10 Digits Mobile number</p> : ""}
                </div>
                <div className="col-sm-12 mb-3">
                  <div className="input-group mb-2">
                    <span className="input-group-text">
                      <img src={lockicon} />
                    </span>
                    <input
                     
                      className="form-control"
                      placeholder="Password"
                      aria-label=""
                      name="password"
                      maxlength="14"
                      value={fizoUsers.password}
                      onChange={handleChange}
                      type={passwordShown ? "text" : "password"}
                    />
                    
                    <span className="input-group-text" onClick={togglePassword}>
                      <img src={eyesicon}  />
                    </span>
                  </div>
                  {/* {errors && fizoUsers.password <=0 ? <p className="errMsg">Please Enter Password</p>: ""} */}
                  {errors && fizoUsers.password && show ? <p className="errMsg">{errMsg.errors.data.password}</p> : ''}
                </div>
                <div className="col-sm-12 mb-3">
                  <div className="input-group mb-2">
                    <span className="input-group-text">
                      <img src={lockicon} />
                    </span>
                    <input
                       type={passwordShown2 ? "text" : "password"}
                      className="form-control"
                      placeholder="Confirm Password"
                      aria-label=""
                      name="c_password"
                      maxlength="14"
                      value={fizoUsers.c_password}
                      onChange={handleChange}
                    />
                    <span className="input-group-text" onClick={togglePassword2}>
                      <img src={eyesicon} />
                    </span>
                  </div>
                  {/* {errors && fizoUsers.c_password <=0 ? <p className="errMsg">Please Confirm Password</p> : ""} */}
                  {show ? <p className="errMsg">{errMsg.errors.data.c_password}</p> : ''}
                </div>
                <div class="col-sm-12 mb-5">
                  <div class="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      name="agree"
                      checked={agree ? true : false}
                      onClick={(e) => setAgree(e.target.checked)}
                    />
                    <label className="form-check-label" for="gridCheck">
                      I agree to the T&C and Privacy policy
                    </label>
                  </div>
                </div>
                <div className="col-12 mb-5">
                  <button type="submit" className="btn btn-white" disabled={agree ? false : true}>
                    Create Account
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
