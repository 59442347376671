import React from 'react'
import './HamburgerProgress.css';
import axiosClient from "../api/ApiInstance.js";
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link } from 'react-router-dom';
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Rings } from 'react-loader-spinner';
import { getPhotosApi } from "../api/Apis";


import CanvasJSReact from './canvasjs.react';
import { forEach } from 'lodash';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;



const HamburgerProgress = () => {

    const hiddenFileInput = useRef(null);
    
  const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState("null");
    const [file, setFile] = useState([]);
    const [userfile, setUserFile] = useState([]);
    const [uploadPop, setUploadPop] = useState(true);
    const [images, setImages] = useState([]);
    const [errors, setErrors] = useState(false);
    const [errMsg, setErrMsg] = useState({
      errors: [],
      isError: false,
    });
    const [show, setShow] = useState(false);

    const imageUrl = "https://api.fizo.in/";

    const getStat = async () => {
        await getPhotosApi()
          .then((res) => {
            console.log(res.data.data, "wwwwwwwwwwwwwwwww");
            setUserFile(res.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      };


    const handleClick = (event) => {
        hiddenFileInput.current.click();
      };
      const handleChange = async (event) => {
        if (event.target.files.length != 0) {
           
            for(let i = 0;i<event.target.files.length;i++){
                setImages((prevHabit) => [...prevHabit, event.target.files[i]]);
                let files = event.target.files[i];
                let reader = new FileReader();
                let url = reader.readAsDataURL(files);
                
                 reader.onloadend = function (e) {
                   setFile((prevHabit) => [...prevHabit, reader.result]);
                   
                  };
            }
            console.log(images,'filesssssssss');
           
        }
      };
      const delPreview = (event) => {
        console.log(images,'filesssssssss');
        var array = [...images]; // make a separate copy of the array
    var index = event.target.id;
  
    if (index !== -1) {
      array.splice(index, 1);
      setImages(array);
    }

    var array1 = [...file]; // make a separate copy of the array
    var index1 = event.target.id;
  
    if (index1 !== -1) {
      array1.splice(index1, 1);
      setFile(array1);
    }
      };

      const delPhoto = (event) => {

        // console.log(event.target.id,'photoid')
        axiosClient
        .post("/delpho",{
            id:event.target.id
        }
        )
        .then((res) => {
          console.log(res.data,'uploadssssssss');
          getStat();
        })
        .catch((error) => {
          console.log(error.response);
          setErrMsg({
            errors: error.response.data,
            isError: true,
          });
          if (error) {
            setShow(true);
          }
          
        });
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        
    setLoading(true);
        if (images.length == 0) {
            setErrors(true);
            console.log(errors);
            
          }else{
        
            

            
            for(let i=0;i<=images.length;i++){
                const image = new FormData();
                image.append(`image`,images[i]);
            
            axiosClient
            .post("/webphotos",image)
            .then((res) => {
              console.log(res.data,'uploadssssssss');
            })
            .catch((error) => {
              console.log(error.response);
              setErrMsg({
                errors: error.response.data,
                isError: true,
              });
              if (error) {
                setShow(true);
              }
              
          setLoading(false);
            });
             }

             setImages([]);
             setFile([]);
             setUploadPop(false);
             getStat();
          }
        
        
          setLoading(false);
      };

    const options = {
        animationEnabled: true,
        title: {
            text: ""
        },
        axisX: {
            valueFormatString: "MMM"
        },
        axisY: {
            title: "",
            prefix: "$"
        },
        data: [{
            yValueFormatString: "$#,###",
            xValueFormatString: "MMMM",
            type: "spline",
            dataPoints: [
                { x: new Date(2017, 0), y: 25060 },
                { x: new Date(2017, 1), y: 27980 },
                { x: new Date(2017, 2), y: 42800 },
                { x: new Date(2017, 3), y: 32400 },
                { x: new Date(2017, 4), y: 35260 },
                { x: new Date(2017, 5), y: 33900 },
                { x: new Date(2017, 6), y: 40000 },
                { x: new Date(2017, 7), y: 52500 },
                { x: new Date(2017, 8), y: 32300 },
                { x: new Date(2017, 9), y: 42000 },
                { x: new Date(2017, 10), y: 37160 },
                { x: new Date(2017, 11), y: 38400 }
            ]
        }]
    }

    const modalClick = () => {
        setUploadPop(true);
      };
    useEffect(() => {
        getStat();
      }, []);
    return (


        <>
            <section className='main'>
                <TopNav />
                <div className='container-fluid p-0'>
                    <section className='hamburger-progress-section pt-5'>
                        <div className='container'>
                            <div className='title-div'>
                                <h6 className='title'>Progress</h6>
                            </div>
                            <Link to="/progress">
                            <div className='back-btn-div'>
                                <img className='back-btn' src={process.env.PUBLIC_URL + '../assets/images/icons/back.png'} alt="" />
                            </div> 
                            </Link>
                            <div className='row'>
                                <div className='col-md-12 mx-auto'>
                                    <div className='row mt-5'>
                                        <div className='col-lg-12'>
                                            <div className='upload-div'>
                                                <h6 className='title mb-5'>Upload your progress photographs</h6>
                                                <p 
                                                className='attach-p'
                                                onClick={handleClick}
                                                >
                                                    Attach 
                                                    <img className='attach-icon' src={process.env.PUBLIC_URL + '/assets/images/icons/attachment.png'} />
                                                    </p>

                                                <div className='row mt-5'>
                                                    <div className='col-md-10 mx-auto'>
                                                        <div className='row'>
                                                        {userfile?.map((val,index) => (
                                                            <div className='col-md-3 col-sm-4  col-6 mt-3'>
                                                                <div className='add-im-div'>
                                                                    <img className='clie-img' src={imageUrl + val?.image} />

                                                                    <img className='close-icon' id={val?.id} onClick={delPhoto} src={process.env.PUBLIC_URL + '/assets/images/icons/close-icon.png'} />
                                                                </div>
                                                            </div>
                                                           ))}
                                                        {file?.map((val,index) => (
                                                            <div className='col-md-3 col-sm-4  col-6 mt-3'>
                                                                <div className='add-im-div'>
                                                                    <img className='clie-img' src={val} />

                                                                    <img className='close-icon' id={index} onClick={delPreview} src={process.env.PUBLIC_URL + '/assets/images/icons/close-icon.png'} />
                                                                </div>
                                                            </div>
                                                           ))}
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='upload-btn-div mt-5'>
                                                <input
                                                        type="file"
                                                        id="bloodpdf"
                                                        ref={hiddenFileInput}
                                                        accept="image/jpeg, image/jpg"
                                                        onChange={handleChange}
                                                        hidden
                                                        multiple
                                                    />
                                                    <span className="text text-danger">
                                                    {errors ? "Select the file first." : ""}
                                                    </span>
                                                    
                                                    <form onSubmit={handleSubmit}>
                                                    {loading ? (
                                                         <Rings color="#00BFFF" height={80} width={80} />
                                                            ) : (
                                                    <button className='btn upload-btn'>

                                                        Upload 
                                                        <img className='upload-icon' src={process.env.PUBLIC_URL + '/assets/images/icons/upload.png'} />
                                                        </button>
                                                             )}
                                                    </form>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="subcription-modal">
                <div
                  class={uploadPop?"modal fade":"modal fade show"}
                  id="subsModal"
                 style={{
                  display:uploadPop?"none":"block"
                 }}
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">
                          Photos Submitted Successfully...!
                        </h1>
                        {/* <p className="cal-no">480cal</p> */}
                        <button
                          type="button"
                          class="btn-close"
                          onClick={modalClick}
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="text-center">
                          
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                    </section>


                </div>

                <Add />
            </section>
        </>
    )
}


export default HamburgerProgress;