import * as React from "react";
import "./Form.css";
import { Field } from "@progress/kendo-react-form";
import { CardSelector } from "./card-selector";
import { FormInput, FormMaskedTextBox, FormDateInput } from "./form-components";
import { requiredValidator, cardValidator, cvcValidator } from "./validators";
import { getInputApi, getMedCondApi, getOneUserMedApi } from "../api/Apis";
import { useEffect } from "react";
import { useState } from "react";
const Medical = () => {
  let signupid;
  const [status, setStatus] = useState();
  const [disableStat, setDisableStat] = useState(false);
  const [disableId, setDisableId] = useState(false);
  const [habit, setHabit] = useState();
  const [medCondition, setMedCondition] = useState();
  const [execute, setExecute] = useState(0);
  const getMedCondition = {
    user_input_id: "",
    medical_condition_id: "",
  };
  const [fizoUsers, setFizoUsers] = useState(getMedCondition);
  const [userMed, setUserMed] = useState();

  let userMedical = [];
  let habitarr = [];

  const removeFromArrayByValue = (value) => {
    var array = [...habit]; // make a separate copy of the array
    var index = array.indexOf(value);
  
    if (index !== -1) {
      array.splice(index, 1);
      setHabit(array);
    }
  };

  const getStat = async () => {
    await getOneUserMedApi()
      .then((res) => {
        
let lengthMed = res.data.data.list.length;


        for(let i = 0;i < lengthMed;i++){
          
          userMedical[i] = res.data.data.list[i].medical_con_id;
          habitarr[i] = String(res.data.data.list[i].medical_con_id);
          if(res.data.data.list[i].medical_type.name == 'None'){
            setDisableStat(true);
            setDisableId();
          }
        }
        setHabit(habitarr);
        setUserMed(userMedical);
        sessionStorage.setItem("medicalchoose", habit);
        
        console.log(habit,'ffffffffff');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getData = async () => {
    await getInputApi()
      .then((res) => {
        setMedCondition(res.data.data.medcondition);
        setExecute(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChange = async (e) => {
    console.log('tttttttt:', e.target.name);
  
    if (habit.includes(String(e.target.id))) {
      await removeFromArrayByValue(String(e.target.id));
      if(e.target.name == 'None'){
        setDisableStat(false);
        setDisableId();
      }
    } else {
     await setHabit((prevHabit) => [...prevHabit, String(e.target.id)]);
     if(e.target.name == 'None'){
      await setHabit([]);
      await setHabit((prevHabit) => [...prevHabit, String(e.target.id)]);
      setDisableStat(true);
      setDisableId();
    }
    }


  
  };
  
  
const  setToSession = async () =>{
 await sessionStorage.setItem("medicalchoose", habit);
}



  useEffect(() => {
    if(execute == 0){
      getStat();
      getData();
    }
    setToSession()
  }, [habit]);
  return (
    <>
      <section className="main">
        <div className="container p-0">
          <form>
            <div className="row medical-top">
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 my-auto mb-5">
                <div className="sec_medical">
                  <h1 className="field-title">
                    Medical <br></br> Condition
                  </h1>

                  <div className="title-bd"></div>
                  <p className="substitle-p">Select your Medical Condition</p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7  col-md-6 col-sm-12">
                <div className="sec-2">
                  <div className="mediacal-box-select-div">
                  <div className="row">
                    {medCondition?.map((val) => {
                      return (
                        <>
                          
                            <div
                              className="col-xl-3 col-lg-6 col-md-6 col-6"
                              key={val.id}
                            >
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id={val.id}
                                  name={val.name}
                                  value={fizoUsers.user_input_id}
                                  onChange={handleChange}

                                  checked = 
                                    {habit.includes(String(val.id))?true:false}
                                    disabled={
                                      disableStat && val?.name != 'None'?true:false
                                    }
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault1"
                                >
                                  {val.name}
                                </label>
                              </div>
                            </div>
                          
                        </>
                      );
                    })}
                    </div>
                    {/* val.medical_type.id */}
                    {/* <div className="row">
                      <div className="col-xl-3 col-lg-6 col-md-6 col-6">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault1"
                          ></input>
                          <label
                            class="form-check-label"
                            for="flexCheckDefault1"
                          >
                            Dibetic
                          </label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <button onClick={handleSubmit}>get</button> */}
          </form>
        </div>
      </section>
    </>
  );
};
export default Medical;
