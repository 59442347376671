import React, { useEffect } from 'react'
import './BodyAnalysis.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link } from 'react-router-dom';
import { RadialGauge } from "@progress/kendo-react-gauges";
import GaugeChart from 'react-gauge-chart';
// import GaugeChart from 'react-gauge-chart';
// import GaugeChart from 'react-gauge-chart';
import './RadialGauge.css'; // Import custom CSS file for styling


export const BodyAnalysis = () => {
    const [value, setValue] = React.useState();
    const[needleVal,setNeddleVal]=React.useState()
    let bmr= Math.round(sessionStorage.getItem("bmr"))
    let bmi=Math.round(sessionStorage.getItem("bmi"))
    let tee=Math.round(sessionStorage.getItem("tee"))
    console.log(Math.round(bmr) + "" + Math.round(bmi) + "" + Math.round(tee) ,'logs');
    React.useEffect(() => {
        setInterval(() => {
          setNeddleVal(Math.ceil(Math.random() * 100));
        }, 1000);
      }, []);
      const radialOptions = {
        pointer: {
          value: value,
        },
    }
    const isFinished = needleVal === 1;
    const getD=()=>{
        if(bmi<18){
            setValue(0)
        }else if(bmi>18.5 && bmi<24.9){
            setValue(0.3)
        }else if(bmi>=25.0 && bmi<29.9){
            setValue(0.5)
        }else if(bmi>35){
            setValue(1)
        }
    }
    

    useEffect(()=>{
        getD()
    },[])
    return (
        <>

            <section className='main'>
                {/* <TopNav />   */}
                <div className='container-fluid p-0'>
                    <section className='body-analysis-section'>
                        <div className='row me-0 ms-0'>
                            <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-10 mx-auto'>
                                <div className='sec-1'>
                                    <h6 className='title'>Body Analysis Report</h6>
                                    <h6 className='oops'>
                                            Oops...! you are{' '}
                                                {Math.floor(bmi * 100) / 100 < 18.5 ? <span style={{color:"blue"}}> Underweight </span>: ''}
                                                {Math.floor(bmi * 100) / 100 > 18.5 && Math.floor(bmi * 100) / 100 < 25 ? <span style={{color:"green"}}>Normal </span>: ''}
                                                {Math.floor(bmi * 100) / 100 > 24.9 && Math.floor(bmi * 100) / 100 < 30 ? <span style={{color:"yellow"}}>Overweight</span>: ''}
                                                {Math.floor(bmi * 100) / 100 > 29.9 && Math.floor(bmi * 100) / 100 < 35 ? <span style={{color:"orange"}}>Obese </span>: ''}
                                                {Math.floor(bmi * 100) / 100 > 35 ? <span style={{color:"red"}}>'Extremely Obese' </span>: ''}
                                            </h6>

                                    <p className='title-subtext'>There's no need to worry, nutrihealth plus provides a proper diet plan.</p>

                                    <Link to="/bmi"><p className='bmi-p'>BMI <img className='' src={process.env.PUBLIC_URL + '/assets/images/icons/information-circle.png'} /></p></Link>

                                    <p className='bmi-count'>{Math.floor(bmi*100)/100}</p>
                                    <div className='bmr-img'>
                                    <img className='calculate-img' src={process.env.PUBLIC_URL + '/assets/images/exercise/calculator.png'} />
                                        <div className='gauge'>
                                       
        <GaugeChart 
        className={` needle${isFinished ? ' finished' : ''}`} style={{ '--needleVal': needleVal }}
          id="gauge-chart"
          
          percent={value} // the value of the gauge (between 0 and 1)
        //   arcPadding={0.02} // the spacing between arcs
          cornerRadius={3} // the corner radius of the gauge
          textColor="#000" // the color of the text inside the gauge
        //   arcWidth={0.2} // the width of the gauge arc
          needleColor="gray" // the color of the needle
          needleBaseColor="gray" // the color of the needle base
        //   colors={["#FF5F6D", "#FFC371", "#3AC7B8"]} // an array of colors for different sections of the gauge
        />
      </div>
                                        
                                    </div>
                                   
                                    {/* <RadialGauge {...radialOptions} /> */}
                                    
                                    <p className='loose-p'>You've to loose 10 Kg. weight</p>

                                    <div className='mt-4 mb-3'>
                                        <div className='row bmr-strip'>
                                            <div className='col-11 p-0'>
                                                <div className='d-flex'>
                                                    <div className='text-div'>
                                                        <p className='name'>BMR</p>
                                                    </div>
                                                    <div className='count-div'>
                                                        <p className='number'>{bmr}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-1 p-0'>
                                                <Link to="/bmr">
                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/information-circle.png'} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='row bmr-strip'>
                                            <div className='col-11 p-0'>
                                                <div className='d-flex'>
                                                    <div className='text-div'>
                                                        <p className='name'>TEE</p>
                                                    </div>
                                                    <div className='count-div'>
                                                        <p className='number'>{tee}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-1 p-0'>
                                                <Link to="/tee">
                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/information-circle.png'} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='get-plan mt-5'>
                                        <Link className='' to="/nutrihealth">
                                            <button className='plan-btn btn'>Get my plan</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )
}


export default BodyAnalysis;