import React, { useState, useEffect } from "react";
import "./RecipesDetails.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link, useParams,useNavigate } from "react-router-dom";
import VideoBg from "../video/omlet.mp4";
import { getSingleRecipeApi } from "../api/Apis";
import Parser from "html-react-parser";

export const RecipesDetails = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [nextData, setNextData] = useState({});
  const imageUrl = "https://api.fizo.in/";
  const getData = async () => {
    try {
      const res = await getSingleRecipeApi(id);
      setData(res.data.data.recipe);
      setNextData(res.data.data.nextRecipe)
      console.log(res.data.data,'nextrecipe');
    } catch (error) {
      console.log(error);
    }
  };

  const nextAuto = async () => {
    navigate(`/recipe-details/${nextData?.id}`);
  };
  useEffect(() => {
   
    getData();
  }, [id]);

  //   console.log(id);
  console.log(data);
  return (
    <>
      <section className="main">
        <div className="container-fluid p-0">
          <TopNav />
          <section className="recipe-details">
            <div className="container">
              <div className="revipe-back-div">
                <Link to="/recipes">
                  <div className="back-btn-div">
                    <img
                      className="back-btn"
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icons/back.png"
                      }
                      alt=""
                    />
                  </div>
                </Link>
              </div>

              <div className="recipe-video-div">
                <video
                  className="video"
                  src={imageUrl + data?.video_url}
                  autoPlay
                  controls
                  muted
                  onEnded={nextAuto}
                />

                <div className="vdo-title-div">
                  <h3 className="title">{data?.heading}</h3>
                </div>

                <div className="next-recipe-div">
                  <div className="d-flex">
                    <div className="me-2">
                      <p className="next-p">Next</p>
                      <h6 className="reci-h6">Recipes</h6>
                    </div>
                    <Link  to={`/recipe-details/${nextData?.id}`}>
                    <div>
                      <img
                        className="next-img"
                        src={
                          imageUrl +
                          nextData?.image
                        }
                      />
                    </div>
                    </Link>
                  </div>
                </div>

              </div>

              <div className="recipe-content-div">
                <h6 className="reciepe-name">Ingredients</h6>
                <ol className="order-left">
                  <li className="listt">{Parser(`${data?.short_desc}`)}</li>
                  
                </ol>

                <h6 className="reciepe-name">Description</h6>
                <p className="recipe-content-p">
                  {Parser(`${data?.dissipation}`)}
                </p>
              </div>
            </div>
          </section>

          <Add />
        </div>
      </section>
    </>
  );
};
export default RecipesDetails;
