import React from 'react'
import './DownloadApp.css';
import { Link } from 'react-router-dom';

export const DownloadApp = () => {
  return (
    <>
        <section className='main'>
            <div className='container-fluid p-0'>
                <section className='download-app'>
                    <h6 className='title'>Download App</h6>
                   <Link to={"https://play.google.com/store/apps/details?id=com.profcymaglobal.fizo"}>
                   <img className='download-img' src={process.env.PUBLIC_URL + 'assets/images/icons/google-play.png'} />
                   </Link> 
                </section>
            </div>
        </section>
    </>
  )
}

export default DownloadApp;
