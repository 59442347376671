import React from "react";
import "../my-info/MyInfo.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link, useNavigate } from "react-router-dom";
import { getProfilePic } from "../api/Apis";
import { useState, useEffect } from "react";
import { getInfoApi, postInfo } from "../api/Apis";

export const MyInfoEdit = () => {
  const [noSubs, setNoSubs] = useState(true);
  const [data, setData] = useState();
  const [pic, setPic] = useState();
  const [formData, setFormData] = useState({
    age_id: "",
    weight_id: "",
  });
  const navigate = useNavigate();
  const getData = async () => {
    await getInfoApi()
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data.user);
        setPic(res.data.data.user.profile_pic);
        setFormData({
          ...formData,
          age_id: res.data.data.userInput.age.name,
          weight_id: res.data.data.userInput.weight.name,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log(formData);

  const imageUrl = "https://api.fizo.in/";
  let imageData = require("form-data");
  const [file, setFile] = useState();
  const [name, setName] = useState();

  // const infoDetails = {
  //   profile_pic: "",
  // };

  // const [fizoUsers, setFizoUsers] = useState(infoDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleFileChange = (e) => {
  //   if (e.target.files) {
  //     setFile(e.target.files[0].name);
  //   }
  // };

  // console.log(imageUrl + data?.profile_pic);

  const handleUploadClick = async (e) => {
    try {
      const file = e.target.files[0]; // Get the selected file object
      if (!file) {
        // Handle case where no file is selected
        // console.log("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("profile_pic", file);

      // Assuming getProfilePic is an asynchronous function that uploads the profile picture
      const response = await getProfilePic(formData);
      // console.log(response); // Log the response from the server
      getData();
      setNoSubs(false);
      setTimeout(() => {
        navigate('/my-info-edit');
        window.location.reload(); 
      }, 1000);
       
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const users = await postInfo(formData);

    console.log(users);
    navigate("/my-info");
    // console.log(users, "h");
    // fizoUsers.profile_pic = img;
  };

  // console.log(fizoUsers.profile_pic, "logs");

  const modalClick = () => {
    setNoSubs(true);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section className="main">
        <div className="container-fluid p-0">
          <TopNav />
          <section className="my-info-form">
            <div className="row me-0 ms-0">
              <div className="col-xl-4 col-lg-6 col-md-8 col-10 mx-auto">
                <div className="form-section">
                  <form className="">
                    <div className="profile-div">
                      <div className="profile-img-div">
                        <img
                          className="profile-img"
                          // src={process.env.PUBLIC_URL + '/assets/images/clients/client2.png'}
                          src={data?.profile_pic == null?process.env.PUBLIC_URL + "/assets/images/icons/dummy-user.png"
                          : imageUrl + data?.profile_pic}
                        />
                        <form role="form" enctype="multipart/form-data">
                          <div>
                            <div className="mb-1">
                              {/* <img
                                className="edit-img"
                                src={imageUrl + data?.profile_pic}
                              /> */}
                              <input
                                type="file"
                                className="from-control d-none"
                                id="uploadFile"
                                accept="image/png, image/jpeg, image/gif"
                                onChange={handleUploadClick}
                                name="profile_pic"
                              />
                              <label for="uploadFile" className="uploadbtn">
                                {/* Upload File */}
                                <img
                                  className="edit-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/edit.png"
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        defaultValue={data?.name}
                      />
                    </div>

                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        value={data?.email}
                      />
                      <div className="overlay">
                        <img
                          className="yes-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/yes.png"
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Mobile</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        value={data?.mobile_number}
                      />
                      <div className="overlay">
                        <img
                          className="yes-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/yes.png"
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Gender</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        defaultValue={data?.user_input.gender}
                      />
                    </div>

                    <div className="form-group">
                      <label>Height</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        defaultValue={data?.user_input?.height?.name}
                      />
                      <div className="overlay">
                        <div className="d-flex">
                          {data?.user_input?.height?.height_id === 1 ? (
                            <button className="measure-p btn">Cm</button>
                          ) : (
                            <button className="measure-p2 btn">Ft</button>
                          )}

                          {/* <button className="measure-p2 btn">Inch</button> */}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Weight</label>
                      <input
                        className="form-control"
                        type="text"
                        name="weight_id"
                        onChange={handleChange}
                        value={formData?.weight_id}
                      />
                      <div className="overlay">
                        <div className="d-flex">
                          {data?.user_input?.weight?.weight_id === 1 ? (
                            <button className="measure-p btn">Kg</button>
                          ) : (
                            <button className="measure-p2 btn">Pound</button>
                          )}
                          {/* <button className="measure-p btn">Kg</button>
                          <button className="measure-p2 btn">Pound</button>
                          <button className="measure-p2 btn">Gram</button> */}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Age</label>
                      <input
                        className="form-control"
                        type="text"
                        name="age_id"
                        onChange={handleChange}
                        value={formData?.age_id}
                      />
                    </div>

                    <div className="align-save-btn">
                      <button
                        className="save-btn btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                  <div className="subcription-modal">
                <div
                  class={noSubs?"modal fade":"modal fade show"}
                  id="subsModal"
                 style={{
                  display:noSubs?"none":"block"
                 }}
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">
                          Photo Updated Successfully..!
                        </h1>
                        {/* <p className="cal-no">480cal</p> */}
                        <button
                          type="button"
                          class="btn-close"
                          onClick={modalClick}
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="text-center">
                          
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                </div>
              </div>
            </div>
          </section>

          <Add />
        </div>
      </section>
    </>
  );
};
export default MyInfoEdit;
