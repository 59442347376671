import React from 'react'
import './InviteFriend.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton } from "react-share";
import { FacebookIcon, TwitterIcon,  TelegramIcon } from "react-share";
import { useState } from "react";
export const InviteFriend = () => {
const [copytext, setCopyText] = useState(false);
    const messengerShare = async () => {

        navigator.clipboard.writeText('https://fizo.profcymadev.in/')
        setCopyText(true);
    };

    return (
        <>

            <section className='main'>
                <TopNav />  
                <div className='container-fluid p-0'>
                    <section className='invite-freind-section'>
                            <div className='container'>
                                <div className='title-div pt-5'>
                                    <h6 className='title'>Invite friends</h6>


                                    <div className='row'>
                                        <div className='col-md-6 mx-auto'>

                                            <form className=''>
                                                <div class="search mt-4">
                                                    <input type="text" class="form-control" placeholder="https://fizo.profcymadev.in/" />
                                                    <button
                                                    type="button"
                                                     class="btn btn-primary"
                                                    onClick={messengerShare}
                                                    >Copy</button>
                                                   
                                                </div>
                                            </form>
                                            <span 
                                                    className='text text-success mt-5'
                                                    > {copytext?'Link Copied..!':''}</span>


                                            <div className='div-or text-center m-sm-5 mt-5'>
                                                <p className='orp'>OR</p>
                                                <hr className='align-line'></hr>
                                            </div>


                                            <div className='social-icon-div'>
                                                <div className='d-flex justify-content-center'>
                                                    <div>
                                                        <Link 
                                                        target='_blank'
                                                        to={`https://api.whatsapp.com/send/?phone&text=https://fizo.profcymadev.in/&type&app_absent=0`}
                                                        ><img className='social-img' src={process.env.PUBLIC_URL + '/assets/images/social/whatsapp.png'}  /></Link>
                                                    </div>
                                                    <div>

                                                        
                                                    <FacebookShareButton
                                                            image={"https://www.w3schools.com/images/lamp.jpg"}
                                                            url={"https://fizo.profcymadev.in/"}
                                                            className="Demo__some-network__share-button"
                                                        >
                                                         <img className='social-img' src={process.env.PUBLIC_URL + '/assets/images/social/messanger.png'}  />
                                                        </FacebookShareButton>
                                                            {/*      
                                                        <Link 
                                                        onClick={messengerShare}
                                                        ><img className='social-img' src={process.env.PUBLIC_URL + '/assets/images/social/messanger.png'}  /></Link> */}
                                                    </div>
                                                    <div>
                                                    <TelegramShareButton
                                                                image={"https://www.w3schools.com/images/lamp.jpg"}
                                                                url={"https://fizo.profcymadev.in/"}
                                                                className="Demo__some-network__share-button"
                                                            >
                                                                <img className='social-img' src={process.env.PUBLIC_URL + '/assets/images/social/telegram.png'}  />
                                                            </TelegramShareButton>
                                                        {/* <Link><img className='social-img' src={process.env.PUBLIC_URL + '/assets/images/social/telegram.png'}  /></Link> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <p className='referal-code'>Do you have any referral code?</p>
                                            <p className='redeemcode'>Redeem Code</p>
                                        </div>
                                    </div>                                    
                                </div>  


                                <div className='didyou-box'>
                                    <h6 className='title'>Did you know? How it works?</h6>
                                    <p className='text'><span>1</span>Invite your friends.</p>
                                    <p className='text'><span>2</span>Invite your friends.</p>
                                    <p className='text'><span>3</span>Invite your friends.</p>
                                </div>
                               
                            </div>

                            <Add />
                    </section>
                </div>
            </section>
        </>
    )
}


export default InviteFriend;