import React, { useEffect } from "react";
import axiosClient from '../api/ApiInstance.js'
import "./login.css";
import callingicon from "./images/calling-icon.png";
import lockicon from "./images/lock-icon.png";
import eyesicon from "./images/eyes-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../api/Apis";
import { useState } from "react";
import firebase from 'firebase/compat/app'
import { auth } from '../firebasefile'

const Login = () => {
  const [usernameErr, setUsernameErr] = useState(false);
  const [pswrdErr, setPswrdErr] = useState(false);
  const [agree, setAgree] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  //login api start
  let navigate = useNavigate();
  const loginApi = {
    username: "",
    password: "",
  };

  const [fizoUsers, setFizoUsers] = useState(loginApi);
  const [errors, setErrors] = useState(false);
  const [errMsg, setErrMsg] = useState({
    errors: [],
    isError: false
  });
  const [show, setShow] = useState(false);
  const [showM, setShowM] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFizoUsers({ ...fizoUsers, [name]: value });
    // console.log(fizoUsers);
    if (fizoUsers.username.length >10) {
      setShowM(true);
    } else {
      setShowM(false);
    }
    if (fizoUsers.username.length !== 0 && fizoUsers.password.length !== 0) {
      setAgree(true)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if(errMsg.isError){
    //   console.error("user Not found");
    //   return;
    // }
    
    if (fizoUsers.username.length == 0 || fizoUsers.password == 0) {
      setErrors(true);
      console.log(errors);
    }
    axiosClient
      .post("/login", {
        username: fizoUsers.username,
        password: fizoUsers.password,
      })
      .then(res => {
        // console.log(res.data,'filled');
        let success=res.data.success;
        // console.log(success,'success');
         sessionStorage.setItem("success",success);
        // console.log("data",res.data.data.user_input.filled);
        let filled=res.data.data.user_input.filled;
        if(res.data.data.unfilled_fields){
          let unfilled=res.data.data.unfilled_fields[0];
            sessionStorage.setItem('unfilled',unfilled);
          // console.log(unfilled,'un');
        }
        // console.log(filled,'fill');
         sessionStorage.setItem('filled',filled);
        let medField=res.data.data.medcon;
         sessionStorage.setItem('medField',medField);
        // console.log(medField,'medfield');
        let userid = res.data.data.user_id;
         sessionStorage.setItem('userid',userid);
        // console.log(userid,'userid');
       
        let token=res.data.data.token;
         sessionStorage.setItem('token',token);
        // console.log(token,'token');
        let gender = res.data.data.gender;
         sessionStorage.setItem('gender',gender);
        // console.log(gender,'eeeeeeeeeeeeeeeeeeeeeeeeeeee');

        if (res.data.data.user_input.filled==true) {
          if(res.data.data.subs.subscription == 'unpaid'){
            sessionStorage.setItem('plan',false);
            navigate("/choose-plan");
          }else{
            sessionStorage.setItem('plan',true);
            navigate("/nutrition");
          }
          
         
        }else if(res.data.data.user_input.filled==false){
          navigate("/form");
        }
      })
      .catch(error => {
        console.log(error.response);
        setErrMsg({
          errors: error.response.data,
          isError: true,
        });
        if (error) {
          setShow(true);
        }
      })
    // const users = await login(fizoUsers.username, fizoUsers.password);
    // let success=users.data.success;
    // // console.log(success,'success');
    // await sessionStorage.setItem("success",success);
    // // console.log("data",users.data.data.user_input.filled);
    // let filled=users.data.data.user_input.filled;
    // if(users.data.data.unfilled_fields){
    //   let unfilled=users.data.data.unfilled_fields[0];
    //   await  sessionStorage.setItem('unfilled',unfilled);
    //   // console.log(unfilled,'un');
    // }
    // // console.log(filled,'fill');
    // await sessionStorage.setItem('filled',filled);
    // let medField=users.data.data.medcon;
    // await sessionStorage.setItem('medField',medField);
    // // console.log(medField,'medfield');
    // let userid = users.data.data.user_id;
    // await sessionStorage.setItem('userid',userid);
    // // console.log(userid,'userid');
   
    // let token=users.data.data.token;
    // await sessionStorage.setItem('token',token);
    // // console.log(token,'token');
    // let gender = users.data.data.gender;
    // await sessionStorage.setItem('gender',gender);
    // // console.log(gender,'eeeeeeeeeeeeeeeeeeeeeeeeeeee');
 
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <section className="login">
        <div className="container">
          <div className="row background-color">
            <div className="col-md-12 mt-5">
            <Link to="/">
              <div className="heading-holder text-center">
                <h2>fizo</h2>
              </div>
              </Link>
            </div>
            <div className="col-lg-5 col-md-7 col-12 mx-auto">
              <div className="text-holder mb-5 mt-5 text-center">
                <h3>Login</h3>
                <p>
                  Don't have an account?
                  <Link to="/sign-up">
                    <span className="ms-2">Create Account</span>
                  </Link>
                </p>
              </div>
              <form className="row" onSubmit={handleSubmit}>

                {show ? <p className="errMsg">{errMsg.errors.message}</p> : ''}
                <div className="col-sm-12 mb-2">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <img src={callingicon} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="username" 
                      minlength="10"
                      maxlength="10"
                      value={fizoUsers.username}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={handleChange}
                    />
                  </div>
                  {errors && fizoUsers.username <= 0 ? <p className="errMsg">Please Enter User Name</p> : ""}
                  {showM ? <p className="errMsg">Enter only 10 Digits Mobile number</p> : ""}
                </div>
                <div className="col-sm-12 mb-2">
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <img src={lockicon} />
                    </span>
                    <input
                      className="form-control"
                      placeholder="Password"
                      aria-label=""
                      name="password"
                      value={fizoUsers.password}
                      onChange={handleChange}
                      type={passwordShown ? "text" : "password"}
                    />
                    <span className="input-group-text">
                      <img src={eyesicon}  onClick={togglePassword}/>
                    </span>
                  </div>
                  {errors && fizoUsers.password <= 0 ? <p className="errMsg">Please Enter Your Password</p> : ""}
                </div>
                <div class="col-sm-6 col-6">
                 <div class="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      onClick={togglePassword}
                    />
                    <label className="form-check-label" for="gridCheck">
                      Show password
                    </label>
                  </div> 
                </div>
                <div className="col-sm-6 col-6 ">
                  <div className=" text text-end">
                    <Link to="/forgot-password">
                      <p>Forgot password?</p>
                    </Link>
                  </div>
                </div>
                <div className="col-12 mt-5">
                  <button type="submit" className="btn btn-white">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
