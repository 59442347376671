import * as React from "react";
import "./Form.css";
import { Field } from "@progress/kendo-react-form";
import { CardSelector } from "./card-selector";
import { FormInput, FormMaskedTextBox, FormDateInput } from "./form-components";
import { requiredValidator, cardValidator, cvcValidator } from "./validators";
import Downshift from "downshift";
import { getAgeApi, getInputApi,getOneUserInputApi  } from "../api/Apis";
import { useState } from "react";
const Age = () => {
  // const items = [
  //   { value: "1" },
  //   { value: "2" },
  //   { value: "3" },
  //   { value: "4" },
  //   { value: "15" },
  //   { value: "16" },
  //   { value: "17" },
  //   { value: "18" },
  //   { value: "19" },
  //   { value: "20" },
  // ];

  const [items, setItems] = useState([]);
  const [userData, setUserData] = useState();
  const [age, setAge] = useState(0);



  const getAge = {
    age_id: "",
  };

  const getStat = async () => {
    await getOneUserInputApi()
      .then((res) => {
        
        //    console.log(res.data.data.activityLevel[0].name);
        if(res.data.data.list.age_id != null){
          setAge(res.data.data.list.age_id);
          getAge.age_id = res.data.data.list.age_id;
          setFizoUsers(getAge);
          console.log(fizoUsers.age_id,"wwwwwwwwwwwwwwwww");
          sessionStorage.setItem("ageid", res.data.data.list.age_id)
        }else{
          sessionStorage.setItem("ageid", "0")
        }
        console.log(res.data.data.list.age_id,"w");
        // setPlan(res.data.data.package);
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [fizoUsers, setFizoUsers] = useState(getAge);
  const getDataA = async () => {
    let data = await getInputApi();
    setUserData(data.data.data.age);
    let data1 = data.data.data.age.map((data) => {
      return { id: data.id, value: data.name };
    });
    setItems(data1);
  };

  // console.log(items);

 


  const handleChange = (e) => {
    // const { name, value } = e.target;
    // setFizoUsers({ ...fizoUsers, [name]: value });
    getAge.age_id = e.target.value;
          setFizoUsers(getAge);
    sessionStorage.setItem("ageid", e.target.value)
  };
  React.useEffect(() => {
    getDataA();
    getStat();
  }, []);
  return (
    <>
      <section className="main">
        <div className="container p-0">
          <form>
            <div className="row workout-Age">
              <div className="col-md-7 my-auto">
                <div className="sec-Age">
                  <h1 className="field-title">
                    Which age group <br></br> do you belong to?
                  </h1>

                  
                  <div className="title-bd"></div>
                  <p className="substitle-p">Enter your age</p>
                </div>
              </div>

              <div className="col-md-5">
                <div className="sec-2">
                  <div className="form-group agegroup-div">
                    {/* <input
                      className="form-control"
                      type=""
                      placeholder=""
                      name="age_id"
                      value={fizoUsers.age_id}
                      onChange={handleChange}
                    /> */}

                   <Downshift
                      onChange={(selection) =>
                        sessionStorage.setItem("ageid", selection.id)
                      }
                      itemToString={(item) => (item ? item.value : "")}
                    >
                      {({
                        getInputProps,
                        getItemProps,
                        getLabelProps,
                        getMenuProps,
                        isOpen,
                        inputValue,
                        highlightedIndex,
                        selectedItem,
                        getRootProps,
                      }) => (
                        <div>
                          <div className="mb-3"
                            style={{ display: "inline-block" }}
                            {...getRootProps({}, { suppressRefError: true })}
                          >
                            <div className="col-md-10">
                              <div className="form-group text-center agegroup-div">
                                <input
                                  className="form-control text-center"
                                  type="text"
                                  name="age_id"
                                  maxlength="2"
                                  value={fizoUsers.age_id}
                                  onChange={handleChange}
                                  placeholder="Enter Your Age"
                                  
                                />
                              </div>
                            </div>
                          </div>
                          <p {...getMenuProps()}>
                            {isOpen
                              ? items
                                .filter(
                                  (item) =>
                                    !inputValue ||
                                    item.value.includes(inputValue)
                                )
                                .map((item, index) => (
                                  <p
                                    {...getItemProps({
                                      key: item.value,
                                      index,
                                      item,
                                      style: {
                                        backgroundColor:
                                          highlightedIndex === index
                                            ? "lightgray"
                                            : "white",
                                        fontWeight:
                                          selectedItem === item
                                            ? "bold"
                                            : "normal",
                                      },
                                    })}
                                  >
                                    {item.value}
                                  </p>
                                ))
                              : null}
                          </p>
                        </div>
                      )}
                    </Downshift> 
                  </div>
                </div>
              </div>
            </div>
            {/* <button onClick={handleSubmit}>get</button> */}
          </form>
        </div>
      </section>
    </>
  );
};

export default Age;
