import React from 'react'
import './CalculatorDetails.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link } from 'react-router-dom';
import {} from "../api/Apis";
import { useEffect } from "react";
import { useState } from "react";
import axiosClient from '../api/ApiInstance.js'

export const CalculatorDetails = () => {

    const [type, setType] = useState();
const [result, setResult] = useState();
    const [errors, setErrors] = useState(false);
    const [errMsg, setErrMsg] = useState({
      errors: [],
      isError: false
    });
    const [show, setShow] = useState(false);

    const getStat = async () => {

        let gender = sessionStorage.getItem('gender');
        let age = sessionStorage.getItem('age');
        let height = sessionStorage.getItem('height');
        let weight = sessionStorage.getItem('weight');
        let strweight = sessionStorage.getItem('strweight');
        let curweight = sessionStorage.getItem('curweight');
        let reps = sessionStorage.getItem('reps');
        let fats = sessionStorage.getItem('fat');
        let activity = sessionStorage.getItem('activity');

        let caltype = sessionStorage.getItem('calType');

        console.log(caltype,"kkkkkkkkkkk");
            if(caltype == 1)
                    {
                        axiosClient
            .post("/calbmrpost", {
              height_id : height,
              weight_id : weight,
              age_id : age,
              gender : gender
    
            })
            .then(res => {
                setType("BMR");
              setResult(res.data.data.BMR);
            })
            .catch(error => {
              console.log(error.response);
              setErrMsg({
                errors: error.response.data,
                isError: true,
              });
              if (error) {
                setShow(true);
              }
            })
                    }

                    if(caltype == 2)
                        {
                            axiosClient
                .post("/calbmi", {
                    height_id : height,
                    weight_id : weight,
                    age_id : age,
                    gender : gender
        
                })
                .then(res => {
                    setType("BMI");
                  setResult(res.data.data.BMI);
                })
                .catch(error => {
                  console.log(error.response);
                  setErrMsg({
                    errors: error.response.data,
                    isError: true,
                  });
                  if (error) {
                    setShow(true);
                  }
                })
                        }
                        if(caltype == 3)
                            {
                                axiosClient
                    .post("/caltee", {
                      height_id : height,
                      weight_id : weight,
                      age_id : age,
                      activity_level_id : activity,
                      gender : gender
            
                    })
                    .then(res => {
                        setType("TEE");
                      setResult(res.data.data.TEE);
                    })
                    .catch(error => {
                      console.log(error.response);
                      setErrMsg({
                        errors: error.response.data,
                        isError: true,
                      });
                      if (error) {
                        setShow(true);
                      }
                    })
                            }
                            if(caltype == 4)
                                {
                                    axiosClient
                        .post("/calrm", {
                          weight_id : weight,
                          reps : reps
                
                        })
                        .then(res => {
                            setType("RM");
                          setResult(res.data.data.RM);
                        })
                        .catch(error => {
                          console.log(error.response);
                          setErrMsg({
                            errors: error.response.data,
                            isError: true,
                          });
                          if (error) {
                            setShow(true);
                          }
                        })
                                }
                                if(caltype == 5)
                                    {
                                        axiosClient
                            .post("/cal-lean", {
                              weight_id : weight,
                              body_fat_per : fats
                    
                            })
                            .then(res => {
                                setType("Lean Body Mass");
                              setResult(res.data.data.leanBodyMass);
                            })
                            .catch(error => {
                              console.log(error.response);
                              setErrMsg({
                                errors: error.response.data,
                                isError: true,
                              });
                              if (error) {
                                setShow(true);
                              }
                            })
                                    }
                                    if(caltype == 6)
                                        {
                                            axiosClient
                                .post("/cal-protein", {
                                  weight_id : weight
                        
                                })
                                .then(res => {
                                    setType("Protein");
                                  setResult(res.data.data.proteinRequired);
                                })
                                .catch(error => {
                                  console.log(error.response);
                                  setErrMsg({
                                    errors: error.response.data,
                                    isError: true,
                                  });
                                  if (error) {
                                    setShow(true);
                                  }
                                })
                                        }
                                        if(caltype == 7)
                                            {
                                                axiosClient
                                    .post("/cal-weightloss", {
                                        starting_weight : strweight,
                                        current_weight : curweight,
                            
                                    })
                                    .then(res => {
                                        setType("Weight Loss");
                                      setResult(res.data.data.proteinRequired);
                                    })
                                    .catch(error => {
                                      console.log(error.response);
                                      setErrMsg({
                                        errors: error.response.data,
                                        isError: true,
                                      });
                                      if (error) {
                                        setShow(true);
                                      }
                                    })
                                            }
       
      };


   


    useEffect(() => {
        getStat();
      }, []);
  return (
    <>
        <section className='main'> 
            <div className='container-fluid p-0'>
                <TopNav />
                <section className='calculator-details'>
                    <div className='container'>
                        <Link to="/calculator">
                            <div className='back-btn-div'>
                                <img className='back-btn' src={process.env.PUBLIC_URL + '/assets/images/icons/back.png'} alt="" />
                            </div> 
                        </Link> 
                        <div className='calculator-title-div text-center'>
                            <h6 className='title'>Calculated {type}</h6>
                            <p className='subtitle'>The <strong>Basal Metabolic Rate (BMR)</strong> Calculator estimates your basal metabolic rate—the amount of energy expended while at rest in a neutrally temperate environment, and in a post-absorptive state</p>

                            <div className='mt-3'>
                              <p className='result-p'>Result</p>
                              <h5 className='total'>{type} = {result} Calories/day</h5>
                              <p className='level'>Daily calorie needs based on activity level</p>
                            </div>
                        </div>     

                        <div className='row'>
                            <div className='col-md-11 mx-auto'>
                                <div className='calculator-table'>
                                    <table className='table table-responsive table-bordered'>
                                        <thead>
                                            <tr>
                                                <td>Activity Level</td>
                                                <td>Calorie</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Sedentary : little or no exercise</td>
                                                <td>1,926</td>
                                            </tr>
                                            <tr>
                                                <td>Exercise : 1-3 times/week</td>
                                                <td>2,207</td>
                                            </tr>
                                            <tr>
                                                <td>Exercise : 1-3 times/week</td>
                                                <td>2,207</td>
                                            </tr>
                                            <tr>
                                                <td>Daily exercise or intense exercise 3-4 times/week</td>
                                                <td>2,488</td>
                                            </tr>
                                            <tr>
                                                <td>Intense exercise 6-7 times/week</td>
                                                <td>2,769</td>
                                            </tr>
                                            <tr>
                                                <td>Very intense exercise daily,or physical job</td>
                                                <td>3,050</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div className='row mt-4'>
                                        <div className='col-lg-4 bd-right'>
                                            <p className='exercise-activity'><span>Exercise:</span> 15-30 min. of elevated heart rate activity.</p>
                                        </div>
                                        <div className='col-lg-4 bd-right'>
                                            <p className='exercise-activity'><span>Intense exercise:</span>  45-120 min. of elevated heart rate activity.</p>
                                        </div>
                                        <div className='col-lg-4'>
                                            <p className='exercise-activity'><span>Very intense exercise:</span>  2+ hours of elevated heart rate activity.</p>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>               
                        

                        
                    </div>
                </section>

                <Add />
            </div>
        </section>

        
    </>
  )
}
export default CalculatorDetails;
