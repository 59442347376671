import React from "react";
import axiosClient from "../api/ApiInstance.js";
import "./forgotpass.css";
import {  Link, useNavigate } from "react-router-dom";
import { forgotpass } from "../api/Apis";
import { useState } from "react";
const Forgotpass = () => {
  let navigate = useNavigate();
  const forgotPassApi = {
    mobile_number: "",
    otp: "",
  };

  //set otp
  const [otp, setOtp] = useState("");

  const [fizoUsers, setFizoUsers] = useState(forgotPassApi);
  const [errMsg, setErrMsg] = useState({
    errors:[],
    isError:false
  });
  const [show, setShow] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFizoUsers({ ...fizoUsers, [name]: value });
    console.log(fizoUsers);
 
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const users = await forgotpass(fizoUsers.mobile_number, phoneOtp);
    // console.log(users);
    // sessionStorage.setItem("key",fizoUsers.mobile_number)
  //  const num=sessionStorage.getItem("key")
  //  console.log(num,'session');
    //random number functionality for otp
    const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
    console.log(randOtp,'random');
    
    if (fizoUsers.mobile_number) {
      //set value of mobile in session storage
      sessionStorage.setItem("mobileOTP", fizoUsers.mobile_number);
      
    console.log(sessionStorage.getItem("mobileOTP"),'sessionMobile');
    }
    if (randOtp) {
      //set value of random otp in session storage
      sessionStorage.setItem("gotp", randOtp);
    }
  //get value of random otp from session storage
  const phoneOtp = sessionStorage.getItem("gotp");
  console.log(phoneOtp,'phone');
  axiosClient
  .post("/forget-password", {
    mobile_number:fizoUsers.mobile_number,
    otp: phoneOtp,
  })
  .then(res => {
      console.log(res.data);
      if (res.status === 200) {
        navigate("/otp-verification");
      }
  })
  .catch(error => {
      console.log(error.response);
      setErrMsg({
        errors: error.response.data,
        isError:true,
      });
      if(error){
        setShow(true);
      }
  })
};
  

  return (
    <>
      <section className="Forgot-password">
        <div className="container">
          <div className="row background-color">
            <div className="col-md-12 mt-5">
            <Link to="/">
              <div className="heading-holder text-center">
                <h2>fizo</h2>
              </div>
              </Link>
            </div>
            <div className="col-lg-5 col-md-7 col-12 mx-auto">
              <div className="text-holder mt-5 text-center">
                <h3>Forgot Password</h3>
                <p>
                  Please type your email or phone number below
                  <br /> and we can help you reset password
                </p>
              </div>
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-sm-12 mb-2">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile number"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="mobile_number"
                      minlength="10"
                      maxlength="10"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={fizoUsers.mobile_number}
                      onChange={handleChange}
                    />
                  </div>
                  {/* { ? <p className="errMsg">{errMsg.errors.data.mobile_number}</p>:''} */}
                  {show && errMsg.errors.data.mobile_number ? <p className="errMsg">{errMsg.errors.data.mobile_number}</p>:<p className="errMsg">{errMsg.errors.message}</p>}
                 
                </div>
                <div className="col-12 mt-5">
                  <button type="submit" className="btn btn-white">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Forgotpass;
