import * as React from "react";
import "./Form.css";
import { Field } from "@progress/kendo-react-form";
import { CardSelector } from "./card-selector";
import { FormInput, FormMaskedTextBox, FormDateInput } from "./form-components";
import { requiredValidator, cardValidator, cvcValidator } from "./validators";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import { Autoplay, Pagination } from "swiper";
import { getFoodApi, getInputApi,getOneUserInputApi   } from "../api/Apis";
import { useState } from "react";
import { useEffect } from "react";

const Food = () => {
  // const [genderId, setGenderId] = useState("male");
  // const[nameofGender,setNameofGender]=useState(true)
 

const [foodData, setFoodData] = useState();
const [userFoodData, setUserFood] = useState('null');
const imageUrl = " https://api.fizo.in/";
  const getStat = async () => {
    await getOneUserInputApi()
      .then((res) => {
        
        if(res.data.data.list.food_perfrence_id != null){
        setUserFood(res.data.data.list.food_perfrence_id);
        console.log(res.data.data.list.food_perfrence_id,"wwwwwwwwwwwwwwwww");
        sessionStorage.setItem("foodtype", res.data.data.list.food_perfrence_id);
        }else{
          sessionStorage.setItem("foodtype", "0");
        }
        // setPlan(res.data.data.package);
       
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const getStats = async () => {
    await getInputApi()
      .then((res) => {
        
        setFoodData(res.data.data.foodPerfrence);
        // console.log(res.data.data.foodPerfrence,"eeeeeeeeeeeeeee");
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

 
 
  const getId = (event) => {
    let id= event;
    sessionStorage.setItem("foodtype", id);
    setUserFood(id);
    console.log(userFoodData, "get");
  };
 
  useEffect(() => {
    getStat();
    getStats(); 
    // openRazorpayWindow();
}, []);
  return (
    <>
      <section className="main">
        <div className="container p-0">
          <form>
            <div className="row workout-Food">
              <div className="col-lg-6 col-sm-12 my-auto">
                <div className="sec-Food">
                  <h1 className="field-title">
                    Let's get to <br></br> know you better
                  </h1>

                  <div className="title-bd"></div>
                  <p className="substitle-p">Select your food preferance</p>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 col-10 mx-auto">
                <div className="sec-2">
                  <div className="food-slider-main">
                    <Swiper
                      slidesPerView={"auto"}
                      spaceBetween={30}
                      loop={true}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        575: {
                          slidesPerView: 2,
                          spaceBetween: 0,
                        },
                        600: {
                          slidesPerView: 2,
                          spaceBetween: 0,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 0,
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 0,
                        },
                        1300: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                      }}
                    className="py-5">
                      {foodData?.map((val) => (
                      <SwiperSlide>
                        <div className="slide-img-div"
                         onClick={() => {
                          getId(val?.id);
                        }}
                        >
                          <div className="form-check mb-3" 
                         
                          >
                            <input className="form-check-input d-none" type="radio" 
                              name="food"
                              checked= {userFoodData == val?.id ? true:false}
                              /> 
                              <label className="form-check-label" htmlFor={val.id}>
                              <img
                              className="food-item-img"
                              src={
                                imageUrl +
                                val.image
                              } 
                              alt="..."
                              
                              />
                              </label>
                          <h6 className="foodname mx-auto text-center">{val.name}</h6>
                          </div>
                        </div>
                      </SwiperSlide>
                      ))}
                      {/* <SwiperSlide>
                        <div className="slide-img-div">
                        <div className="form-check mb-3">
                            <input className="form-check-input d-none" type="radio" name="food" id="2"
                              onClick={getId}/>
                              <label className="form-check-label" htmlFor="2">
                              <img
                              className="food-item-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/meal/png_2.png"
                              } alt="..."/>
                              </label>
                          </div> */}
                          {/* <div className="food-img-div">
                            <img
                              className="food-item-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/meal/png_2.png"
                              }
                              id="2"
                              onClick={getId}
                            />
                          </div> */}
                          {/* <h6 className="foodname mt-2 mx-auto text-center">Vegan</h6>
                        </div>
                      </SwiperSlide> */}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            {/* <button onClick={handleSubmit}>get</button> */}
          </form>
        </div>
      </section>
    </>
  );
};
export default Food;
