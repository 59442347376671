import React from 'react'
import './Tee.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link } from 'react-router-dom';
import VideoBg from '../video/omlet.mp4';

export const Tee = () => {
  return (
    <>
        <section className='main'> 
            <div className='container-fluid p-0'>
                <TopNav />
                <section className='bmr-details'>
                    <div className='container'>                                            
                        <div className='row'>
                            <div className='col-lg-6 mx-auto'>
                                <div className='back-div'>
                                    <Link to="/body-analysis">
                                        <div className='back-btn-div'>
                                            <img className='back-btn' src={process.env.PUBLIC_URL + '/assets/images/icons/back.png'} alt="" />
                                        </div> 
                                    </Link> 
                                </div>   
                                <div className='bmr-div'>
                                    <div className='title-div'>
                                        <h6 className='title'>What is TEE ?</h6>
                                    </div>

                                    <div className='mt-4'>
                                        <p className='content-p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse</p>
                                    </div>

                                    <ul className='mt-3'>
                                        <li className='content-p'>Sed ut perspiciatis unde omnis iste</li>
                                        <li className='content-p'>Lorem ipsum dolor sit amet</li>
                                        <li className='content-p'>Lorem ipsum dolor sit amet</li>
                                        <li className='content-p'>Lorem ipsum dolor sit amet</li>
                                        <li className='content-p'>Lorem ipsum dolor sit amet</li>
                                        <li className='content-p'>Lorem ipsum dolor sit amet</li>
                                    </ul>
                                </div>  
                            </div>
                        </div>                     
                    </div>
                </section>

                <Add />
            </div>
        </section>

        
    </>
  )
}
export default Tee;
