import React from 'react'
import './Blog.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link , useNavigate} from 'react-router-dom';
import { useEffect } from "react";
import { useState } from "react";
import { getBlogs} from "../api/Apis";
import { Rings } from 'react-loader-spinner';



export const Blog = () => {
    
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
    const [response,setResponse] = useState()
    const [formData1, setFormData1] = useState({
        "plan": "", 
    });
    const [blog, setBlogs] = useState();
    const imageUrl = " https://api.fizo.in/";
    const getblog = async () => {
        await getBlogs()
          .then((res) => {
            //    console.log(res.data.data.activityLevel[0].name);
            setBlogs(res.data.data.blog);
            // console.log(typeof res.data.data.blog);
            console.log(res.data.data.blog);
            setLoading(false);
            // setPlan(res.data.data.package);
           
          })
          .catch((error) => {
            console.log(error);
          });
      };

     

      useEffect(() => {
        getblog();
        
        // openRazorpayWindow();
    }, []);
  return (
    <>
        <section className='main'> 
            <div className='container-fluid p-0'>
                <TopNav />
                {loading ? (
                    <div className="loader-ring"
                    
                    >
       <Rings color="#00BFFF" height={80} width={80} 
       
       />
       </div>
      ) : (
                <section className='blog'>
                    <div className='container'>
                    <div className='blog-title-div text-center'>
                            <h6 className='title'>Blogs</h6>
                            
                        </div>
                        
                       
                       
                        
                    {blog?.map((val) =>{
                                        return(
                                            <>
                        

                        <div className='blog-card'>
                            <Link 
                             to={`/blog-details/${val?.id}`}
                            >
                                <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                        <div className='calender-div'>
                                            <div className='d-flex justify-content-end'>
                                                <div>
                                                    <p className='date-p'><img className='calender-img' src={process.env.PUBLIC_URL + '/assets/images/icons/calender.png'} />{(new Date(val?.created_at)).toLocaleDateString('en-US',{ day: '2-digit', month: 'short', year: 'numeric' } )}</p>
                                                </div>
                                                <div>
                                                    <p className='diet-p'>{(val?.type).substring(0,30)}</p>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                        <div className='d-flex'>
                                            <div className='img-div'>
                                                <img className='blog-img' src={imageUrl + process.env.PUBLIC_URL + val?.image } alt=" " />
                                            </div>
                                            <div className='content my-auto'>
                                                <p className="p-content">{val?.heading}</p>
                                            </div>
                                        </div>
                                </div>
                                    
                                </div>
                            </Link>
                        </div>
                        </>
                                               );
                                           })}

                        {/* <div className='blog-card'>
                            <Link to="/blog-details">
                                <div className='row'>
                                    <div className='col-lg-8'>
                                        <div className='d-flex'>
                                            <div className='img-div'>
                                                <img className='blog-img' src={process.env.PUBLIC_URL + '/assets/images/blogs/blog1.png'} alt="blog-img
                                                " />
                                            </div>
                                            <div className='content my-auto'>
                                                <p>Healthy diet greater in people genetic risk for obesity</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className='calender-div'>
                                            <div className='d-flex justify-content-end'>
                                                <div>
                                                    <p className='date-p'><img className='calender-img' src={process.env.PUBLIC_URL + '/assets/images/icons/calender.png'} />15 Feb 2023</p>
                                                </div>
                                                <div>
                                                    <p className='diet-p'>Nutrition</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='blog-card'>
                            <Link to="/blog-details">
                                <div className='row'>
                                    <div className='col-lg-8'>
                                        <div className='d-flex'>
                                            <div className='img-div'>
                                                <img className='blog-img' src={process.env.PUBLIC_URL + '/assets/images/blogs/blog1.png'} alt="blog-img
                                                " />
                                            </div>
                                            <div className='content my-auto'>
                                                <p>Healthy diet greater in people genetic risk for obesity</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className='calender-div'>
                                            <div className='d-flex justify-content-end'>
                                                <div>
                                                    <p className='date-p'><img className='calender-img' src={process.env.PUBLIC_URL + '/assets/images/icons/calender.png'} />15 Feb 2023</p>
                                                </div>
                                                <div>
                                                    <p className='diet-p'>Nutrition</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='blog-card'>
                            <Link to="/blog-details">
                                <div className='row'>
                                    <div className='col-lg-8'>
                                        <div className='d-flex'>
                                            <div className='img-div'>
                                                <img className='blog-img' src={process.env.PUBLIC_URL + '/assets/images/blogs/blog1.png'} alt="blog-img
                                                " />
                                            </div>
                                            <div className='content my-auto'>
                                                <p>Healthy diet greater in people genetic risk for obesity</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className='calender-div'>
                                            <div className='d-flex justify-content-end'>
                                                <div>
                                                    <p className='date-p'><img className='calender-img' src={process.env.PUBLIC_URL + '/assets/images/icons/calender.png'} />15 Feb 2023</p>
                                                </div>
                                                <div>
                                                    <p className='diet-p'>Nutrition</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div> */}

                    </div>
                </section>
      )}
                <Add />
            </div>
        </section>

        
    </>
  )
}
export default Blog;
