import React from "react";
import "./Contact.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
// import logo_img from "../../public/assets/images/logo/Fizo.png"
// import { Facebook, Twitter, Linkedin, Whatsapp } from 'bootstrap-icons-react';
const Contact = () => {
  return (
    <>
      <Header />
      <section className="Contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-left">


                <div className="content p-4">

               
                <div className="text-center ">
                  <img
                    className="head-logo"
                    src={process.env.PUBLIC_URL + "assets/images/logo/Fizo.png"}
                    alt="logo"
                  />
                </div>

                <h4 className="heading text-center">
                  You can contact directly at :
                </h4>
                <p className="text-center mt-2">contact@gmail.com</p>
                <p className="text-center para_contact">
                  Or you write us via the contact form, <br />
                  we answer as quickly as possible.
                </p>

              

<div className="d-flex justify-content-center">
              <Link
                to="https://www.linkedin.com/in/diegoarbito"
                title="LinkedIn"
                className="social-link me-3 mb-3"
              >
                <img
                  src="https://res.cloudinary.com/diegoarbito/image/upload/v1469744659/Linkedin_Icon_jfuwkc.png"
                  alt="LinkedIn"
                  className="social-icon"
                />
              </Link>
              <Link
                to="https://twitter.com/diegoarbito"
                title="Twitter"
                className="social-link me-3 mb-3"
              >
                <img
                  src="https://res.cloudinary.com/diegoarbito/image/upload/v1469744663/Twitter_enkrbf.png"
                  alt="Twitter"
                  className="social-icon"
                />
              </Link>
              <Link
                to="https://facebook.com"
                title="Facebook"
                className="social-link me-3 mb-3"
              >
                <img
                  src="https://res.cloudinary.com/diegoarbito/image/upload/v1469744665/facebook_bqjnna.png"
                  alt="Facebook"
                  className="social-icon"
                />
              </Link>
              <Link
                to="https://youtube.com"
                title="YouTube"
                className="social-link mb-3"
              >
                <img
                  src="https://res.cloudinary.com/diegoarbito/image/upload/v1469744667/Youtube_jdupgi.png"
                  alt="YouTube"
                  className="social-icon"
                />
              </Link>
            </div>
              </div>
              </div>
            </div>

            {/*<div className="col-lg-6">
              <div className="contact-container">
                <h1 className="contact-heading">Contact Us</h1>
                <form className="contact-form">
                  <div className="input-group">
                  
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                    />
                  </div>

                  <div className="input-group">
                   
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                    />
                  </div>

                  <div className="input-group">
                   
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                    />
                  </div>

                  <div className="input-group">
                    
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      placeholder="Enter the message"
                    ></textarea>
                  </div>

                  <button type="submit" className="submit-button">
                    Send Message
                  </button>
                </form>
              </div>
            </div>*/}
          </div>
        </div>
      </section>

      {/* icons */}
      <div className="icons">
        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/Contact/linkedin.png"}
            className="img-fluid mt-2"
            alt="..."
          />
        </div>

        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/Contact/twitter.png"}
            className="img-fluid mt-3"
            alt="..."
          />
        </div>

        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/Contact/insta.png"}
            className="img-fluid mt-3"
            alt="..."
          />
        </div>

        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/Contact/facebook.png"}
            className="img-fluid mt-3"
            alt="..."
          />
        </div>
        <div>
          <div class="vl"></div>
        </div>
        <div className="follow">
          <p className="us">Follow us</p>
          {/* <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/banner/follow.png"
                        }
                        className="follow mt-5"
                        alt="..."
                      /> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
