import * as React from "react";
import "./Form.css";
import { Field } from "@progress/kendo-react-form";
import { CardSelector } from "./card-selector";
import { FormInput, FormMaskedTextBox, FormDateInput } from "./form-components";
import { requiredValidator, cardValidator, cvcValidator } from "./validators";
import { getInputApi, getActivityApi,getOneUserInputApi   } from "../api/Apis";
import { useEffect } from "react";
import { useState } from "react";
const Activity = () => {
  const [activityLevel, setActivityLevel] = useState();
  const [userActivity, setUserActivity] = useState();
  // const [activityData, setActivityData] = useState();
  // const getActivity = {
  //   activity_level_id: "",
  // };
  // const [fizoUsers, setFizoUsers] = useState(getActivity);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const users = await getActivityApi(
  //     (fizoUsers.activity_level_id = activityData)
  //   );
  //   console.log(users);
  // };

  const postData = (id) => {
    sessionStorage.setItem("activity", id);
    // setActivityData(id);
    console.log("clicked");
  };

  // let activityId = activityData;
  const getData = async () => {
    await getInputApi()
      .then((res) => {
        //    console.log(res.data.data.activityLevel[0].name);
        setActivityLevel(res.data.data.activityLevel);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStat = async () => {
    await getOneUserInputApi()
      .then((res) => {
        
        if(res.data.data.list.activity_level_id != null){
        setUserActivity(res.data.data.list.activity_level_id);
       sessionStorage.setItem("activity", res.data.data.list.activity_level_id);
        }else{
          sessionStorage.setItem("activity", "0");
        }
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    getStat();
  }, []);
  return (
    <>
      <section className="main">
        <div className="container p-0">
          <form>
            <div className="row activity-top">
              <div className="col-xxl-5 col-xl-4 col-lg-4 col-md-6 my-auto">
                <div className="sec-activity">
                  <h1 className="field-title">
                    Activity<br></br> Level
                  </h1>

                  <div className="title-bd"></div>
                  <p className="substitle-p">Select your Activity Level</p>
                </div>
              </div>

              <div className="col-xxl-7 col-xl-8 col-lg-8 col-md-6">
                <div className="sec-2">

                 
                 

                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    {activityLevel?.reduce((pairs, val, i) => {
                      if (i % 2 === 0) {
                        pairs.push([val]);
                      } else {
                        pairs[pairs.length - 1].push(val);
                      }
                      return pairs;
                    }, []).map((pair, pairIndex) => (
                      <div className="row" key={pairIndex}>
                        {pair.map((val, buttonIndex) => (
                          <div className="col-lg-6 col-md-12" key={buttonIndex}>
                            <li className="nav-item" role="presentation">
                              <button
                                className={userActivity == val.id ? "nav-link active" : "nav-link"}
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => postData(val.id)}
                              >
                                <b>{(val.name).split("(")[0]}</b>
                                {"("+(val.name).split("(")[1]}
                              </button>
                            </li>
                          </div>
                        ))}
                      </div>
                    ))}
                  </ul>

                </div>
              </div>
            </div>
            {/* <button onClick={handleSubmit}>get</button> */}
          </form>
        </div>
      </section>
    </>
  );
};

export default Activity;
