import React from 'react'
import { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "../circular.css"


const SubCircular = () => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
      }
    }, 50);
  }, [percentage]);

  let tID = setTimeout(function () {
    window.location.href = 
    "/plansuccess"; //replace with your url
    window.clearTimeout(tID);// clear time out.
}, 4000);
  return (
    <>
       <div className="centered-container">
         <div className='fizo-circular mx-auto'>
         <span className='progress-span'> We Update your diet <br />
            and workout according <br />
            to the plan that you have chosen.</span>
      <div className='subprogress-div mx-auto'>
      <CircularProgressbar 
    value={percentage} 
    
    strokeWidth={5}
/>

      </div>
         </div>

       </div>
    </>
  )
}

export default SubCircular