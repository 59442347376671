import './banner/Banner.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Banner from './banner/Banner';
import About from './about/About';
import PlanBanner from './plan_banner/PlanBanner';
import DownloadApp  from './download-app/DownloadApp';
import SimplePricing  from './simple-pricing/SimplePricing';
import Review  from './review/Review';

function Home() {
  return (
    <>
      <Header/> 
      <Banner/> 
      <About/>  
      <PlanBanner/> 
      <SimplePricing/>
      <DownloadApp/>
      <Review/>
      <Footer/> 
    </>
  );
}

export default Home;
