import React from "react";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// import "./circular.css"
import animationData from "../Lottiefiles/update-diet.json";
import Lottie from "react-lottie";
import "./updatediet.css"

const UpdateDietCircular = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  let tID = setTimeout(function () {
    window.location.href = 
    "/activate-plan"; //replace with your url
    window.clearTimeout(tID);// clear time out.
}, 5000);


  return (
    <>
      <div className="update-diet mx-auto">
        <div className="diet-circular">

          <div className="lottie-div">
            <Lottie options={defaultOptions} height={400} width={400} className="lottie"/>
          </div>

          <div className="text">
            <p className="text-center">
              We update your diet & workout according to the plan that you
              choose.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateDietCircular;
