import "./About.css";
// import { Link } from 'react-router-dom';
import VideoPlay from '../../animation/video-play/VideoPlay';
import Lottie from 'react-lottie';
import { Scrollchor } from 'react-scrollchor';
import React, {useState } from "react";
import { useEffect } from "react";
import { getAboutUs } from "../../api/Apis";


function About() {
    const [about, setAboutContent] = useState();
    var HtmlToReactParser = require('html-to-react').Parser;


    const [imageBack, setImgBack] = useState()
    var htmlToReactParser = new HtmlToReactParser();
    const imageUrl = "https://api.fizo.in/";

    const getStat = async () => {
        await getAboutUs()
          .then((res) => {
            //    console.log(res.data.data,'kkkkkkkkkkkkkk');
               setAboutContent(res.data.data.about);
          })
          .catch((error) => {
            console.log(error);
          });
      };
    

    useEffect(() => {
        getStat();
      }, []);


    return (
        <section className="main">
            <div className="container-fluid p-0">
                <section className="about"  id="sample-code1">
                    <div className="row ms-0 me-0">
                        {/* <div className="col-md-9 mx-auto">
                            <Link  to="#aboutcall">
                                <div className="d-flex scrolldown-div">
                                    <div className="div1">
                                        <p className="scroll-p">Scroll down see more</p>
                                    </div>
                                    <div className="div2">
                                        <hr />
                                    </div>
                                    <div className="div3">
                                        <Scrollchor to="#sample-code1">
                                            <img className="drop-icon" src={process.env.PUBLIC_URL + "assets/images/icons/dropdown-icon.png"} />                                            
                                        </Scrollchor>
                                    </div>
                                </div>
                            </Link >
                        </div> */}
                    </div>
                    <div className="container" id="aboutcall ">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                
                                    <div className="sec-1">
                                        <div className="img-div">
                                            {/* <img className="gym-img" src={process.env.PUBLIC_URL + "assets/images/home/about/gym.png"} alt="gym" /> */}
                                            <video
                          className="gym-img"
                          src={
                            imageUrl + about?.image
                          }
                          loop
                          autoPlay
                          muted
                        />
                                            <VideoPlay/>
                                        </div>
                                    </div>
                                
                            </div>
                            <div className="col-md-6 mb-3"  >
                                <div className="sec-2">
                                <h6 className="about-title" >About Us</h6>
                                    <h1 className="subtitle">{about?.heading}</h1>
                                   <p className="about-content">{htmlToReactParser.parse(about?.description)}</p> 
                                    <div class="start-btn-div">
                                        <button className='about-btn btn'>About us <img className='next-icon' src={process.env.PUBLIC_URL + 'assets/images/icons/icon-next.svg'} alt='next' /></button>
                                        <a href="">
                                            {/* <button className='about-btn btn'><span>About US <img className='next-icon' src={process.env.PUBLIC_URL + 'assets/images/icons/icon-next.svg'} alt='next' /></span></button> */}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default About;