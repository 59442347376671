import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Header.css";
import { Link } from "react-router-dom";
import React, { useState, Component, useEffect } from "react";
import { getInfoApi,logout } from "../api/Apis";
// import React, { useState } from 'react';

function Header() {
  const [scroll, setScroll] = useState(false);
  const [user, setUser] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [infoData, setInfoData] = useState(false);

  const userLogout = async () =>{
    await logout()
    .then((res) => {
      
      
      if(res.data.success == true){
        sessionStorage.clear();
        setInfoData(false);
      }
     
    })
    .catch((error) => {
      console.log(error);
    });

    setIsClicked(true);
  };



  const getData = async () => {

      const token = sessionStorage.getItem("token");
console.log(token,'tokencheck');
if(token != null){
  setInfoData(true);
}

  };
 
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });

    getData();

  }, [infoData]);
  


  return (
    <section className="main">
      <Container fluid className="p-0">
        <section className={scroll ? "scroll-header" : "before-scroll-header"}>
          <div className="row me-0 ms-0 header">
            <div className="col-lg-9 mx-auto">
              <Navbar bg="" expand="lg">
                <Navbar.Brand to="">
                  <img
                    className="head-logo"
                    src={process.env.PUBLIC_URL + "assets/images/logo/Fizo.png"}
                    alt="logo"
                  />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto navbar-nav">
                    <div className="nav-item">
                      <Link to="/blog" className="nav-link">
                        Blogs
                      </Link>
                    </div>
                    <div className="nav-item">
                    {infoData?(
                        <Link to="/recipes" className="nav-link">
                        Recipies
                      </Link>
                      ):(
                        <Link to="/login" className="nav-link">
                        Recipies
                      </Link>
                      )}
                        

                    </div>
                    <div className="nav-item">
                      {infoData?(
                        <Link to="/calculator" className="nav-link">
                        Calculator
                      </Link>
                      ):(
                        <Link to="/login" className="nav-link">
                        Calculator
                      </Link>
                      )}
                    </div>
                    {/* <div className="nav-item">
                      <Link href="#link" className="nav-link">
                        Get in Touch
                      </Link>
                    </div> */}

                    <div className="nav-item"> 
                    {infoData?(
                      
                        <button className="login-btn btn"
                        onClick={userLogout}
                        >
                          <span style={{
                            fontSize:"16px"
                          }}>
                            Logout
                            <img
                              className="login-next-icon"
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/icon-next.svg"
                              }
                              alt="next"
                            />
                          </span>
                        </button>
                     
                    ):(
                      <Link to="/login" className="nav-link">
                        <button className="login-btn btn">
                          <span>
                            Login
                            <img
                              className="login-next-icon"
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/icon-next.svg"
                              }
                              alt="next"
                            />
                          </span>
                        </button>
                      </Link>
                    )}
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </section>
      </Container>
    </section>
  );
}

export default Header;

// import React from 'react';
// // import { Link } from 'react-router-dom';
// import "./Header.css";
// const Header = () => {

//     return (
//         <>
//             <section className='main'>
//                 <div className='container-fluid p-0'>
//                     {/* start header-section */}
//                     <section className='header'>
//                         <div className='row me-0 ms-0'>
//                             <div className='col-md-9 mx-auto'>
//                                 <nav className="navbar navbar-expand-lg">
//                                     <div className="container-fluid">
//                                         <img className='head-logo' src={process.env.PUBLIC_URL + 'assets/images/logo/Fizo.png'} alt='logo' />

//                                         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                                             <span className="navbar-toggler-icon"></span>
//                                         </button>
//                                         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//                                             <ul className="navbar-nav">
//                                                 <li className="nav-item">
//                                                     <a className="nav-link active" aria-current="page" href="#">Blogs</a>
//                                                 </li>
//                                                 <li className="nav-item">
//                                                     {/* <Link to="/" className="nav-link">Blogs</Link> */}
//                                                     <a className="nav-link" href="#">Recipies</a>
//                                                 </li>
//                                                 <li className="nav-item">
//                                                     <a className="nav-link" href="#">Calculator</a>
//                                                 </li>
//                                                 <li className="nav-item">
//                                                     <a className="nav-link" href="#">Get in Touch</a>
//                                                 </li>
//                                                 <li className="nav-item">
//                                                     <button className='login-btn btn'><span>Login <img className='login-next-icon' src={process.env.PUBLIC_URL + 'assets/images/icons/icon-next.svg'} alt='next' /></span></button>
//                                                 </li>
//                                             </ul>
//                                         </div>
//                                     </div>
//                                 </nav>
//                             </div>
//                         </div>
//                     </section>
//                     {/* start header-section */}
//                 </div>
//             </section>
//         </>

//     );
// }

// export default Header;
