import React from "react";
import axiosClient from "../api/ApiInstance";
import "./newpass.css";
import { Link, useNavigate} from "react-router-dom";
import eyesicon from "./sign-up-images/eyes-icon.png";
import { newpassword } from "../api/Apis";
import { useState } from "react";
const Newpass = () => {
  let navigate = useNavigate();
  const mobile = sessionStorage.getItem('mobileOTP');
  const passwordApi = {
    mobile_number: "",
    new_password: "",
    c_password: "",
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [fizoUsers, setFizoUsers] = useState(passwordApi);
  const [noSubs, setNoSubs] = useState(true);
  const [errors, setErrors] = useState(false);
  const [errMsg, setErrMsg] = useState({
    errors: [],
    isError: false
  });
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFizoUsers({ ...fizoUsers, [name]: value });
    console.log(fizoUsers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const users = await newpassword(
    //   num,
    //   fizoUsers.new_password,
    //   fizoUsers.c_password
    // );
    // console.log(users);
    // if (fizoUsers.new_password !== fizoUsers.c_password) {
    //   alert("Password does not match");
    // } else {
    //   alert("Your password has been changed successfully");
    // }
    axiosClient
      .post("/new-password", {
        mobile_number: mobile,
        new_password: fizoUsers.new_password,
        c_password: fizoUsers.c_password,
      })
      .then(res => {
        console.log(res.data);
        if (res.status === 200) {
          setNoSubs(false);
          setTimeout(
            function() {
              navigate("/login");
            },
            3000
        );
          
        }
      })
      .catch(error => {
        console.log(error.response);
        setErrMsg({
          errors: error.response.data,
          isError: true,
        });
        if (error) {
          setShow(true);
        }
      })
  };
  // if (fizoUsers.username === "") {
  //   //   setUsernameErr(true);
  // } else if (fizoUsers.password === "") {
  //   //   setPswrdErr(true);
  // } else {
  //   //  navigate("/");
  // }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    console.log("clicked");
  };
  const togglePassword2=()=>{
    setPasswordShown2(!passwordShown2);
    console.log("clicked");
  }
  return (
    <>
      <section className="new-password">
        <div className="container">
          <div className="row background-color">
            <div className="col-md-12 mt-5">
              <div className="heading-holder text-center">
                <h2>fizo</h2>
              </div>
            </div>
            <div className="col-lg-5 col-md-7 col-12 mt-4 mx-auto">
              <div className="text-holder mb-4 text-center">
                <h3>Forgot Password</h3>
                <p>Please type your new password below</p>
              </div>
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-sm-12 mb-2">
                  <div className="input-group mb-3">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control"
                      placeholder="Enter new password"
                      aria-label="new_password"
                      aria-describedby="new_password"
                      name="new_password"
                      value={fizoUsers.new_password}
                      onChange={handleChange}
                    />
                    <span className="input-group-text" onClick={togglePassword}>
                      <img src={eyesicon}  />
                    </span>
                  </div>
                  {show && errMsg.errors.data.new_password ? <p className="errMsg">{errMsg.errors.data.new_password}</p> : ''}
                </div>
                <div className="col-sm-12 mb-2">
                  <div className="input-group mb-3">
                    <input
                      type={passwordShown2 ? "text" : "password"}
                      className="form-control"
                      placeholder="Re-Enter new password"
                      aria-label="c_password"
                      aria-describedby="c_password"
                      name="c_password"
                      value={fizoUsers.c_password}
                      onChange={handleChange}
                    />
                     <span className="input-group-text" onClick={togglePassword2}>
                      <img src={eyesicon} />
                    </span>
                  </div>
                  {show && errMsg.errors.data.c_password ? <p className="errMsg">{errMsg.errors.data.c_password}</p> : ''}
                </div>
                <div className="col-12 mt-5">
                  {/* <Link to="/forget-password1"> */}
                  <button type="Login" className="btn btn-white"
                  >
                    Submit
                  </button>
                  {/* </Link> */}
                </div>
              </form>

              <div className="addmeal-modal">
                <div
                  class={noSubs?"modal fade":"modal fade show"}
                  id="AddMeal"
                  style={{
                    display:noSubs?"none":"block"
                   }}
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">
                          Password Changed Successfully...!
                        </h1>
                        {/* <p className="cal-no">480cal</p> */}
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Newpass;
