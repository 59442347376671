import React from "react";
import axiosClient from "../api/ApiInstance.js";
import { Rings } from 'react-loader-spinner';
import "./ExerciseDay.css";
import Add from "../add/Add";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from "swiper";
import TopNav from "../top-nav/TopNav";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getWorkOut, getDietWeekApi, getWorkProgress, getSubCheck } from "../api/Apis";

export const ExerciseDay = () => {
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(true);
  const [weeks, setWeek] = useState();
  const [subcheck, setSubCheck] = useState(true);
  const [assignedWork, setAssignedWork] = useState(false);
  const [currentWeek, setCurrentWeek] = useState();
  const [currentDay, setCurrentDay] = useState();
  const [executeCount, setExecuteCount] = useState(false);
  const [executeWorkCount, setExecuteWorkCount] = useState(false);
  const [work, setWork] = useState();
  const [caloriesLeft, setCaloriesLeft] = useState();
  const [today, setToday] = useState();
  const [userWorkSelect, setWorkSelect] = useState();
  const [workPercent, setWorkPercent] = useState(0);
  const [errors, setErrors] = useState(false);
  const [errMsg, setErrMsg] = useState({
    errors: [],
    isError: false,
  });
  const [show, setShow] = useState(false);

  const imageUrl = " https://api.fizo.in/";

  const getUserSubscription = async () => {
    await getSubCheck()
      .then((res) => {
        console.log(res.data.data.subs.subscription, 'manage')
        if (res.data.data.subs.subscription == 'unpaid') {
          setSubCheck(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postWork = async (e) => {
    console.log(e.target.name + currentWeek, 'workcheck');

    axiosClient
      .post("/work-out", {
        day_id: e.target.name,
        week_id: currentWeek,
      })
      .then((res) => {
        console.log(res.data, "added meal");
        getDietWeekDays();
        getProgress();
      })
      .catch((error) => {
        console.log(error.response);
        setErrMsg({
          errors: error.response.data,
          isError: true,
        });
        if (error) {
          setShow(true);
        }
      });
  };
  const getDietWeek = async () => {
    await getDietWeekApi()
      .then((res) => {
        // console.log(res.data.data,'ongoind')
        setCurrentWeek(res.data.data.ongoingWeek);
        setCurrentDay(res.data.data.currentDay)
        setExecuteCount(true);

      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDietWeekDays = async () => {
    await getWorkOut(currentWeek)
      .then((res) => {

        console.log(currentWeek, "week");
        console.log(res.data.data, "execute");

        if (res.data.data.assigned == true) {
          setAssignedWork(true);
        } else {
          setAssignedWork(false);
        }

        if (res.data.data.workout_acc_week) {
          setCaloriesLeft(res.data.data.workout_acc_week);
        } else {
          setCaloriesLeft(res.data.data.workout_package);
        }
        setWorkSelect(res.data.data.workDone_by_user);

        let totalWeek = res.data.data.totalWeeks;
        let weeks = [];
        for (let i = 0; i < totalWeek; i++) {
          weeks[i] = i + 1;
        }
        setWeek(weeks);
        if (res.data.data.selectedWeek) {
          setCurrentWeek(res.data.data.selectedWeek);
        } else {
          setCurrentWeek(res.data.data.ongoingWeek);
        }

        setExecuteWorkCount(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProgress = async () => {
    await getWorkProgress(currentWeek)
      .then((res) => {

        console.log(res.data.data.percentage, "progress");
        setWorkPercent(Math.round(res.data.data.percentage));

      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleDay = (week) => {
    setCurrentWeek(week);
    setExecuteWorkCount(false);
  };

  useEffect(() => {
    if (executeCount == false) {
      getDietWeek();
    }
    if (executeWorkCount == false) {
      getDietWeekDays();
    }

    getProgress();
    getUserSubscription();
  }, [currentWeek, workPercent]);


  return (
    <>
      <section className="main">
        <TopNav />
        <div className="container-fluid p-0">
          <section
            className={subcheck ? "exerciseday-section " : "exerciseday-section exerciseday-section-blur"}
          >
            <div className="exerciseday-tab-section pt-5">
              <div className="container">
                <div className="title-div">
                  <h6 className="title">Exercise</h6>
                </div>

                <div className="tabs-div mt-4">

                  <div className="row">
                    <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-8 col-xl-6 mx-auto">
                      <ul
                        className="nav nav-pills mb-3 justify-content-center"
                        id="pills-tab"
                        role="tablist"
                      >
                        <Swiper
                          spaceBetween={10}

                          breakpoints={{
                            0: {
                              slidesPerView: 2,
                            },
                            320: {
                              slidesPerView: 3,
                            },
                            640: {
                              slidesPerView: 4,
                            },
                            768: {
                              slidesPerView: 4,
                            },
                            1024: {
                              slidesPerView: 4,

                            },
                            1200: {
                              slidesPerView: 4,
                            },
                            1400: {
                              slidesPerView: 4,
                            },
                          }}
                          centeredSlides={false}

                          pagination={{
                            clickable: true,
                          }}
                          loop={true}
                          // navigation={true}
                          modules={[Autoplay]}
                          className="mySwiper"
                        >
                          {weeks?.map((val) => (
                            <SwiperSlide>
                              <li class="nav-item" role="presentation">
                                <button
                                  class={val == currentWeek ? "nav-link active" : "nav-link"}
                                  id="pills-week1-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-week1"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-week1"
                                  aria-selected="true"
                                  onClick={() => {
                                    handleDay(val);
                                  }}
                                >
                                  Week {val}
                                </button>
                              </li>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </ul>
                    </div>
                  </div>

                  {loading ? (
                    <Rings color="#00BFFF" height={80} width={80} />
                  ) : (
                    <div>
                      {assignedWork ? (
                        <div class="tab-content pt-3" id="pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="pills-week1"
                            role="tabpanel"
                            aria-labelledby="pills-week1-tab"
                            tabindex="0"
                          >
                            <div className="tab-data">
                              <p className="work-progress-p">
                                Work progress : {workPercent}% Completed
                              </p>

                              <div
                                class="progress"
                                role="progressbar"
                                aria-label="Success example"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div class="progress-bar"
                                  style={{
                                    width: workPercent + "%"
                                  }}
                                ></div>
                              </div>

                              <h6 className="hooray-P">
                                {workPercent == 14 ? <span style={{ color: "red" }}>Yeah!...You Started with todays workout </span> : ''}
                                {workPercent == 29 ? <span style={{ color: "red" }}>You Need To Work Out More to your target</span> : ''}
                                {workPercent == 43 ? <span style={{ color: "orange" }}>Come On You can do it!</span> : ''}
                                {workPercent == 57 ? <span style={{ color: "orange" }}> Congratulations...! For Weeks half century</span> : ''}
                                {workPercent == 71 ? <span style={{ color: "blue" }}>Come On You can do it!</span> : ''}
                                {workPercent == 86 ? <span style={{ color: "blue" }}>Hooray...! You are close enough to your target</span> : ''}
                                {workPercent == 100 ? <span style={{ color: "green" }}>Work Out Completed...!</span> : ''}

                              </h6>
                              {caloriesLeft?.map((val, index) => (
                                <div key={index}>
                                  {val?.exe_packagess_one?.map((value) => (
                                    <div className="tab-body" key={value.day_id}>
                                      <div className="day-card">
                                        <div className="row">
                                          <div className="col-10">
                                            <Link
                                              to={`/exercise/${value.main_workout_name}/${value.day_id}/${value.workout_pack_id}/${value.week_id}`}
                                            >
                                              <h1 className="dayh1">
                                                Day {value.day_id}
                                              </h1>
                                              <p className="work-namep">
                                                {value.main_workout_name} | {value.reps}{" "}
                                                reps
                                              </p>
                                            </Link>
                                          </div>
                                          <div className="col-2 my-auto">
                                            <div className="align-input">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                name={value?.day_id}
                                                id={`flexCheckChecked_${value.day_id}`}
                                                onClick={postWork}
                                                checked={
                                                  userWorkSelect.some(
                                                    (cred) =>
                                                      cred.day_id ==
                                                      value?.day_id &&
                                                      cred.week_id == value?.week_id
                                                  ) == true
                                                    ? true
                                                    : false
                                                }
                                                disabled={
                                                  userWorkSelect.some(
                                                    (cred) =>
                                                      cred.day_id ==
                                                      value?.day_id &&
                                                      cred.week_id == value?.week_id
                                                  ) == true || currentDay != value?.day_id
                                                    ? true : false
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col-md-6 mx-auto mt-5">
                              <div className="card ">
                                <div className="card-body">

                                  <h3 className="noAssigned">No Workout Assigned Yet..!</h3>
                                  <p className="noAssigned">You can refer to the support for more info</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}


                </div>

                <Add />
              </div>
            </div>
          </section>
          <div className="subcription-modal">
            <div
              class={subcheck ? "modal fade" : "modal fade show"}
              id="subsModal"
              style={{
                display: subcheck ? "none" : "block"
              }}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      Get Premium to access all the features.
                    </h1>
                    {/* <p className="cal-no">480cal</p> */}

                  </div>
                  <div class="modal-body">
                    <div className="text-center">
                      <Link to={"/choose-plan"}>
                        <button
                          className="add-btn btn btn-info"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Go Premium!
                        </button>
                      </Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default ExerciseDay;
