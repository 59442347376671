import React from "react";
import "./Exercise.css";
import axiosClient from "../api/ApiInstance.js";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getWorkDay, getDietWeekApi } from "../api/Apis";

export const Exercise = () => {
  const [response, setResponse] = useState();
  const [formData1, setFormData1] = useState({
    plan: "",
  });
  const [workoutday, setExeday] = useState();
  const [currentWeek, setCurrentWeek] = useState();
  const [currentDay, setCurrentDay] = useState();
  const [completeStat, setCompleteStat] = useState(false);
  const imageUrl = " https://api.fizo.in/";

  const [errors, setErrors] = useState(false);
  const [errMsg, setErrMsg] = useState({
    errors: [],
    isError: false,
  });
  const [show, setShow] = useState(false);

  const { name, id, wk, we } = useParams();

  const getDietWeek = async () => {
    await getDietWeekApi()
      .then((res) => {
        setCurrentWeek(res.data.data.ongoingWeek);
        setCurrentDay(res.data.data.currentDay)

      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postWork = async (e) => {
    console.log(id +  we,'workcheck');
        
        axiosClient
          .post("/work-out", {
            day_id: id,
            week_id:  we,
          })
          .then((res) => {
            console.log(res.data ,"added meal");
            setCompleteStat(true);
          })
          .catch((error) => {
            console.log(error.response);
            setErrMsg({
              errors: error.response.data,
              isError: true,
            });
            if (error) {
              setShow(true);
            }
          });
      };

  const getStatiday = async () => {
    await getWorkDay(id, wk, we)
      .then((res) => {
           console.log(res.data.data,"eeeeeeeeeeeeeeeeee");

        setExeday(res.data.data.workout_per_day);
        if(res.data.data.workStat == true){
          setCompleteStat(true);
        }else{
          setCompleteStat(false);
        }
     
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDietWeek();
    getStatiday();

    // openRazorpayWindow();
  }, []);

  return (
    <>
      <section className="main">
        <div className="container-fluid p-0">
          <TopNav />
          <section className="exercise">
            <div className="container">
              <Link to="/exercise-day">
                <div className="back-btn-div">
                  <img
                    className="back-btn"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/back.png"
                    }
                    alt=""
                  />
                </div>
              </Link>
              <div className="exercise-title-div text-center">
                <h6 className="title">
                  <span className="span-p">Week {we} / Day {id} /</span>{name}
                </h6>
                <p className="subtitle">
                  Perform each exercise till failure.Maintain good form. Take 30
                  to 40sec rest after each set.
                </p>
              </div>

              <div className="workout-div">
                {workoutday?.map((val) => {
                  return (
                    <>
                      <div className="workout-card" key={val.workout_data.id}>
                        <Link
                          to={`/exercise-details/${name}/${val.day_id}/${val.workout_pack_id}/${val.week_id}/${val.id}`}
                        >
                          <div className="row">
                            <div className="col-md-10 col-10">
                              <div className="d-flex">
                                <div className="img-div">
                                  <img
                                    className="img"
                                    src={
                                      imageUrl +
                                      process.env.PUBLIC_URL +
                                      val.workout_data.image
                                    }
                                    alt=""
                                  />
                                </div>

                                <div className="content  my-auto ms-3">
                                  <h6>{val.workout_data.name}</h6>
                                  <p>
                                    Sets: {val.workout_data.sets} | Reps:
                                    {val.workout_data.reps}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-2  my-auto ">
                              <div className="icon text-end">
                                <img
                                  className="img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/card-next.png"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </>
                  );
                })}

               

                <div className="text-center">
                  <button 
                  className={completeStat?"completed-btn btn-success":"completed-btn btn-info"}
                  onClick={postWork}
                  disabled={
                    completeStat || currentDay!=id?true:false
                  }
                  >{completeStat?'Completed':'Complete'}</button>
                </div>
              </div>
            </div>
          </section>

          <Add />
        </div>
      </section>
    </>
  );
};
export default Exercise;
