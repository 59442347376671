import React from "react";
import "./App.css";
import "./index.css";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import {BrowserRouter as Router , Route , Link} from 'react-router-dom';

import { Route, Routes } from "react-router-dom";

import Home from "./home/Home";
import Recipes from "./recipes/Recipes";
import MyInfo from "./my-info/MyInfo";
import MyInfoEdit from "./my-info-edit/MyInfoEdit";
import Blog from "./blog/Blog";
import BlogDetails from "./blog-details/BlogDetails";
import RecipesDetails from "./recipes-details/RecipesDetails";
import Exercise from "./exercise/Exercise";
import ExerciseDetails from "./exercise-details/ExerciseDetails";
import ManageSubscription from "./manage-subscription/ManageSubscription";
import Calculator from "./calculator/Calculator";
import CalculatorDetails from "./calculator-details/CalculatorDetails";
import Nutrition from "./nutrition/Nutrition";
import MoreDetails from "./more-details/MoreDetails";
import ExerciseDay from "./exercise-day/ExerciseDay";
import Reminder from "./reminder/Reminder";
import ChoosePlan from "./choose-plan/ChoosePlan";
import InviteFriend from "./invite-friend/InviteFriend";
import MyReports from "./my-reports/MyReports";
import Bmr from "./bmr/Bmr";
import Tee from "./tee/Tee";
import Bmi from "./bmi/Bmi";
import Nutrihealthplus from "./nutrihealthplus/Nutrihealthplus";
import BodyAnalysis from "./body-analysis/BodyAnalysis";
import Progress from "./progress/Progress";
import SleepScore from "./sleep-score/SleepScore";
import WaterIntake from "./water-intake/WaterIntake";
import HamburgerProgress from "./hamburger-progress/HamburgerProgress";
import Chatbot from "./chatbot/Chatbot";
import Login from "./login/Login";
import Forgotpass from "./forgot-password/Forgotpass";
import Newpass from "./new-password/Newpass";
import Otpverification from "./otp-verification/Otpverification";
import Signup from "./sign-up/Signup";
import Stepform from "./step-form/Form";
import Slider from "./vertical-slider/Slider";
import Autocompletes from "./react-autocomplete/Autocomplete";
import Circular from "./circular-progress/Circular";
import SubCircular from "./circular-progress/sub-progress/SubCircular";
import PlanSuccess from "./circular-progress/plan-success/PlanSuccess";
import Bodyanalysiscircular from "./body-analysis-circular/BodyAnalysis";
import UpdateDietCircular from "./UpdateDiet/UpdateDietCircular";
import ActivatePlan from "./activated-plan/ActivatePlan";
import HorizontalWeight from "./Horizontal Slider/HorizontalWeight";
import Privacy from "./Privacy/Privacy";
import Contact from "./Contact/Contact";
import Terms from "./Terms&Condition/Terms";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/recipes" element={<Recipes />} />
        <Route path="/my-info" element={<MyInfo />} />
        <Route path="/my-info-edit" element={<MyInfoEdit />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details/:id" element={<BlogDetails />} />
        <Route path="/recipe-details/:id" element={<RecipesDetails />} />
        <Route path="/exercise/:name/:id/:wk/:we" element={<Exercise />} />
        <Route
          path="/exercise-details/:name/:id/:wk/:we/:ids"
          element={<ExerciseDetails />}
        />
        <Route path="/manage-subscription" element={<ManageSubscription />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/calculator-details" element={<CalculatorDetails />} />
        <Route path="/nutrition" element={<Nutrition />} />
        <Route path="/more-details/:week/:day" element={<MoreDetails />} />
        <Route path="/exercise-day/" element={<ExerciseDay />} />
        <Route path="/reminder" element={<Reminder />} />
        <Route path="/choose-plan" element={<ChoosePlan />} />
        <Route path="/invite-friend" element={<InviteFriend />} />
        <Route path="/my-reports" element={<MyReports />} />
        <Route path="/bmr" element={<Bmr />} />
        <Route path="/tee" element={<Tee />} />
        <Route path="/bmi" element={<Bmi />} />
        <Route path="/nutrihealth" element={<Nutrihealthplus />} />
        <Route path="/body-analysis" element={<BodyAnalysis />} />
        <Route path="/progress" element={<Progress />} />
        <Route path="/sleep-score" element={<SleepScore />} />
        <Route path="/water-intake" element={<WaterIntake />} />
        <Route path="/hamburger-progress" element={<HamburgerProgress />} />
        <Route path="/chat" element={<Chatbot />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<Forgotpass />} />
        <Route path="/reset-password" element={<Newpass />} />
        <Route path="/otp-verification" element={<Otpverification />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/form" element={<Stepform />} />
        {/* <Route path ="/slider" element={<Slider/>} /> */}
        <Route path="/auto" element={<Autocompletes />} />
        <Route path="/circular" element={<Circular />} />
        <Route path="/subcircular" element={<SubCircular />} />
        <Route path="/plansuccess" element={<PlanSuccess />} />
        <Route
          path="/bodyanalysiscircular"
          element={<Bodyanalysiscircular />}
        />
        <Route path="/bodyupdate" element={<UpdateDietCircular />} />
        <Route path="/activate-plan" element={<ActivatePlan />} />
        <Route path="/update-diet" element={<UpdateDietCircular />} />
        <Route path="/horizontal" element={<HorizontalWeight />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </>
  );
}

export default App;
