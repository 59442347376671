import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import './Review.css';

import { Rings } from 'react-loader-spinner';
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useEffect } from "react";
import { getReviews} from "../../api/Apis";

export const Review = () => {


    const [loading, setLoading] = useState(true);
    const [reviews, setReview] = useState();
    const imageUrl = "https://api.fizo.in/";
    const getReview = async () => {
        await getReviews()
          .then((res) => {
            console.log(res.data.data.reviews,'reviews');
            setReview(res.data.data.reviews)
           
        setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      };

    useEffect(() => {
        getReview();
        
        // openRazorpayWindow();
    }, []);
    return (
        <>
            <section className='main'>
                <div className='container'>
                    <section className='home-review'>
                        <h6 className='title'>Our Reviews</h6>
                        <h3 class="subtitle">What Our Clients Say</h3>

                        {loading ? (
       <Rings color="#00BFFF" height={80} width={80} />
      ) : (
                        <div className='client-testimonials-div'>
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={30}

                                navigation={true}
                                modules={[Navigation]}


                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    575: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                }}

                                className="client-testimonials-slider"
                            >
                                {reviews?.map((val)=>(
                                    <SwiperSlide>
                                    <div className="card">
                                        <img className="client-img" src={imageUrl + "public/" + val?.pic} />

                                        <h1 className="quote">''</h1>
                                        <p className="client-text">{val?.description}</p>

                                        <h6 className="client-name">{val?.user_name}</h6>
                                        <p className="position">{val?.designation}</p>
                                    </div>
                                </SwiperSlide>
                                ))}
                                {/* <SwiperSlide>
                                    <div className="card">
                                        <img className="client-img" src={imageUrl + 'assets/images/home/client-review/client1.png'} />

                                        <h1 className="quote">''</h1>
                                        <p className="client-text">I would always recommend Gimmer for reaching your fitness goals. I also referred him to various friends and work colleagues for training.</p>

                                        <h6 className="client-name">Joan Thompson</h6>
                                        <p className="position">Athletic Trainers</p>
                                    </div>
                                </SwiperSlide> */}



                            </Swiper>
                        </div>
      )}
                    </section>


                </div>
            </section>
        </>
    )
}
export default Review;
