import React, { useEffect } from "react";
import "./MyInfo.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { Link } from "react-router-dom";
import { getProfilePic } from "../api/Apis";
import { useState } from "react";
import { getInfoApi,getInputApi } from "../api/Apis";

export const MyInfo = () => {
  const [data, setData] = useState();
  const [foodData, setFoodData] = useState();
  const [userfoodData, setUserFoodData] = useState();

  const getData = async () => {
    await getInfoApi()
      .then((res) => {
        console.log(res.data,'lllllltrylllllll');
        setData(res.data.data.user);
        setUserFoodData(res.data.data.userInput);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStats = async () => {
    await getInputApi()
      .then((res) => {
        
        setFoodData(res.data.data.foodPerfrence);
        // console.log(res.data.data.foodPerfrence,"eeeeeeeeeeeeeee");
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    getStats();
  }, []);




  const imageUrl = "https://fizoadmin.profcymadev.in/";
  return (
    <>
      <section className="main">
        <div className="container-fluid p-0">
          <TopNav />
          <section className="my-info-form">
            <div className="row me-0 ms-0">
              <div className="col-xl-4 col-lg-6 col-md-8 col-10 mx-auto">
                <div className="form-section">
                  <form className="">
                    <div className="profile-div">
                      <div className="profile-img-div">
                        <img
                          className="profile-img"
                          src={data?.profile_pic == null?process.env.PUBLIC_URL + "/assets/images/icons/dummy-user.png"
                          : imageUrl + data?.profile_pic}
                          // src={file}
                        />
                        <form enctype="multipart/form-data">
                          <div>
                            <div className="mb-1"></div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div>
                      <Link className="link-p" to="/my-info-edit">
                        Edit
                      </Link>
                    </div>

                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        disabled
                        value={data?.name}
                      />
                    </div>

                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        disabled
                        value={data?.email}
                      />
                      <div className="overlay">
                        <img
                          className="yes-icon mt-3"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/yes.png"
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Mobile</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        value={data?.mobile_number}
                      />
                      <div className="overlay">
                        <img
                          className="yes-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/yes.png"
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Gender</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        value={data?.user_input.gender}
                      />
                    </div>

                    <div className="form-group">
                      <label>Height</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        value={data?.user_input.height?.name}
                      />
                      <div className="overlay">
                        <div className="d-flex">
                          {data?.user_input?.height?.height_id === 1 ? (
                            <button className="measure-p btn">Cm</button>
                          ) : (
                            <button className="measure-p2 btn">Ft</button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Weight</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        value={data?.user_input?.weight.name}
                      />
                      <div className="overlay">
                        <div className="d-flex">
                          {data?.user_input?.weight?.weight_id === 1 ? (
                            <button className="measure-p btn">Kg</button>
                          ) : (
                            <button className="measure-p2 btn">Pound</button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Age</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                        value={data?.user_input.age_id}
                      />
                    </div>

                    <div className="form-group">
                      <label>Food Preference</label>
                      <input
                        className="form-control"
                        type=""
                        name=""
                        disabled
                         value={userfoodData?.food_perf?.name}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          <Add />
        </div>
      </section>
    </>
  );
};
export default MyInfo;
