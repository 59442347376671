import React from 'react'
import './Calculator.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link, useNavigate } from 'react-router-dom';
import { getInputApi, getActivityApi,getOneUserInputApi   } from "../api/Apis";
import { useEffect } from "react";
import { useState } from "react";

export const Calculator = () => {
    let navigate = useNavigate();
const [height, setHeight] = useState();
const [weight, setWeight] = useState();
const [age, setAge] = useState();
const [gender, setGender] = useState(true);
const [activity, setActivity] = useState(1);

    const getStat = async () => {
        await getInputApi()
          .then((res) => {
            
            // setUserActivity(res.data.data.list.activity_level_id);
            setHeight(res.data.data.heightcm);
            setWeight(res.data.data.weightkg);
            setAge(res.data.data.age);
            // console.log(res.data.data.heightcm,"wwwwwwwwwwwwwwwww");
            // setPlan(res.data.data.package);
           
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const postData = (id) => {
        sessionStorage.setItem("gender", id);
        if(gender == true){
            setGender(false);
        }else{
            setGender(true);
        }
      };

      const postActivity = (id) => {
        sessionStorage.setItem("activity", id);
      
        setActivity(id);
      };

      const postReps = (e) => {
        sessionStorage.setItem("reps", e.target.value);
      };
      const postFat = (e) => {
        sessionStorage.setItem("fat", e.target.value);
      };

      const handleChange = (e) => {
        if (e.target.id === "age") {
        //   console.log("age :- ", e.target.value);
          sessionStorage.setItem("age", e.target.value);
        } if (e.target.id === "hei") {
        //   console.log("hei :- ", e.target.value);
          sessionStorage.setItem("height", e.target.value);
        }if (e.target.id === "wei") {
            // console.log("wei :- ", e.target.value);
            sessionStorage.setItem("weight", e.target.value);
          }if (e.target.id === "strwei") {
            // console.log("wei :- ", e.target.value);
            sessionStorage.setItem("strweight", e.target.value);
          }if (e.target.id === "curwei") {
            // console.log("wei :- ", e.target.value);
            sessionStorage.setItem("curweight", e.target.value);
          }

      };

      const handleNavigation = (e) => {
    //    console.log("link Click");
    if (e.target.id === "bmr") {
          sessionStorage.setItem("calType", "1");
        } if (e.target.id === "bmi") {
          sessionStorage.setItem("calType", "2");
        }if (e.target.id === "tee") {
            sessionStorage.setItem("calType", "3");
          }
          if (e.target.id === "rm") {
            sessionStorage.setItem("calType", "4");
          }
          if (e.target.id === "lean") {
            sessionStorage.setItem("calType", "5");
          }
          if (e.target.id === "protien") {
            sessionStorage.setItem("calType", "6");
          }
          if (e.target.id === "weightloss") {
            sessionStorage.setItem("calType", "7");
          }
      };

      const clearForm = (event) => {
        document.getElementById("form"+event).reset();
      };

    useEffect(() => {
        getStat();
      }, []);
    return (
        <>
            <section className='main'>
                <div className='container-fluid p-0'>
                    <TopNav />
                    <section className='calculator'>
                        <div className='container'>
                            <div className='calculator-title-div text-center'>
                                <h6 className='title'>Calculator</h6>
                            </div>
                            <Link to="/progress">
                            <div className='back-btn-div'>
                                <img className='back-btn' src={process.env.PUBLIC_URL + '../assets/images/icons/back.png'} alt="" />
                            </div> 
                            </Link>

                            <div className='calculator-accordion-sec'>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button first-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                BMR Calculator
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <form className='' id="form1">
                                                    <div className='form-group'>
                                                        <button 
                                                        className={gender?"female-btn btn":"male-btn btn"}
                                                        type="button"
                                                        onClick={() => postData("male")}
                                                        >Male</button>
                                                        <button 
                                                        className={gender?"male-btn btn":"female-btn btn"}
                                                         type="button"
                                                         onClick={() => postData("female")}
                                                        >Female</button>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select className="form-select" 
                                                        id="age" 
                                                        aria-label="Default select example" 
                                                        onChange={handleChange}>
                                                             <option selected>Select age</option>
                                                            {age?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))} 
                                                        </select> */}
                                                        <input type='number'
                                                        id="age" 
                                                        onChange={handleChange}
                                                         placeholder='Age' className='AgeInput'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select class="form-select"
                                                         aria-label="Default select example"
                                                         id="hei"
                                                         onChange={handleChange}
                                                         >
                                                            <option selected>Height (cm)</option>
                                                            {height?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number' 
                                                         id="hei"
                                                         onChange={handleChange}
                                                         placeholder='Height' className='AgeInput'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select class="form-select"
                                                         aria-label="Default select example"
                                                         id="wei"
                                                         onChange={handleChange}
                                                         >
                                                            <option selected>Weight (kg)</option>
                                                            {weight?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number' 
                                                          id="wei"
                                                          onChange={handleChange}
                                                         placeholder='Weight' className='AgeInput'/>
                                                    </div>
                                                    <div className='submit-btn-align'>
                                                        <Link to="/calculator-details">
                                                            <button className='btn calculate-btn' id="bmr" onClick={handleNavigation}>Calculate</button>
                                                        </Link>

                                                        <button className='btn clear-btn'
                                                        type="button"
                                                        onClick={() => clearForm(1)}
                                                        >Clear</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button second-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                TEE Calculator
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <form className='' id="form2">
                                                <div className='form-group'>
                                                        <button 
                                                        className={gender?"female-btn btn":"male-btn btn"}
                                                        type="button"
                                                        onClick={() => postData("male")}
                                                        >Male</button>
                                                        <button 
                                                        className={gender?"male-btn btn":"female-btn btn"}
                                                         type="button"
                                                         onClick={() => postData("female")}
                                                        >Female</button>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select class="form-select" aria-label="Default select example">
                                                            <option selected>Select age</option>
                                                            {age?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                         id="age" 
                                                         onChange={handleChange}
                                                          placeholder='Age' className='AgeInput'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select class="form-select" aria-label="Default select example">
                                                            <option selected>Height (cm)</option>
                                                            {height?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                          id="hei"
                                                          onChange={handleChange}
                                                          placeholder='Height' className='AgeInput'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select class="form-select" aria-label="Default select example">
                                                            <option selected>Weight (kg)</option>
                                                            {weight?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                         id="wei"
                                                         onChange={handleChange}
                                                          placeholder='Weight' className='AgeInput'/>
                                                    </div>
                                                    <h6>Activity</h6>
                                                    <div className='form-group'>

                                                        <button 
                                                        className={activity == "1"?'female-btn btn mb-lg-0 mb-2':'male-btn btn mb-lg-0 mb-2'}
                                                        type="button"
                                                        onClick={() => postActivity("1")}
                                                        >Moderate</button>

                                                        <button 
                                                        className={activity == "2"?'female-btn btn mb-lg-0 mb-2':'male-btn btn mb-lg-0 mb-2'}
                                                        type="button"
                                                        onClick={() => postActivity("2")}
                                                        >Sedentary</button>

                                                        <button 
                                                        className={activity == "3"?'female-btn btn':'male-btn btn'}
                                                        type="button"
                                                        onClick={() => postActivity("3")}
                                                        >Strenous</button>
                                                    </div>

                                                    <div className='submit-btn-align'>
                                                        <Link to="/calculator-details">
                                                            <button className='btn calculate-btn' id="tee"  onClick={handleNavigation}>Calculate</button></Link>
                                                        
                                                        <button className='btn clear-btn' 
                                                        type="button"
                                                        onClick={() => clearForm(2)}
                                                        >Clear</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button third-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                            BMI Calculator
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                            <form className=''  id="form3">
                                            <div className='form-group'>
                                                        <button 
                                                        className={gender?"female-btn btn":"male-btn btn"}
                                                        type="button"
                                                        onClick={() => postData("male")}
                                                        >Male</button>
                                                        <button 
                                                        className={gender?"male-btn btn":"female-btn btn"}
                                                         type="button"
                                                         onClick={() => postData("female")}
                                                        >Female</button>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select class="form-select" 
                                                        id="age" 
                                                        aria-label="Default select example" 
                                                        onChange={handleChange}
                                                        >
                                                            <option selected>Select age</option>
                                                            {age?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                         id="age" 
                                                         onChange={handleChange}
                                                          placeholder='Age' className='AgeInput'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select class="form-select"
                                                         aria-label="Default select example"
                                                         id="hei"
                                                         onChange={handleChange}
                                                         >
                                                            <option selected>Height (cm)</option>
                                                            {height?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                          id="hei"
                                                          onChange={handleChange}
                                                          placeholder='Height' className='AgeInput'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select class="form-select"
                                                         aria-label="Default select example"
                                                         id="wei"
                                                         onChange={handleChange}
                                                         >
                                                            <option selected>Weight (kg)</option>
                                                            {weight?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                         id="wei"
                                                         onChange={handleChange}
                                                         placeholder='Weight' className='AgeInput'/>
                                                    </div>
                                                    <div className='submit-btn-align'>
                                                        <Link to="/calculator-details">
                                                            <button className='btn calculate-btn' id="bmi"  onClick={handleNavigation}>Calculate</button>
                                                        </Link>

                                                        <button className='btn clear-btn'
                                                        type="button"
                                                        onClick={() => clearForm(3)}
                                                        >Clear</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button fourth-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                            1RM Calculator
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                            <form className=''  id="form4">
                                                    <div className='form-group'>
                                                        {/* <select class="form-select"
                                                         aria-label="Default select example"
                                                         id="wei"
                                                         onChange={handleChange}
                                                         >
                                                            <option selected>Weight (kg)</option>
                                                            {weight?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                         id="age" 
                                                         onChange={handleChange}
                                                          placeholder='Age' className='AgeInput'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        <input 
                                                        class="form-control"
                                                        type="number"
                                                        placeholder="Enter Reps"
                                                         onChange={postReps}
                                                         />
                                                    </div>
                                                    <div className='submit-btn-align'>
                                                        <Link to="/calculator-details">
                                                            <button className='btn calculate-btn' id="rm"  onClick={handleNavigation}>Calculate</button>
                                                        </Link>

                                                        <button className='btn clear-btn'
                                                        type="button"
                                                        onClick={() => clearForm(4)}
                                                        >Clear</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button five-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                            Lean Body Mass Calculator
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                            <form className=''  id="form5">
                                                    <div className='form-group'>
                                                        {/* <select class="form-select"
                                                         aria-label="Default select example"
                                                         id="wei"
                                                         onChange={handleChange}
                                                         >
                                                            <option selected>Weight (kg)</option>
                                                            {weight?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                         id="wei"
                                                         onChange={handleChange}
                                                          placeholder='Weight(kg)' className='AgeInput'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        <input 
                                                        class="form-control"
                                                        type="number"
                                                        placeholder="Enter Body Fat Per"
                                                         onChange={postFat}
                                                         />
                                                    </div>
                                                    <div className='submit-btn-align'>
                                                        <Link to="/calculator-details">
                                                            <button className='btn calculate-btn' id="lean" onClick={handleNavigation}>Calculate</button>
                                                        </Link>

                                                        <button className='btn clear-btn'
                                                        type="button"
                                                        onClick={() => clearForm(5)}
                                                        >Clear</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button six-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                                            Protein Calculator
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                            <form className=''  id="form6">
                                                    <div className='form-group'>
                                                        {/* <select class="form-select"
                                                         aria-label="Default select example"
                                                         id="wei"
                                                         onChange={handleChange}
                                                         >
                                                            <option selected>Weight (kg)</option>
                                                            {weight?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                            id="wei"
                                                            onChange={handleChange}
                                                          placeholder='Weight(kg)' className='AgeInput'/>
                                                    </div>
                                                    <div className='submit-btn-align'>
                                                        <Link to="/calculator-details">
                                                            <button className='btn calculate-btn' id="protien" onClick={handleNavigation}>Calculate</button>
                                                        </Link>

                                                        <button className='btn clear-btn'
                                                        type="button"
                                                        onClick={() => clearForm(6)}
                                                        >Clear</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button seven-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                                            Weight Loss Calculator
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseSeven" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                            <form className=''  id="form7">
                                                    <div className='form-group'>
                                                        {/* <select class="form-select"
                                                         aria-label="Default select example"
                                                         id="strwei"
                                                         onChange={handleChange}
                                                         >
                                                            <option selected>Starting Weight (kg)</option>
                                                            {weight?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                         id="strwei"
                                                         onChange={handleChange}
                                                          placeholder='Starting Weight(kg)' className='AgeInput'/>
                                                    </div>
                                                    <div className='form-group'>
                                                        {/* <select class="form-select"
                                                         aria-label="Default select example"
                                                         id="curwei"
                                                         onChange={handleChange}
                                                         >
                                                            <option selected>Current Weight (kg)</option>
                                                            {weight?.map((val) => (
                                                            <option value={val.name}>{val.name}</option>
                                                            ))}
                                                        </select> */}
                                                         <input type='number'
                                                         id="curwei"
                                                         onChange={handleChange}
                                                         placeholder='Current Weight(kg)' className='AgeInput'/>
                                                    </div>
                                                    <div className='submit-btn-align'>
                                                        <Link to="/calculator-details">
                                                            <button className='btn calculate-btn' id="weightloss" onClick={handleNavigation}>Calculate</button>
                                                        </Link>

                                                        <button className='btn clear-btn'
                                                        type="button"
                                                        onClick={() => clearForm(7)}
                                                        >Clear</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Add />
                </div>
            </section>


        </>
    )
}
export default Calculator;
