import React from 'react'
import './Nutrihealthplus.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link } from 'react-router-dom';
import VideoBg from '../video/omlet.mp4';

export const Nutrihealthplus = () => {
  return (
    <>
        <section className='main'> 
            <div className='container-fluid p-0'>
                <TopNav />
                <section className='nutrihealth-plus'>
                    <div className='container'>                                            
                        <div className='row'>
                            <div className='col-xxl-7 col-xl-8 col-lg-9 col-md-10 mx-auto'>
                                <div className='back-div'>
                                    <Link to="/body-analysis">
                                        <div className='back-btn-div'>
                                            <img className='back-btn' src={process.env.PUBLIC_URL + '/assets/images/icons/back.png'} alt="" />
                                        </div> 
                                    </Link> 
                                </div>   

                                <div className='row mt-5'>
                                    <div className='col-lg-6 mb-4'>
                                        <div className='cont-sec'>
                                            <h6 className='title'>What won’t work ?</h6>
                                            <p className='content'><img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/red-close.png'} />Too restrictive to adhere</p>
                                            
                                            <p className='content'><img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/red-close.png'} />Ignore your needs & lifestyle</p>

                                            <p className='content'><img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/red-close.png'} />Expensive & complex meals</p>

                                            <p className='content'><img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/red-close.png'} />Only temporary results</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6  mb-4'>
                                        <div className='cont-sec'>
                                            <div className='cont-sec'>
                                                <h6 className='title'>Why NutriHealthPlus ?</h6>
                                                <p className='content'><img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/green-success.png'} />Eat what you love everyday</p>
                                                
                                                <p className='content'><img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/green-success.png'} />Fits your lifestyle & schedule</p>

                                                <p className='content'><img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/green-success.png'} />Tracks & Count your daily intake</p>

                                                <p className='content'><img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/green-success.png'} />Level based exercises routines</p>

                                                <p className='content'><img className='icon' src={process.env.PUBLIC_URL + '/assets/images/icons/green-success.png'} />Gets you results that last</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>  

                                <div className='gotit-div'>
                                <Link to="/choose-plan">
                                    <button className='btn gotit-btn'>Got it</button>
                                    </Link>
                                </div>
                            </div>
                        </div>                     
                    </div>
                </section>

                <Add />
            </div>
        </section>

        
    </>
  )
}
export default Nutrihealthplus;
