import axiosClient from "./ApiInstance.js";
import { resolve } from "./Resolve.js";

export async function Register(
  name,
  email,
  mobile_number,
  password,
  c_password
) {
  return await resolve(
    axiosClient
      .post("/register", {
        name: name,
        email: email,
        mobile_number: mobile_number,
        password: password,
        c_password: c_password,
      })
      .then((res) => res.data)
    //   console.log()
  );
}

export async function login(username, password) {
  return await resolve(
    axiosClient
      .post("/login", {
        username: username,
        password: password,
      })
      .then((res) => res.data)
    //   console.log()
  );
}

export async function forgotpass(mobile_number, otp) {
  return await resolve(
    axiosClient
      .post("/forget-password", {
        mobile_number: mobile_number,
        otp: otp,
      })
      .then((res) => res.data)
    //   console.log()
  );
}

export async function newpassword(mobile_number, new_password, c_password) {
  return await resolve(
    axiosClient
      .post("/new-password", {
        mobile_number: mobile_number,
        new_password: new_password,
        c_password: c_password,
      })
      .then((res) => res.data)
    //   console.log()
  );
}

export async function logout() {
  return await resolve(
    axiosClient.post("/logout").then((res) => res.data)
  );
}

export async function getProfilePic(profile_pic) {
  return await resolve(
    axiosClient.post("/profile-pic", profile_pic).then((res) => res.data)
  );
}

export async function postInfo(formData) {
  return await resolve(
    axiosClient
      .post("/info", {
        age_id: formData.age_id,
        weight_id: formData.weight_id,
      })
      .then((res) => res.data)
  );
}

export async function postWorkOut(day_id,week_id) {
  return await resolve(
    axiosClient
      .post("/work-out", {
        day_id: day_id,
        week_id: week_id,
      })
      .then((res) => res.data)
  );
}

export async function getWorkOut(week_id) {
  return await resolve(
    axiosClient.get("/work-out", { params: {week_id: week_id} }).then((res) => res.data)
  );
}
export async function getWorkProgress(week_id) {
  return await resolve(
    axiosClient.get("/work-pro", { params: {week_id: week_id} }).then((res) => res.data)
  );
}
export async function getWorkDay(day_id, workout_pack_id, week_id) {
  return await resolve(
    axiosClient
      .get("/work-day", {
        params: {
          workout_pack_id: workout_pack_id,
          week_id: week_id,
          day_id: day_id,
        },
      })
      .then((res) => res.data)
  );
}

export async function getProgress(data) {
  return await resolve(
    axiosClient
      .get("/weightsget", {
        params:
          data === "weekly" ? { weekly: "weekly" } : { monthly: "monthly" },
      })
      .then((res) => res.data)
  );
}

export async function getBlogs() {
  return await resolve(axiosClient.get("/blogs", {}).then((res) => res.data));
}

export async function getBlog(id) {
  return await resolve(axiosClient.get("/blogs/"+id, {}).then((res) => res.data));
}

export async function getAboutUs() {
  return await resolve(axiosClient.get("/aboutContent", {}).then((res) => res.data));
}

export async function getReviews() {
  return await resolve(axiosClient.get("/reviews", {}).then((res) => res.data));
}

export async function getPlanBanners() {
  return await resolve(axiosClient.get("/planBanner", {}).then((res) => res.data));
}

export async function getManageSpecification() {
  return await resolve(
    axiosClient.get("/manageSubscription", {}).then((res) => res.data)
  );
}

export async function getUserSubscription() {
  return await resolve(
    axiosClient.get("/subscription").then((res) => res.data)
  );
}

export async function getSubCheck() {
  return await resolve(
    axiosClient.get("/subscheck").then((res) => res.data)
  );
}

export async function getWorkDaywise(day_id, workout_pack_id, week_id,work_id) {
  return await resolve(
    axiosClient
      .get("/work-day", {
        params: {
          workout_pack_id: workout_pack_id,
          week_id: week_id,
          day_id: day_id,
          work_id: work_id,
        },
      })
      .then((res) => res.data)
  );
}

export async function getGender(gender) {
  return await resolve(
    axiosClient.post("/user-input", { gender: gender }).then((res) => res.data)
  );
}
export async function getPlan() {
  return await resolve(axiosClient.get("/package", {}).then((res) => res.data));
}
export async function getPlans(plans_id) {
  return await resolve(
    axiosClient
      .get("/package", { params: { plans_id: plans_id } })
      .then((res) => res.data)
  );
}

export async function getHeightApi(height_id) {
  return await resolve(
    axiosClient
      .post("/user-height", { height_id: height_id })
      .then((res) => res.data)
  );
}

export async function getWeightApi(weight_id) {
  return await resolve(
    axiosClient
      .post("/user-weight", { weight_id: weight_id })
      .then((res) => res.data)
  );
}

export async function getAgeApi(age_id) {
  return await resolve(
    axiosClient.post("/user-age", { age_id: age_id }).then((res) => res.data)
  );
}

export async function getFoodApi(food_perfrence_id) {
  return await resolve(
    axiosClient
      .post("/user-foodpref", { food_perfrence_id: food_perfrence_id })
      .then((res) => res.data)
  );
}

export async function getActivityApi(activity_level_id) {
  return await resolve(
    axiosClient
      .post("/user-actlvl", { activity_level_id: activity_level_id })
      .then((res) => res.data)
  );
}
//
export async function getMedCondApi(user_input_id, medical_condition_id) {
  return await resolve(
    axiosClient
      .post("/user-medcond", {
        user_input_id: Number(user_input_id),
        medical_condition_id: medical_condition_id,
      })

      .then((res) => res.data)
  );
}

// export async function getMedCondition(activity_level_id) {
//   return await resolve(
//     axiosClient
//       .post("/user-actlvl", { activity_level_id: activity_level_id })
//       .then((res) => res.data)
//   );
// }

export async function getWorkoutPrefExp(
  workout_prefrence_id,
  workout_experience_id
) {
  return await resolve(
    axiosClient
      .post("/user-workpref", {
        workout_prefrence_id: workout_prefrence_id,
        workout_experience_id: workout_experience_id,
      })
      .then((res) => res.data)
  );
}

export async function getHabitsApi(user_input_id, goal_id) {
  return await resolve(
    axiosClient
      .post("/user-habits", {
        user_input_id: user_input_id,
        goal_id: goal_id,
      })
      .then((res) => res.data)
  );
}

export async function getBodyFat(b_f_percantage) {
  console.log(b_f_percantage);
  return await resolve(
    axiosClient
      .post("/user-bfper", {
        b_f_percantage: b_f_percantage,
      })
      .then((res) => res.data)
  );
}

export async function getBmi() {
  console.log();
  return await resolve(
    axiosClient.post("/user-bmi", {}).then((res) => res.data)
  );
}
// export async function getMedApi(user_input_id,medical_condition_id
//   ) {
//   return await resolve(
//     axiosClient
//       .post("/user-medcond", { user_input_id: user_input_id, medical_condition_id:medical_condition_id})
//       .then((res) => res.data)
//   );
// }
export async function getSleepScore(Monthly, Weekely) {
  return await resolve(
    axiosClient
      .get(`/sleep`, { params: { Monthly: Monthly, Weekely: Weekely } })
      .then((res) => res.data)
  );
}

export async function getWater(Monthly, Weekely) {
  return await resolve(
    axiosClient
      .get(`/water`, { params: { Monthly: Monthly, Weekely: Weekely } })
      .then((res) => res.data)
  );
}

export async function getYest(Monthly, Weekely) {
  return await resolve(
    axiosClient
      .get(`/water`, { params: { Monthly: Monthly, Weekely: Weekely } })
      .then((res) => res.data)
  );
}

export async function getsWater(Monthly, Weekely) {
  return await resolve(
    axiosClient
      .get(`/water`, { params: { Monthly: Monthly, Weekely: Weekely } })
      .then((res) => res.data)
  );
}

export async function getInputApi() {
  return await resolve(
    axiosClient.get("/user-input", {}).then((res) => res.data)
  );
}

export async function getOneUserInputApi() {
  return await resolve(
    axiosClient.get("/user-one-input", {}).then((res) => res.data)
  );
}

export async function getOneUserMedApi() {
  return await resolve(
    axiosClient.get("/user-med-input", {}).then((res) => res.data)
  );
}

export async function getBannerApi() {
  return await resolve(axiosClient.get("/banner", {}).then((res) => res.data));
}

export async function getPhotosApi() {
  return await resolve(
    axiosClient.get("/getphotos", {}).then((res) => res.data));
}

// export async function getRecipesApi() {
//   return await resolve(axiosClient.get("/banner", {}).then((res) => res.data));
// }

export async function getInfoApi() {
  return await resolve(axiosClient.get("/info", {}).then((res) => res.data));
}

export async function getRecipeApi() {
  return await resolve(
    axiosClient.get("/get-recipes", {}).then((res) => res.data)
  );
}

export async function getSingleRecipeApi(id) {
  return await resolve(
    axiosClient.get(`/get-recipes/${id}`, {}).then((res) => res.data)
  );
}

export async function getMealDetail() {
  return await resolve(
    axiosClient.get(`/getmealdetail`, {}).then((res) => res.data)
  );
}

export async function getDietApi(week_id, day_id) {
  return await resolve(
    axiosClient
      .get(`/diets`, { params: { week_id: week_id, day_id: day_id } })
      .then((res) => res.data)
  );
}

export async function getDietWeekApi() {
  return await resolve(
    axiosClient
      .get(`/dietsweek`,{})
      .then((res) => res.data)
  );
}

export async function getMealApi() {
  return await resolve(
    axiosClient
    .get(`/getmeal`, {})
    .then((res) => res.data));
}

export async function getReminders() {
  return await resolve(
    axiosClient.get(`/reminder-meals`, {}).then((res) => res.data)
  );
}

export async function setReminder() {
  console.log();
  return await resolve(
    axiosClient.post("/status-main", {}).then((res) => res.data)
  );
}

export async function postStatusMeals(id) {
  console.log();
  return await resolve(
    axiosClient
      .post("/status-meals", {
        meal_id: id,
      })
      .then((res) => res.data)
  );
}

export async function postPostTime(id, post_time) {
  console.log();
  return await resolve(
    axiosClient
      .post("/reminder-meals", {
        meal_id: id,
        post_time: post_time,
      })
      .then((res) => res.data)
  );
}

export async function postWater(
  water_hrs_id,
  starting_from,
  until,
  water_status
) {
  return await resolve(
    axiosClient
      .post("/water-rem", {
        water_hrs_id: water_hrs_id,
        starting_from: starting_from,
        until: until,
        water_status: water_status,
      })
      .then((res) => res.data)
  );
}

export async function postWeight(type, reminding_time, weight_status) {
  return await resolve(
    axiosClient
      .post("/weight-rem", {
        type: type,
        reminding_time: reminding_time,
        weight_status: weight_status,
      })
      .then((res) => res.data)
  );
}
