import React, { useEffect, useState } from "react";
import "./horizontalweight.css";
const HorizontalWeight = () => {
  const [value, setValue] = useState('');
  var rangeSlider;
  var rangeBullet;
  useEffect(() => {
    rangeSlider = document.getElementById("rs-range-line");
    rangeBullet = document.getElementById("rs-bullet");
    rangeSlider.addEventListener("input", showSliderValue, false);
  }, []);
  function showSliderValue() {
    rangeBullet.innerHTML = rangeSlider.value;
    setValue(rangeSlider.value)
    console.log(rangeSlider.value, "val");
    var bulletPosition = rangeSlider.value / rangeSlider.max;
    rangeBullet.style.left = bulletPosition * 578 + "px";
  }
// console.log(value,'vall');
  return (
    <>
      <div className="weight-male-img">
        <img
          src={process.env.PUBLIC_URL + "/assets/images/step-form/male-img.png"}
          // style={{ width: `${value}vh`, maxWidth:"30vh", height:"15rem"}}
        />
      </div>
      <div className="range-div">
        <div className="container">
          <div className="range-slider">
            <span id="rs-bullet" className="rs-label">
              0
            </span>
            <input
              id="rs-range-line"
              className="rs-range"
              type="range"
              min="0"
              max="200"
            />
          </div>

          <div className="box-minmax">
            <span>0</span>
            <span>200</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default HorizontalWeight;
