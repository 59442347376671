import React, { useEffect, useState } from "react";
import "./Form.css";
import { Link, useNavigate } from "react-router-dom";
import { Scrollchor } from "react-scrollchor";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import Gender from "./gender";
import Height from "./height";
import Weight from "./weight";
import Age from "./age";
import BodyType from "./BodyType";
import Food from "./Food";
import Activity from "./Activity";
import Medical from "./Medical";
import Workout from "./Workout";
import Habits from "./Habits";
import {getBmi} from "../api/Apis"
import {
  getGender,
  getHeightApi,
  getInputApi,
  getWeightApi,
  getAgeApi,
  getBodyFat,
  getFoodApi,
  getActivityApi,
  getMedCondApi,
  getWorkoutPrefExp,
  getHabitsApi,
} from "../api/Apis";
import { MyContext } from "./StepContext";

const stepPages = [
  <Gender />,
  <Height />,
  <Weight />,
  <Age />,
  <BodyType />,
  <Food />,
  <Activity />,
  <Medical />,
  <Workout />,
  <Habits />,
];

const genderApi = {
  gender: "",
};
const Stepform = () => {
  const  [stepval, setStepval] = useState(1);
  console.log(Height);
  const [visible, setVisible] = useState(false);
  const [userid, setUserId] = useState(sessionStorage.getItem('userid'));
  const navigate = useNavigate();
  let ab;
  let uf = sessionStorage.getItem("unfilled");
  let fill = sessionStorage.getItem("filled");
  let medcon = sessionStorage.getItem("medField");
  let success = sessionStorage.getItem("success");
  console.log(medcon);
  console.log(fill, "uffffff");
  console.log("unfill",uf);
  if (uf == "gender") {
    ab = 0;
  } else if (uf == "height_id") {
    ab = 1;
  } else if (uf == "weight_id") {
    ab = 2;
  } else if (uf == "age_id") {
    ab = 3;
  } else if (uf == "b_f_percantage") {
    ab = 4;
  } else if (uf == "activity_level_id") {
    ab = 5;
  } else if (uf == "food_perfrence_id") {
    ab = 6;
  } else if (uf == "workout_experience_id") {
    if (medcon == "medical_condition_id") {
      ab = 7;
    } else {
      ab = 8;
    }
  } else if (uf == "workout_prefrence_id") {
    if (medcon == "medical_condition_id") {
      ab = 7;
    } else {
      ab = 8;
    }
  } else if (uf == "goal_id") {
    ab = 9;
  }
  const [step, setStep] = React.useState(ab);
  const [formState, setFormState] = React.useState({});
  const [steps, setSteps] = React.useState([
    {
      label: "Gender",
      isValid: undefined,
    },
    {
      label: "Height",
      isValid: undefined,
    },
    {
      label: "Weight",
      isValid: undefined,
    },
    {
      label: "Age",
      isValid: undefined,
    },
    {
      label: "Bodytype",
      isValid: undefined,
    },
    {
      label: "Food",
      isValid: undefined,
    },
    {
      label: "Activity",
      isValid: undefined,
    },
    {
      label: "Medical",
      isValid: undefined,
    },
    {
      label: "Workout",
      isValid: undefined,
    },
    {
      label: "Habits",
      isValid: undefined,
    },
  ]);
  const [fizoUsers, setFizoUsers] = useState(genderApi);

  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;
  const onStepSubmit = React.useCallback(
    (event) => {
    
      const { isValid, values } = event;

      if (step < 10) {
        HandleSubmit();
      }

      const currentSteps = steps.map((currentStep, index) => ({
        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));
      setSteps(currentSteps);
      setStep(() => Math.min(step + 1, lastStepIndex));
      setFormState(values);
      if (isLastStep && isPreviousStepsValid && isValid) {
        let datagoal =  sessionStorage.getItem("habit");
        
        console.log(datagoal,'goalNavigate')
            if (datagoal == null || datagoal == "0") {
              setStep(() => Math.max(step - 0, 0));
            }else{
              navigate("/circular");
            }        
      }
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
  );
  const onPrevClick = React.useCallback(
    (event) => {
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep]
  );

  const HandleSubmit = async (e) => {
    // alert("hi")
    // e.preventDefault()

    var http = new XMLHttpRequest();
    http.open("HEAD", false);
    if (http.status === "404") {
      alert("wait");
    } else {
      console.log("");
      //Go on and render the freaking component...

      switch (step) {
        case 0:
          {
            let genderVal = await sessionStorage.getItem("gender");
            if (genderVal == null) {
              genderVal = "Male";
            }
            const users = await getGender((fizoUsers.gender = genderVal));
            console.log("Gender " + users.data.success);

            sessionStorage.setItem("userid", users.data.data.id);
          }
          break;

        case 1:
          {
            let type = await sessionStorage.getItem("heightype");
            let hegihtVal = await sessionStorage.getItem("height");
            console.log(type);
            if (hegihtVal == null || hegihtVal == 0) {
              alert("Please Select your Height!");
              setStep(() => Math.max(step - 0, 0));
            } else {
            if (type == "cm") {
              let data = await getInputApi();
              // console.log(data.data.data.heightcm)
              
              let id;
              data.data.data.heightcm.forEach((data) => {
                if (data.name == Math.round(hegihtVal)) {
                  console.log(type + hegihtVal + data.id,"in Cm");
                  id = data.id;
                }
              });
              const users2 = await getHeightApi((fizoUsers.height_id = id));
              console.log("Heghit " ,users2.data);
            }  if (type == "ft") {
              console.log(type + hegihtVal,"in Ft");
              const [minspiltvalue, minremain] = hegihtVal.split(".");
              let heightFt = minspiltvalue+"'"+minremain;
              console.log(heightFt);
              let data = await getInputApi();
              let id;
              data.data.data.heightft.forEach((data) => {

                console.log(data.name)
                if (data.name == heightFt) {
                  id = data.id;
                }
              });
              
              console.log(id,'kkkkkkkkk');
              const users2 = await getHeightApi((fizoUsers.height_id = id));
              console.log("Heghit ",users2.data);
            } 
          }
          }
          break;

        case 2:
          {
            let data = await getInputApi();
            let weighttype = await sessionStorage.getItem("weightype");
            let weightVal = await sessionStorage.getItem("weight");
            console.log(weightVal,"weight_id ");
            if (weightVal == null || weightVal == 0) {
              alert("Please Select your weight!");
              setStep(() => Math.max(step - 0, 0));
            } else {
              let id;
              let type;
              if(weighttype == "pound"){
               type = 2;
               data.data.data.weightpo.forEach((data) => {
                if (data.name == Math.round(weightVal) && data.weight_id == type) {
                  id = data.id;
                }
              });
              }else{
                type = 1;
                data.data.data.weightkg.forEach((data) => {
                  if (data.name == Math.round(weightVal) && data.weight_id == type) {
                    id = data.id;
                  }
                });
              }
              
              const users2 = await getWeightApi((fizoUsers.weight_id = id));
              console.log("weight_id ", users2.data);
              sessionStorage.setItem("response", users2.data.success);
            }
          }
          break;

        case 3:
          {
            let ageid = await sessionStorage.getItem("ageid");
            console.log("Age id ", ageid);
            if (ageid == null || ageid == 0) {
              alert("Please Enter your Age!");
              setStep(() => Math.max(step - 0, 0));
            } else {
              const users = await getAgeApi((fizoUsers.age_id = ageid));
              console.log("Age id " + users.data.success);
            }
          }

          break;

        case 4:
          {
            let data = await Number(sessionStorage.getItem("bodytype"));
            if (data == null || data == "0") {
              alert("Please Select Body type!");
              setStep(() => Math.max(step - 0, 0));
            } else {
              // console.log(data);
              const users = await getBodyFat((fizoUsers.b_f_percentage = data));
              console.log(fizoUsers);
              console.log("Body Fat " + users.data.success);
            }
          }
          break;

        case 5:
          {
            let data = await sessionStorage.getItem("foodtype");
            if (data == null || data == "0") {
              
              alert("Please Select Food type!");
              setStep(() => Math.max(step - 0, 0));
            } else {
              const users = await getFoodApi(
                (fizoUsers.food_perfrence_id = data)
              );
              console.log(fizoUsers);
              console.log("Food Type" + users.data.success);
            }
          }
          break;

        case 6:
          {
            let data = await sessionStorage.getItem("activity");
            if (data == null || data == "0") {
              alert("Please Select Activity!");
              setStep(() => Math.max(step - 0, 0));
            } else {
              // alert(data);
              // console.log(data);
              const users = await getActivityApi(
                (fizoUsers.activity_level_id = data)
              );
              console.log(fizoUsers);
              console.log("Activity " + users.data.success);
            }
          }
          break;

        case 7:
          {
            let data = await sessionStorage.getItem("medicalchoose");
            let a = [data];
            let userid = await sessionStorage.getItem("userid");
            // alert(userid);
            if (userid == null || userid == "0" || a[0] == "0" ||a[0]=='') {
              alert("Please Select Medical condition!");
              setStep(() => Math.max(step - 0, 0));
            } else {
              // const arrays = data.split(",");
              // console.log(`data  ${[arrays]}`);
              // alert(a);
              const users = await getMedCondApi(
                (fizoUsers.user_input_id = userid),
                (fizoUsers.medical_condition_id = a[0])
              );
              console.log(fizoUsers);
              console.log("getMedCondApi " + users.data.success);
            }
          }
          break;

        case 8:
          {
            let data1 = await sessionStorage.getItem("workoutexp");
            let data2 = await sessionStorage.getItem("workoutpref");
              console.log(data1+data2,"ppppppppp");
            if (
              (Number(data1) == null || Number(data2) == null) || (data1==null || data2==null) || (data1==0 || data2==0)
            ) {
              alert("Please Select Work Experience!");
              setStep(() => Math.max(step - 0, 0));
            } else {
              // const arrays = data.split(",");
              // console.log(`data  ${[arrays]}`);
              const users = await getWorkoutPrefExp(
                (fizoUsers.workout_prefrence_id = Number(data2)),
                (fizoUsers.workout_experience_id = Number(data1))
              );
              console.log(fizoUsers);
              console.log("getWorkoutPrefExp " + users.data.success);
            }
          }
          break;
        case 9:
          {
            let data = await sessionStorage.getItem("habit");
            let userid = await sessionStorage.getItem("userid");
            console.log(data,'goal')
            if (data == null || data == "0" || userid==null) {
              alert("Please Select your Goal!");
              await setStep(() => Math.max(step - 0, 0));
            } else {
              const users = await getHabitsApi(
                (fizoUsers.user_input_id = userid),
                (fizoUsers.habit_id = data)
              );

              console.log(fizoUsers);
              console.log(data, "goaldata");
              console.log("getHabitsApi " + users.data.success);

              const bmiUsers=await getBmi();
              console.log(bmiUsers.data.data.tee,'bmiusers');
              let tee=bmiUsers.data.data.tee
              let bmr=bmiUsers.data.data.bmr
              let bmi=bmiUsers.data.data.bmi

              sessionStorage.setItem("tee",tee)
              sessionStorage.setItem("bmr",bmr)
              sessionStorage.setItem("bmi",bmi)
            }
          }
          break;

        default:
          break;
      }
    }
 
  };
  return (
    <>
      {/* <MyContext.Provider value={{ stepval, setStepval }}> */}
      <section className="main">
        <div className="container">
          <section className="step-form-section">
            <div className="row">
              <div
                className="col-md-10 mx-auto"
                // style={{
                //     display: "flex",
                //     flexDirection: "column",
                //     justifyContent: "center"
                // }}
              >
                <Stepper value={step} items={steps} />
                <Form
                  initialValues={formState}
                  onSubmitClick={onStepSubmit}
                  render={(formRenderProps) => (
                    <div
                    // style={{
                    //     alignSelf: "center"
                    // }}
                    >
                      <FormElement
                      // style={{
                      //     width: 480
                      // }}
                      >
                        {stepPages[step]}
                        <span
                          // style={{
                          //     marginTop: "40px"
                          // }}
                          className={"k-form-separator"}
                        />
                        <div
                          // style={{
                          //     justifyContent: "space-between",
                          //     alignContent: "center"
                          // }}
                          className={
                            "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                          }
                        >
                          <span
                            className=""
                            // style={{
                            //     alignSelf: "center"
                            // }}
                          >
                            {/* Step {step + 1} of 10 */}
                          </span>
                          <div>
                            {step !== 0 ? (
                              // <Button
                              //     style={{
                              //         marginRight: "16px"
                              //     }}
                              //     onClick={onPrevClick}
                              // >
                              //     Previous
                              // </Button>

                              <div
                                className="back-btn-div"
                                onClick={onPrevClick}
                              >
                                <img
                                  className="back-btn"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/back.png"
                                  }
                                  alt=""
                                />
                              </div>
                            ) : undefined}
                            <Button
                              themeColor={"primary"}
                              disabled={
                                isLastStep ? !isPreviousStepsValid : false
                              }
                              onClick={formRenderProps.onSubmit}
                            >
                              <img
                                className="form-next-icon"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/icon-next1.png"
                                }
                              />
                              {isLastStep ? "" : ""}
                            </Button>
                          </div>
                        </div>
                      </FormElement>
                    </div>
                  )}
                />
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* </MyContext.Provider> */}
    </>
  );
};

export default Stepform;
