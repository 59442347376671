import React from 'react'
import './BlogDetails.css';
import Add from '../add/Add';
import TopNav from '../top-nav/TopNav';
import { Link, useParams} from 'react-router-dom';
import { useEffect } from "react";
import { useState } from "react";
import { getBlog} from "../api/Apis";
import { Rings } from 'react-loader-spinner';

export const BlogDetails = () => {
  
  const [loading, setLoading] = useState(true);
    const [response,setResponse] = useState()
    const [formData1, setFormData1] = useState({
        "plan": "", 
    });
    const [blog, setBlogs] = useState();
    const [description, setDescription] = useState();
    
  const {id} = useParams();
    const imageUrl = "https://api.fizo.in/";
    const getblog = async () => {
        await getBlog(id)
          .then((res) => {
            setBlogs(res.data.data.blog);
            // console.log(res.data.data.blog,'llllllllllll');
            var HtmlToReactParser = require('html-to-react').Parser;
            let htmlInput = res.data.data.blog.dissipation;

            var htmlToReactParser = new HtmlToReactParser();
            setDescription(htmlToReactParser.parse(htmlInput));
            setLoading(false);
           
          })
          .catch((error) => {
            console.log(error);
          });
      };

      useEffect(() => {
        getblog();
        
        // openRazorpayWindow();
    }, []);
  return (
    <>
        <section className='main'> 
            <div className='container-fluid p-0'>
                <TopNav />
                <section className='blog-details'>
                    <div className='container'>
                        <div className='blog-title-div text-center'>
                            <h6 className='title'>Blogs</h6>
                        </div> 
                                                         
                        <Link to="/blog">
                            <div className='back-btn-div'>
                                <img className='back-btn' src={process.env.PUBLIC_URL + '../assets/images/icons/back.png'} alt="" />
                            </div> 
                        </Link> 
                        {loading ? (
                    <div className="loader-ring"
                    
                    >
       <Rings color="#00BFFF" height={80} width={80} 
       
       />
       </div>
      ) : (
                        <div className='blog-sec'>
                          <div className="mx-auto text-center">
                            <img className='blog-banner' src={imageUrl + blog?.image} />

                          </div>
                            <div className='mt-5'>

                           
                            <p className='diet-p ms-2'>{blog?.type}</p>
                           

                           
                            <p class="date-p"><img class="calender-img" src={process.env.PUBLIC_URL + '/assets/images/icons/calender.png'} />{(new Date(blog?.created_at)).toLocaleDateString('en-US',{ day: '2-digit', month: 'short', year: 'numeric' } )}3</p>
                            <h6 className='blog-name'>{blog?.heading}</h6>
                            

                            <p className='content'>{description}</p>
                            </div>
                        </div>
      )}

                        
                    </div>
                </section>

                <Add />
            </div>
        </section>

        
    </>
  )
}
export default BlogDetails;
