import React, { useEffect, useState } from "react";
import axiosClient from "../api/ApiInstance.js";
import "./SleepScore.css";
import Add from "../add/Add";
import TopNav from "../top-nav/TopNav";
import { getSleepScore, getInfoApi } from "../api/Apis";
import Charts from "./Charts";
import CanvasJSReact from "@canvasjs/react-charts";
import { Chart } from "@canvasjs/react-charts";
import "./chart.css";
import { Link, useNavigate  } from "react-router-dom";

import sound from './fizoalarm.mp3'
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const SleepScore = () => {
  let navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Weekly");
  const [time, setTime] = useState();
  const [data, setData] = useState();
  const [wakeup, setWakeup] = useState();
  const [bedtime, setBedTime] = useState();
  const [height1, setHeight1] = useState();
  const [height2, setHeight2] = useState();
  const [height3, setHeight3] = useState();
  const [height4, setHeight4] = useState();
  const [height5, setHeight5] = useState();
  const [height6, setHeight6] = useState();

  const [monthlyHeight1, setMonthlyHeight1] = useState();
  const [monthlyHeight2, setMonthlyHeight2] = useState();
  const [monthlyHeight3, setMonthlyHeight3] = useState();
  const [monthlyHeight4, setMonthlyHeight4] = useState();
  const [monthlyHeight5, setMonthlyHeight5] = useState();
  const [monthlyHeight6, setMonthlyHeight6] = useState();
  const [errors, setErrors] = useState(false);
  const [errMsg, setErrMsg] = useState({
    errors: [],
    isError: false,
  });
  const [show, setShow] = useState(false);


  const [infoData, setInfoData] = useState(false);
  const getInfoData = async () => {
    await getInfoApi()
      .then((res) => {
        console.log(res.data.data,'ggfhjasfaf');
        setInfoData(res.data.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getData = async (monthly, weekly) => {
    console.log(monthly,'llllllllllllll');
    await getSleepScore(monthly, weekly)
      .then((res) => {
        // console.log(res.data.data.sleepScoreDay[0]);
        console.log(res.data.data,'llllllllllllll');
        if(res.data.data.sleep != null){
          setWakeup((res.data.data.sleep.wakeup_at).substring(0,5));
          setBedTime((res.data.data.sleep.sleep_at).substring(0,5));
        }
        setData(Math.round(res.data.data.sleepScoreDay[0]));
        setHeight1(Math.round(res.data.data.sleepScoreDay[0]));
        setHeight2(Math.round(res.data.data.sleepScoreDay[1]));
        setHeight3(Math.round(res.data.data.sleepScoreDay[2]));
        setHeight4(Math.round(res.data.data.sleepScoreDay[3]));
        setHeight5(Math.round(res.data.data.sleepScoreDay[4]));
        setHeight6(Math.round(res.data.data.sleepScoreDay[5]));
      })
      .catch((error) => {
        console.log(error);
      });

    const dateTime = new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    console.log(dateTime);
    setTime(dateTime);
  };

  const getMonthlyData = async (monthly, weekly) => {
    await getSleepScore(monthly, weekly)
      .then((res) => {
        setMonthlyHeight1(Math.round(res.data.data.sleepScoremonth[0]));
        setMonthlyHeight2(Math.round(res.data.data.sleepScoremonth[1]));
        setMonthlyHeight3(Math.round(res.data.data.sleepScoremonth[2]));
        setMonthlyHeight4(Math.round(res.data.data.sleepScoremonth[3]));
        setMonthlyHeight5(Math.round(res.data.data.sleepScoremonth[4]));
        setMonthlyHeight6(Math.round(res.data.data.sleepScoremonth[5]));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let h1 = height1;
  let h2 = height2;
  let h3 = height3;
  let h4 = height4;
  let h5 = height5;
  let h6 = height6;

  let Mh1 = monthlyHeight1;
  let Mh2 = monthlyHeight2;
  let Mh3 = monthlyHeight3;
  let Mh4 = monthlyHeight4;
  let Mh5 = monthlyHeight5;
  let Mh6 = monthlyHeight6;

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const [alarmTime, setAlarmTime] = useState("");
  const [isAlarmOn, setIsAlarmOn] = useState(false);

  const updateCurrentTime = () => {
    setCurrentTime(new Date().toLocaleTimeString());
  };

  const handleAlarmTimeChange = (event) => {
    console.log(event.target.value ,"setalarm");
    axiosClient
    .post("/sleep", {
      sleep_at: time,
      wakeup_at: event.target.value,
    })
    .then((res) => {
      console.log(res.data ,"alarm post");
      setWakeup((res.data.data.sleepscore.wakeup_at).substring(0,5));
      setAlarmTime(res.data.data.sleepscore.sleep_at);
      setBedTime((res.data.data.sleepscore.sleep_at).substring(0,5));
    })
    .catch((error) => {
      console.log(error.response);
      setErrMsg({
        errors: error.response.data,
        isError: true,
      });
      if (error) {
        setShow(true);
      }
    });

  };

  const handleAlarmToggle = () => {
    setIsAlarmOn(!isAlarmOn);
  };
  let val;
  const handleOptions = (event) => {
    setSelectedOption(event.target.value);
    val = event.target.value;
    console.log(val);
  };



  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    backgroundColor: "transparent",
    axisX: {
      // labelFormatter: () => "", // Remove x-axis labels
      gridThickness: 0, // Remove y-axis grid lines
      tickThickness: 1, // Remove tick lines from x-axis
      // Add custom labels
    },
    axisY: {
      minimum: 0, // Set the minimum value for the y-axis
        maximum: 100, // Set the maximum value for the y-axis
        interval: 10, // Set the interval between custom labels
        includeZero: true, // Ensure that zero is included
        labelFormatter: (e) => {
            return e.value.toFixed(0); // Format the labels as integers
        },
        gridThickness: 0, // Remove y-axis grid lines
    },
    data: [
      {
        type: "column",
        indexLabelPlacement: "outside", // Place index labels below data points
        indexLabelFontSize: 12, // Adjust font size of index labels
        fillOpacity: 1, // Remove white background
        borderColor: "transparent", // Remove border lines
        color: "#274bbf",
        cornerRadius: 100,
        
        indexLabelFontSize: 16, // Set font size of index labels
        indexLabelFontColor: "white", // Set font color of index labels
        dataPoints: [
          {
            y: selectedOption === "Weekly" ? h1 : Mh1,
            indexLabel: selectedOption === "Weekly" ? "D1" : "W1",
          },
          {
            y: selectedOption === "Weekly" ? h2 : Mh2,
            indexLabel: selectedOption === "Weekly" ? "D2" : "W2",
          },
          {
            
            y: selectedOption === "Weekly" ? h3 : Mh3,
            indexLabel: selectedOption === "Weekly" ? "D3" : "W3",
          },
          {
            y: selectedOption === "Weekly" ? h4 : Mh4,
            indexLabel: selectedOption === "Weekly" ? "D4" : "W4",
          },
          {
            y: selectedOption === "Weekly" ? h5 : Mh5,
            indexLabel: selectedOption === "Weekly" ? "D5" : "W5",
          },
          {
            y: selectedOption === "Weekly" ? h6 : Mh6,
            indexLabel: selectedOption === "Weekly" ? "D6" : "W6",
          },
        ],
      },
    ],
    
  };

  // dataPoints: [
  //   { y: Mh1, indexLabel: "W1" },
  //   { y: Mh2, indexLabel: "W2" },
  //   { y: Mh3, indexLabel: "W3" },
  //   { y: Mh4, indexLabel: "W4" },
  //   { y: Mh5, indexLabel: "W5" },
  //   { y: Mh6, indexLabel: "W6" },
  // ],
  new Audio("public/assets/alarm/fizoalarm.mp3").play();
  const playAlarm = () => {
console.log('play');
new Audio(sound).play();
  };
  useEffect(() => {
    Notification.requestPermission();
    const monthly = selectedOption === "Monthly" ? "monthly" : "";
    const weekly = selectedOption === "Weekly" ? "weekly" : "";
    getData(monthly, weekly);
   
    getMonthlyData(monthly, weekly);
    getInfoData();
     const intervalId = setInterval(() => {
      
      if(time == wakeup){
        // playAlarm();
      }

    }, 26000)

    return () => clearInterval(intervalId);
   
  }, [selectedOption]);
  return (
    <>
      <section className="main">
        <TopNav />
        <div className="container-fluid p-0">
          <section className="sleep-source-section">
            <div className="container">
              <div className="title-div pt-5">
                <h6 className="title">Sleep</h6>
                <h5 className="title-sleep">Sleep Score</h5>
              </div>
              <div className='back-btn-div' 
                            onClick={() => navigate(-1)}
                            >
                                <img className='back-btn' src={process.env.PUBLIC_URL + '../assets/images/icons/back.png'} alt="" />
                            </div> 

              <div className="drop-div">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleOptions}
                  value={selectedOption}
                >
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>

              <br></br>

              <div className="score-div mt-5">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12 mb-5">
                    <div className="sec-1">
                      <img
                        className="chart-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/sleep-score/sleep-chart.png"
                        }
                      />

                      <div className="bars">
                        <div className="sleep-charts">
                          <CanvasJSChart options={options} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12 mb-5">
                    <div className="sec-2">
                      <img
                        className="banner2"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/sleep-score/banner.png"
                        }
                      />

                      <div className="overflow">
                        <div className="container">
                          <p className="good-night-p">{time?.substring(0,2) < 12? "Good Morning":""}{time?.substring(0,2) > 12 && time?.substring(0,2) < 17? "Good Afternoon":""}{time?.substring(0,2) > 17 && time?.substring(0,2) < 20? "Good Evening":""}{time?.substring(0,2) > 20 && time?.substring(0,2) < 23? "Good Night":""}, {infoData?.name}</p>
                          <h1 className="main-time">{time}</h1>

                          <div className="m-4">
                            <div className="d-flex mx-auto alarm">
                              <p
                                className="ms-3 me-2 mt-2"
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                }}
                              >
                                Alarm :
                              </p>
                              <input
                                className="alarm-input"
                                type="time"
                                id="alarmTime"
                                value={wakeup}
                                onChange={handleAlarmTimeChange}
                                
                              />
                            </div>
                          </div>

                          <hr className="border-bot"></hr>
                          <p className="good-night-p">Today's Sleep</p>

                          <div className="d-flex justify-content-center">
                            <div className="bed-time-div">
                              <p className="bed-p">Bed Time</p>
                              <h6 className="time">{bedtime}</h6>
                            </div>
                            <div className="bed-time-div">
                              <p className="bed-p">Wake Up</p>
                              <h6 className="time">{wakeup}</h6>
                            </div>
                          </div>

                          <div className="m-5">
                            {/* <p className="slight-p">
                              Slightly better than yesterday
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Add />
      </section>
    </>
  );
};

export default SleepScore;
